export const TelIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <g>
        <path
          fill="none"
          stroke={props.color}
          d="M28.1,25.5l-2.6-1.7c-0.3-0.2-0.8-0.1-1,0.2l-0.8,1c-0.1,0.1-0.3,0.2-0.4,0.1l-0.1-0.1
				c-0.5-0.3-1.1-0.6-2.3-1.8c-1.2-1.2-1.5-1.8-1.8-2.3L19,20.7c-0.1-0.1,0-0.3,0.1-0.4l1-0.8c0.3-0.2,0.4-0.7,0.2-1l-1.7-2.6
				c-0.2-0.3-0.7-0.4-1-0.2l-1.1,0.7c-0.3,0.2-0.6,0.5-0.7,0.9c-0.4,1.4-0.1,3.9,3.5,7.5c2.9,2.9,5,3.7,6.5,3.7c0.3,0,0.7,0,1-0.1
				c0.4-0.1,0.7-0.4,0.9-0.7l0.7-1.1C28.5,26.2,28.4,25.7,28.1,25.5z"
        />
      </g>
    </g>
  </svg>
);
