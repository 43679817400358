export const EQUIPMENT_CONSTANTS = {
  TYPES: {
    andaineuse: 'andaineuse',
    broy: 'broy',
    charrue: 'charrue',
    chenillard: 'chenillard',
    coupe: 'coupe',
    enjambeur: 'enjambeur',
    epand: 'epand',
    faneuse: 'faneuse',
    faucheuse: 'faucheuse',
    haymaking: 'haymaking',
    intercep: 'intercep',
    lisier: 'lisier',
    misc: 'misc',
    plomb: 'plomb',
    presse: 'presse',
    pulve: 'pulve',
    recolt: 'recolt',
    recolt_tool: 'recolt_tool',
    remork: 'remork',
    semoir: 'semoir',
    snow_removal: 'snow_removal',
    sol_combined: 'sol_combined',
    sol: 'sol',
    tractor: 'tractor',
    traitement: 'traitement',
    vendange: 'vendange',
  },
} as const;

export const PARCELS_CONSTANTS = {
  CATEGORY: {
    ANONYMOUS: 'anonymous',
    FIELD: 'field',
    FARMHOUSE: 'farmhouse',
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
    RENT_INACTIVE: 'INACTIVE',
    RENT_OVER: 'RENT_OVER',
    BEING_RENT: 'BEING_RENT',
    BEING_RENT_ACTIVE: 'BEING_RENT_ACTIVE',
  },
} as const;

export const DEVICES_CONSTANTS = {
  KARNOTT: {
    RED_BATTERY_LEVEL: 15,
    ORANGE_BATTERY_LEVEL: 30,
  },
  BATTERY: {
    HEALTH_STATUS: {
      WARNING: 'WARNING',
      CRITICAL: 'CRITICAL',
    },
  },
} as const;

export const OBSERVATION_CONSTANTS = {
  TYPE: {
    EQUIPMENT: 'equipment_instance',
    PARCEL: 'parcel',
    SESSION: 'equipment_session',
  },
  STATUS: {
    AVAILABLE: 'AVAILABLE',
    ARCHIVED: 'ARCHIVED',
  },
} as const;
