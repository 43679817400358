import Icon from 'react-icon-base';

const IcKarnottPause = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path
        fill="#918E8A"
        d="M9.83422222,4.465 C8.18472298,2.77852164 5.60007634,2.51859455 3.66097929,3.84418282 C1.72188225,5.16977108 0.968414072,7.71167051 1.86137465,9.91532709 C2.75433523,12.1189837 5.04501627,13.3706328 7.33250187,12.9048011 C9.61998746,12.4389694 11.2671648,10.3854008 11.2666667,8 C11.2701795,6.67325712 10.7543408,5.40026545 9.83422222,4.465 L9.83422222,4.465 Z M5.64444444,9.5 C5.64444444,9.77614237 5.42556143,10 5.15555556,10 C4.88554968,10 4.66666667,9.77614237 4.66666667,9.5 L4.66666667,6.5 C4.66666667,6.22385763 4.88554968,6 5.15555556,6 C5.42556143,6 5.64444444,6.22385763 5.64444444,6.5 L5.64444444,9.5 Z M8.08888889,9.5 C8.08888889,9.77614237 7.87000588,10 7.6,10 C7.32999412,10 7.11111111,9.77614237 7.11111111,9.5 L7.11111111,6.5 C7.11111111,6.22385763 7.32999412,6 7.6,6 C7.87000588,6 8.08888889,6.22385763 8.08888889,6.5 L8.08888889,9.5 Z"
      />
      <path
        fill="#4B453C"
        d="M22.7604444,9.44 L20.9368889,7.345 C20.7634385,7.13222446 20.5085709,7.00645142 20.2377778,7 L12.6551111,7 C12.7060858,7.33080499 12.7322362,7.66511412 12.7333333,8 C12.7333333,9.72390718 12.0637321,11.3772076 10.8718342,12.5961941 C9.67993634,13.8151805 8.06337591,14.5 6.37777778,14.5 C4.69144963,14.5043487 3.07354619,13.8183522 1.88488889,12.595 C1.74311111,12.45 1.616,12.305 1.48888889,12.15 L1.48888889,14.925 C1.49023197,15.1594848 1.57544351,15.3853587 1.72844444,15.56 L3.552,17.655 C3.72545041,17.8677755 3.98031804,17.9935486 4.25111111,18 L20.2377778,18 C20.5047023,17.9998459 20.7589392,17.8834756 20.9368889,17.68 L22.7604444,15.585 C22.9191358,15.403963 23.0047499,15.1680873 23,14.925 L23,10.075 C22.9986569,9.84051515 22.9134454,9.61464133 22.7604444,9.44 Z M13.6768889,14.805 C13.3258812,14.805 13.0413333,14.5139851 13.0413333,14.155 C13.0413333,13.7960149 13.3258812,13.505 13.6768889,13.505 C14.0278965,13.505 14.3124444,13.7960149 14.3124444,14.155 C14.3124444,14.5139851 14.0278965,14.805 13.6768889,14.805 Z M16.2288889,14.805 C15.8778812,14.805 15.5933333,14.5139851 15.5933333,14.155 C15.5933333,13.7960149 15.8778812,13.505 16.2288889,13.505 C16.5798965,13.505 16.8644444,13.7960149 16.8644444,14.155 C16.8644444,14.5139851 16.5798965,14.805 16.2288889,14.805 Z M18.7808889,14.805 C18.5238307,14.805 18.292084,14.6466326 18.1937121,14.4037442 C18.0953402,14.1608559 18.1497156,13.8812793 18.3314832,13.6953806 C18.5132509,13.5094819 18.7866146,13.4538707 19.0241055,13.5544783 C19.2615963,13.655086 19.4164444,13.8920995 19.4164444,14.155 C19.4164548,14.5120415 19.1348747,14.8022535 18.7857778,14.805 L18.7808889,14.805 Z"
      />
    </g>
  </Icon>
);

export default IcKarnottPause;
