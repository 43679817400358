import { useContext } from 'react';
import { MapContext } from '../../contexts/map';
import { useDrawUnitLocationsOnMap } from './effects';

const UnitLocations = function ({ unitLocations, events }) {
  const { map } = useContext(MapContext);
  useDrawUnitLocationsOnMap(map, unitLocations, events);
  return null;
};

export default UnitLocations;
