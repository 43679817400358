export const PenIcon = ({ color, size }) => (
  <svg viewBox="0 0 24 24" width={`${size || 24}`} height={`${size || 24}`}>
    <g>
      <path
        fill={color}
        stroke={color}
        d="M13.06 6.87l-8.2 8.2a.76.76 0 0 0-.21.4L4 19.11a.76.76 0 0 0 .75.89h.13l3.63-.64a.75.75 0 0 0 .4-.21l8.2-8.2zM19.89 6.67l-2.57-2.56a1.09 1.09 0 0 0-1.5 0L14.33 5.6l4.07 4.07 1.49-1.49a1.06 1.06 0 0 0 0-1.51z"
      />
    </g>
  </svg>
);
