import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const NewsIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 34 18.8"
    >
      <g transform="translate(1 1)">
        <line
          x2="0.994"
          y2="0.761"
          transform="translate(1.855 0.635)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="1.531"
          transform="translate(0 8.4)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y1="0.761"
          x2="0.994"
          transform="translate(2.329 15.404)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="0.994"
          y1="0.761"
          transform="translate(29.15 15.404)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="1.531"
          transform="translate(30.469 8.4)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="0.994"
          y2="0.761"
          transform="translate(28.677 0.635)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M134.924,137.376v8.553L142.3,149.9v-8.035Z"
          transform="translate(-126.329 -133.1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M149.735,137.376v8.269l-7.4,4.248v-7.962Z"
          transform="translate(-126.329 -133.1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M134.924,137.376l7.376,4.486,7.436-4.486-7.541-4.276Z"
          transform="translate(-126.329 -133.1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
