import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const IntercepIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M25.1,28.7V8c0-1.5,1.2-2.7,2.7-2.7l13.7,0C42.3,5.4,43,6,43,6.8v2.1c0,0.8-0.7,1.5-1.5,1.5h-9.7c-0.9,0-1.6,0.7-1.6,1.6l0,26.6"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M28,38c-3.5-1-7.8-1.6-12.4-1.6c-5.7,0-10.8,0.9-14.6,2.3l0,0C1.3,31.6,7.7,26,15.6,26c7.9,0,14.3,5.6,14.6,12.6l-0.1,0
		C29.4,38.4,28.8,38.2,28,38"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1.3,38.4c2.5-4.6,8-7.7,14.4-7.7c6.1,0,11.5,2.9,14.1,7.2"
        />
      </g>
    </svg>
  </CircledIcon>
);
