import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const PulveIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			4.4,34.8 6.7,30.7 8.9,34.8 		"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			14.6,34.8 16.9,30.7 19.2,34.8 		"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			24.8,34.8 27.1,30.7 29.4,34.8 		"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			35.1,34.8 37.3,30.7 39.6,34.8 		"
          />
        </g>
        <g>
          <g>
            <path
              fill="none"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
				M16.9,23.7L2,26.9c-0.8,0.1-1.3,1-0.9,1.8L2,30.1c0.2,0.4,0.6,0.6,1,0.6h19h19c0.4,0,0.8-0.2,1-0.6l0.8-1.4
				c0.4-0.7,0-1.6-0.9-1.8l-14.9-3.3"
            />
          </g>
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M26.1,26.8h-8.1c-0.4,0-0.8-0.4-0.8-0.8L17.9,10c0-0.4,0.4-0.8,0.8-0.8h6.5c0.4,0,0.8,0.4,0.8,0.8L26.9,26
			C26.9,26.4,26.5,26.8,26.1,26.8z"
          />
        </g>

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="17.8"
          y1="15.4"
          x2="26.2"
          y2="15.4"
        />
      </g>
    </svg>
  </CircledIcon>
);
