import { useContext } from 'react';
import styled from 'styled-components';
import { I18nContext } from '../../../contexts/I18nProvider';
import { color, size, spacing, ui, zIndex } from '../../../ui/theme';
import { Icons } from '../../new_icons';

const BottomSection = function ({ clearAction, drawnParcel }) {
  const { t } = useContext(I18nContext);
  return (
    <BottomSectionWrapper>
      {drawnParcel ? (
        drawnParcel.valid === false ? (
          <SelectionState error>
            {drawnParcel.toobig ? t('ErrorsImportModal.area_too_large_error') : t('BottomSection.invalid_geometry')}
            <ClearSelection onClick={clearAction}>
              <Icons.CloseIcon size={size('xl')} color={color('white')} />
            </ClearSelection>
          </SelectionState>
        ) : (
          <SelectionState>
            <span>{drawnParcel.area}</span>
            <ClearSelection onClick={clearAction}>
              <Icons.CloseIcon size={size('xl')} color={color('red')} />
            </ClearSelection>
          </SelectionState>
        )
      ) : null}
    </BottomSectionWrapper>
  );
};

const BottomSectionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: ${zIndex('ui')};
  bottom: ${spacing}px;
  margin-right: 40%;
  width: 20%;
  margin-left: 40%;
  overflow: hidden;
`;

const ClearSelection = styled.div`
  cursor: pointer;
  margin-left: ${spacing / 2}px;
  display: flex;
  align-items: center;
`;

const SelectionState = styled.div`
  display: flex;
  flex-direction: row;
  color: ${color('white')};
  align-items: stretch;
  background-color: ${({ error }) => (error ? ui('error') : color('green'))};
  border-radius: ${spacing / 4}px;
  padding: ${spacing / 2}px ${spacing}px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: appear;
  animation-duration: 1s;
  span {
    font-family: 'Ubuntu';
    font-size: ${size('l')};
    align-self: center;
  }
  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export default BottomSection;
