import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ParcelRentIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 26.441 24"
    >
      <g transform="translate(1 1)">
        <path
          d="M846.938,14.713a1.313,1.313,0,0,1,1.313,1.314v1.1a1.313,1.313,0,0,1-1.313,1.314l-2.659.026,3.426-.026a1.317,1.317,0,0,0,.931-.386s8.2-4.14,8.561-3.724a2.054,2.054,0,0,1-.178,2.769l-6.651,4.815a2.632,2.632,0,0,1-1.737.769l-8.762.418-1.4,1.4-5.122-5.127,3.885-3.888a2.637,2.637,0,0,1,1.864-.772Z"
          transform="translate(-833.35 -2.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M854.138,5.376,840.51,9.305l-3.647-2.877L850.491,2.5Z"
          transform="translate(-833.35 -2.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
