export const LocationIcon = ({ color, size }) => (
  <svg viewBox="0 0 24 24" width={`${size || 24}`} height={`${size || 24}`}>
    <g>
      <path
        fill={color}
        stroke="none"
        d="M12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm8.94 3A9 9 0 0 0 13 3.06V1h-2v2.06A9 9 0 0 0 3.06 11H1v2h2.06A9 9 0 0 0 11 20.94V23h2v-2.06A9 9 0 0 0 20.94 13H23v-2zM12 19a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"
      />
    </g>
  </svg>
);
