import { localStorageKeys } from '../utils/storage';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(localStorageKeys.state);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(localStorage.getItem(localStorageKeys.state));
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(localStorageKeys.state, serializedState);
  } catch (err) {
    return undefined;
  }
};
