export const RulerIcon = (props) => (
  <svg
    fill={props.color}
    height={`${props.size || 39}`}
    width={`${props.size || 39}`}
    strokeWidth={`${props.strokeWidth || 1}`}
    viewBox="0 0 21 21"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <rect y="0" fill="none" width="21" height="21" />
      <path opacity="0.5" stroke={props.color} d="M4.5,8" />
      <line stroke={props.color} x1="3.5" y1="7" x2="3.5" y2="10" />
      <line stroke={props.color} x1="5.5" y1="7" x2="5.5" y2="11" />
      <line stroke={props.color} x1="7.5" y1="7" x2="7.5" y2="10" />
      <line stroke={props.color} x1="9.5" y1="7" x2="9.5" y2="11" />
      <line stroke={props.color} x1="11.5" y1="7" x2="11.5" y2="10" />
      <line stroke={props.color} x1="13.5" y1="7" x2="13.5" y2="11" />
      <line stroke={props.color} x1="15.5" y1="7" x2="15.5" y2="10" />
      <path d="M1,6v7v2h19v-2v-7H1z M2,13v-5h17v5H2z" fill={props.color} />
      <line stroke={props.color} x1="17.5" y1="7" x2="17.5" y2="11" />
    </g>
  </svg>
);
