import findIndex from 'lodash-es/findIndex';
import omit from 'lodash-es/omit';
import { colors } from '@karnott/colors';
import { Actions } from '../actions';
import { CLUSTER_CONSTANTS } from '../constants/clusterConstants';
import { COLOR_CATEGORIES, COLOR_ID } from '../constants/parcelsConstants';
import { I18n } from '../i18n';
import { createCrop, deleteCrop, fetchCrops, updateCrop } from '../slices/crops';
import { createTag, deleteTag, fetchTags, updateTag } from '../slices/tags';
import { isPluginActivated } from '../utils/clusters';
import { localStorageKeys } from '../utils/storage';

const initialState = {
  colors: [
    {
      title: I18n.t('Colors.by_crops'),
      checked: {
        [COLOR_CATEGORIES.PARCELS]: localStorage.getItem(localStorageKeys.colorizeFieldsBy) === COLOR_ID.CROPS,
      },
      id: COLOR_ID.CROPS,
      catalog: {},
      items: [],
      disabled: false,
    },
    {
      title: I18n.t('Colors.by_tags'),
      checked: {
        [COLOR_CATEGORIES.PARCELS]: localStorage.getItem(localStorageKeys.colorizeFieldsBy) === COLOR_ID.TAGS,
      },
      id: COLOR_ID.TAGS,
      catalog: {},
      items: [],
    },
    {
      title: I18n.t('Colors.by_cluster'),
      checked: {
        [COLOR_CATEGORIES.PARCELS]: localStorage.getItem(localStorageKeys.colorizeFieldsBy) === COLOR_ID.CLUSTERS,
        [COLOR_CATEGORIES.TRACKS]: localStorage.getItem(localStorageKeys.colorizeTracksBy) === COLOR_ID.CLUSTERS,
      },
      id: COLOR_ID.CLUSTERS,
      catalog: {},
      items: [],
      tracksNoColorLabel: I18n.t('Colors.cluster_undefined'),
    },
    {
      title: I18n.t('Colors.by_equipment'),
      checked: {
        [COLOR_CATEGORIES.TRACKS]: localStorage.getItem(localStorageKeys.colorizeTracksBy) === COLOR_ID.EQUIPMENT,
      },
      id: COLOR_ID.EQUIPMENT,
      catalog: {},
      items: [],
      tracksNoColorLabel: I18n.t('Colors.equipment_undefined'),
    },
  ],
};

function getTagColorItem(tagId) {
  return {
    id: tagId,
    labelKey: 'label',
  };
}

function getClusterColorItem(clusterId) {
  return {
    id: clusterId,
    labelKey: 'name',
  };
}

function getEquipmentColorItem(equipmentId) {
  return {
    id: Number(equipmentId),
    labelKey: 'label',
  };
}

const parcelColors = (state = initialState, action) => {
  const { type, payload } = action;
  const cropColorsIndex = state.colors.findIndex((c) => c.id === COLOR_ID.CROPS);
  const tagsColorsIndex = state.colors.findIndex((c) => c.id === COLOR_ID.TAGS);
  const clustersColorsIndex = state.colors.findIndex((c) => c.id === COLOR_ID.CLUSTERS);
  const equipmentsColorsIndex = state.colors.findIndex((c) => c.id === COLOR_ID.EQUIPMENT);
  const newColors = [...state.colors];

  switch (type) {
    case Actions.PARCELS_SUCCESS: {
      const cropIds = [];
      Object.values(payload.entities.parcel || {}).forEach((parcel) => {
        if (parcel?.current_crop_id && cropIds.indexOf(parcel.current_crop_id) === -1) {
          cropIds.push(parcel.current_crop_id);
        }
      });
      const newCropColors = cropIds.map((cropId) => ({
        labelKey: 'name',
        id: cropId,
      }));
      newCropColors.push({
        value: colors('black'),
        label: I18n.t('Crop.undefined'),
        id: 0,
      });
      newColors[cropColorsIndex] = { ...newColors[cropColorsIndex], items: newCropColors };

      return {
        ...state,
        colors: newColors,
      };
    }
    case createCrop.fulfilled.type: {
      newColors[cropColorsIndex].catalog = {
        ...newColors[cropColorsIndex].catalog,
        [payload.id]: payload,
      };
      newColors[cropColorsIndex].items = [
        ...newColors[cropColorsIndex].items,
        {
          label: payload.name,
          id: payload.id,
        },
      ];
      return {
        ...state,
        colors: newColors,
      };
    }
    case fetchCrops.fulfilled.type: {
      const cropsById = {};
      for (const crop of payload) {
        cropsById[crop.id] = crop;
      }
      newColors[cropColorsIndex] = {
        ...newColors[cropColorsIndex],
        catalog: {
          ...newColors[cropColorsIndex].catalog,
          ...cropsById,
        },
      };
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.COLORIZE_PARCELS_BY: {
      const colorId = payload;
      newColors.forEach((c) => {
        if (typeof c.checked[COLOR_CATEGORIES.PARCELS] === 'boolean') {
          const isChecked = c.id === colorId && c.checked[COLOR_CATEGORIES.PARCELS] === false;
          c.checked = { ...c.checked, [COLOR_CATEGORIES.PARCELS]: isChecked };
        }
      });
      localStorage.setItem(localStorageKeys.colorizeFieldsBy, colorId);
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.COLORIZE_TRACKS_BY: {
      const colorId = payload;
      newColors.forEach((c) => {
        if (typeof c.checked[COLOR_CATEGORIES.TRACKS] === 'boolean') {
          const isChecked = c.id === colorId && c.checked[COLOR_CATEGORIES.TRACKS] === false;
          c.checked = { ...c.checked, [COLOR_CATEGORIES.TRACKS]: isChecked };
        }
      });
      localStorage.setItem(localStorageKeys.colorizeTracksBy, colorId);
      return {
        ...state,
        colors: newColors,
      };
    }
    case updateCrop.fulfilled.type: {
      const updatedCrop = payload;
      const updatedCropForCatalog = {
        [payload.id]: payload,
      };
      newColors[cropColorsIndex].catalog = {
        ...newColors[cropColorsIndex].catalog,
        ...updatedCropForCatalog,
      };
      const indexOfUpdatedCrop = findIndex(newColors[cropColorsIndex].items, (item) => item.id === updatedCrop.id);
      if (indexOfUpdatedCrop !== -1) {
        newColors[cropColorsIndex].items[indexOfUpdatedCrop] = {
          ...payload,
          labelKey: 'name',
        };
      }
      return {
        ...state,
        colors: newColors,
      };
    }
    case deleteCrop.fulfilled.type:
      newColors[cropColorsIndex].catalog = omit({ ...newColors[cropColorsIndex].catalog }, payload);
      newColors[cropColorsIndex].items = [...newColors[cropColorsIndex].items].filter((item) => item.id !== payload);
      return {
        ...state,
        colors: newColors,
      };
    case fetchTags.fulfilled.type: {
      const entries = payload.map((t) => [t.id, t]);
      newColors[tagsColorsIndex] = {
        ...newColors[tagsColorsIndex],
        catalog: {
          ...newColors[tagsColorsIndex].catalog,
          ...Object.fromEntries(entries),
        },
        items: entries.map((t) => t[0]).map(getTagColorItem),
      };
      return {
        ...state,
        colors: newColors,
      };
    }
    case createTag.fulfilled.type:
    case updateTag.fulfilled.type:
      newColors[tagsColorsIndex].catalog[payload.id] = payload;
      newColors[tagsColorsIndex].items = newColors[tagsColorsIndex].items
        .filter((i) => i.id !== payload.id)
        .concat([getTagColorItem(payload.id)]);
      return {
        ...state,
        colors: newColors,
      };
    case deleteTag.fulfilled.type: {
      newColors[tagsColorsIndex].catalog = omit(newColors[tagsColorsIndex].catalog, payload);
      newColors[tagsColorsIndex].items = newColors[tagsColorsIndex].items.filter((i) => i.id !== payload);
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CLUSTERS_SUCCESS: {
      const viti = payload
        .filter((c) => c.role === 'ADMIN')
        .some((c) => c.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.viticulture);
      const cropPlugin = payload.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.crop_management));

      if (viti) {
        newColors[cropColorsIndex].title = I18n.t('Colors.by_grapes');
      }
      newColors[cropColorsIndex].disabled = !cropPlugin;

      const clusterEntries = payload.filter((c) => !!c.color).map((c) => [c.id, c]);
      newColors[clustersColorsIndex] = {
        ...newColors[clustersColorsIndex],
        catalog: {
          ...newColors[clustersColorsIndex].catalog,
          ...Object.fromEntries(clusterEntries),
        },
        items: clusterEntries.map((t) => t[0]).map(getClusterColorItem),
      };

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CLUSTER_SUCCESS: {
      if (payload.color) {
        newColors[clustersColorsIndex] = {
          ...newColors[clustersColorsIndex],
          catalog: {
            ...newColors[clustersColorsIndex].catalog,
            [payload.id]: payload,
          },
          items: [
            ...newColors[clustersColorsIndex].items.filter((i) => i.id !== payload.id),
            getClusterColorItem(payload.id),
          ],
        };
      }

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CLUSTER_UNLINKED: {
      newColors[clustersColorsIndex].catalog = omit(newColors[clustersColorsIndex].catalog, payload);
      newColors[clustersColorsIndex].items = newColors[clustersColorsIndex].items.filter((i) => i.id !== payload);

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.EQUIPMENT_SUCCESS:
    case Actions.EQUIPMENTS_SUCCESS: {
      if (!payload?.entities?.equipmentInstance) return state;

      const equipmentEntries = Object.entries(payload.entities.equipmentInstance).filter(
        ([, equipment]) => !!equipment.color,
      );
      const equipmentEntriesNoColor = Object.entries(payload.entities.equipmentInstance).filter(
        ([, equipment]) => !equipment.color,
      );
      const ids = equipmentEntries.map(([id]) => id);
      newColors[equipmentsColorsIndex] = {
        ...newColors[equipmentsColorsIndex],
        catalog: {
          ...Object.fromEntries(
            Object.entries(newColors[equipmentsColorsIndex].catalog).filter(
              ([id]) => !equipmentEntriesNoColor.find(([idNoColor]) => idNoColor === id),
            ),
          ),
          ...Object.fromEntries(equipmentEntries),
        },
        items: [
          ...newColors[equipmentsColorsIndex].items.filter(({ id }) => !ids.includes(String(id))),
          ...ids.map(getEquipmentColorItem),
        ],
      };

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.EQUIPMENT_DELETE_SUCCESS: {
      newColors[equipmentsColorsIndex].catalog = omit(newColors[equipmentsColorsIndex].catalog, payload);
      newColors[equipmentsColorsIndex].items = newColors[equipmentsColorsIndex].items.filter((i) => i.id !== payload);

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default parcelColors;
