import { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  filterListByAuthorizationField,
  getItemFilterByAuthorizationField,
  hasPermission,
} from '../../utils/permissions';
import { Context } from './context';

const Provider = function ({ children, clusters }) {
  const satisfies = useCallback(
    ({ authorizations, action, clusterAuthorization } = {}) => {
      if (clusterAuthorization) {
        if (typeof clusterAuthorization === 'object') {
          return (
            clusters.clustersIds.filter((cID) => {
              return clusterAuthorization.some((auth) => clusters.clusterById[cID].authorizations[auth]);
            }).length > 0
          );
        }
        return (
          clusters.clustersIds.filter((cID) => {
            const authorizations = clusters.clusterById[cID].authorizations[clusterAuthorization];
            const typeOfAuthorizations = typeof authorizations;
            return (
              authorizations &&
              (typeOfAuthorizations === 'boolean' ||
                typeOfAuthorizations === 'string' ||
                (typeOfAuthorizations === 'object' && authorizations.length > 0))
            );
          }).length > 0
        );
      }
      if (authorizations && authorizations.length !== undefined) {
        return authorizations.reduce((isAuthorized, authorization) => {
          if (action && typeof action !== 'string' && action.length !== undefined && authorization) {
            return action.reduce((isAuthorized, a) => authorization[a] && isAuthorized, true);
          }
          return isAuthorized && authorization && authorization[action];
        }, true);
      }

      if (action && typeof action !== 'string' && action.length !== undefined) {
        return action.reduce((isAuthorized, a) => authorizations[a] && isAuthorized, true);
      }
      return hasPermission({ authorizations }, action);
    },
    [clusters],
  );

  return (
    <Context.Provider
      value={{
        satisfies,
        filterListByAuthorizationField,
        getItemFilterByAuthorizationField,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const ReduxConnectedProvider = connect((state) => ({
  clusters: state.clusters,
}))(Provider);
export { ReduxConnectedProvider as Provider };
