import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import * as turf from '@turf/helpers';
import { colors } from '@karnott/colors';
import { WarningIcon } from '@karnott/icons';
import { I18nContext } from '../../contexts/I18nProvider';
import { getStaticHTMLFromComponent } from '../../utils';
import { L as Leaflet } from '../../utils/LeafletOverrides';
import { localStorageKeys } from '../../utils/storage';
import { useDeviceCoords } from './assets/Devices/effects';
import { useDriverCoords } from './assets/Drivers/effects';
import { useEquipmentCoords } from './assets/Equipments/effects';
import { useLocationCoords } from './assets/Locations/effects';
import { useObservationsCoords } from './assets/Observations/effects';
import { useParcelsCoords } from './assets/Parcels/effects';
import { useTrackCoords } from './assets/Tracks/effects';
import { MarkerIcon } from './markerIcon';

export function useMapCarrier({ id = 'map-carrier' }) {
  return useMemo(() => {
    let element = document.getElementById(id);
    if (element) {
      element.remove();
    }
    element = document.createElement('div');
    element.setAttribute('id', id);
    element.setAttribute(
      'style',
      'visibility:hidden; display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 0px;',
    );
    document.body.appendChild(element);
    return element;
  }, [id]);
}

export function useMapInCarrier({ carrier, tiles }) {
  const tileRef = useRef(tiles);

  useEffect(() => {
    if (carrier) {
      carrier.onTouchMove = (e) => {
        e.stopPropagation();
      };
    }
    return () => {};
  }, [carrier]);

  const map = useMemo(() => {
    const container = Leaflet.DomUtil.get(carrier.id);
    if (container !== null) {
      container._leaflet_id = null;
    }

    const initWithIGN = localStorage.getItem(localStorageKeys.ign) === 'true';
    const instance = new Leaflet.Map(container, {
      zoomControl: false,
      minZoom: 4,
      maxZoom: 21,
      attributionControl: false,
      // disable double click in test mode to be able to click quickly during tests
      doubleClickZoom: !(import.meta.env.DEV && import.meta.env.VITE_TESTING),
      // disable default zoom handler to enable smooth zoom if tiles are not google
      scrollWheelZoom: !initWithIGN,
      // allow any zoom level, not just integers
      zoomSnap: initWithIGN ? 0 : 1,
      // change the zoom granularity when using the + / - controls
      zoomDelta: initWithIGN ? 0.5 : 1,
    });

    instance.once('load', () => {
      instance.once('resize', () => {
        tileRef.current.remove();
        instance.addLayer(tileRef.current);
      });
    });

    new ResizeObserver(() => {
      instance.invalidateSize(false);
    }).observe(container);

    instance.setView([48.833, 2.333], 7);

    if (import.meta.env.DEV) {
      instance.on('moveend', () => {
        const center = instance.getCenter();
        carrier.setAttribute('data-latitude', center.lat);
        carrier.setAttribute('data-longitude', center.lng);
        carrier.setAttribute('data-zoom', instance.getZoom());
      });
    }

    return instance;
  }, [carrier]);

  const changeTiles = useCallback(
    (tiles) => {
      tileRef.current.removeFrom(map);
      tileRef.current = tiles;
      map.addLayer(tiles);
    },
    [map],
  );

  useEffect(() => {
    // we have to do this change in case of initial tiles change
    if (tiles && tileRef.current !== tiles) {
      changeTiles(tiles);
    }
  }, [tiles, changeTiles]);

  return [map, changeTiles];
}

export function useCarrierInContainer({ map, container, carrier }) {
  useEffect(() => {
    if (!container || !map || !carrier) return () => {};
    container.current && carrier && container.current.prepend(carrier);
    if (carrier) {
      carrier.style.visibility = 'visible';
      carrier.style.width = 'inherit';
    }

    map.invalidateSize(false);
    return () => {
      if (carrier) {
        carrier.style.visibility = 'hidden';
        carrier.style.width = '0px';
      }
      map.invalidateSize(false);
    };
  }, [container, carrier, map]);
}

export function useDebounce(call, delay = 300) {
  const timeout = useRef(null);
  return useCallback(
    (...args) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => call(...args), delay);
    },
    [call, delay, timeout],
  );
}

export function useFitMapOnPoints(points, map, locked) {
  const [fitOnce, setFitOnce] = useState(false);
  const nbPoints = useRef(null);
  const fitMapCb = useCallback(
    (soft, target) => {
      if (((!target || !target.length) && (!points || !points.length)) || (locked && fitOnce)) return;
      if (locked && !fitOnce) {
        nbPoints.current = points.length;
        setFitOnce(true);
      } else if (
        (target && target.length) ||
        (points && points.length === 1) ||
        (points && points.length && nbPoints.current && nbPoints.current !== points.length) ||
        (map && points && points.length && (!fitOnce || !soft))
      ) {
        nbPoints.current = points.length;
        map.fitBounds(target || points, {
          animate: true,
          padding: [0, 0],
          maxZoom: 21,
        });

        setFitOnce(true);
      }
    },
    [fitOnce, map, points, nbPoints, locked],
  );

  const fitMap = useDebounce(fitMapCb);

  useEffect(() => {
    fitMap(true);
  }, [fitMap, locked]);

  useEffect(() => {
    fitMap(true);
  }, [points, fitMap]);

  return [fitMapCb];
}

export function useAssetsPoints(mask, equipments, parcels, tracks, devices, observations, drivers, locations) {
  const emptyArray = useRef([]);

  const shouldUseSpecificLocation = !mask || mask.includes('specific_location'); // useful to zoom on a place for e2e tests
  const shouldUseParcel = !mask || mask.includes('parcel');
  const shouldUseEquipment = !mask || mask.includes('equipment');
  const shouldUseTracks = !mask || mask.includes('tracks');
  const shouldUseDevice = !mask || mask.includes('device');
  const shouldUseObs = !mask || mask.includes('observation');
  const shouldUseDriver = !mask || mask.includes('driver');
  const shouldUseLocation = !mask || mask.includes('location');

  const specificLocationPoints = useSpecificLocationPoints(shouldUseSpecificLocation ? mask : undefined);
  const parcelPoints = useParcelsCoords(shouldUseParcel ? parcels : emptyArray.current);
  const equipmentPoints = useEquipmentCoords(shouldUseEquipment ? equipments : emptyArray.current);
  const tracksPoint = useTrackCoords(shouldUseTracks ? tracks : emptyArray.current);
  const devicePoints = useDeviceCoords(shouldUseDevice ? devices : emptyArray.current);
  const observationsPoints = useObservationsCoords(shouldUseObs ? observations : emptyArray.current);
  const driverPoints = useDriverCoords(shouldUseDriver ? drivers : emptyArray.current);
  const locationPoints = useLocationCoords(shouldUseLocation ? locations : emptyArray.current);
  const points = useRef([]);

  return useMemo(() => {
    if (specificLocationPoints) return specificLocationPoints;
    const newPoints =
      parcelPoints ||
      emptyArray.current
        .concat(equipmentPoints || emptyArray.current)
        .concat(tracksPoint || emptyArray.current)
        .concat(devicePoints || emptyArray.current)
        .concat(observationsPoints || emptyArray.current)
        .concat(driverPoints || emptyArray.current)
        .concat(locationPoints || emptyArray.current);

    if (
      (newPoints.length === 1 &&
        (points.current.length !== 1 ||
          newPoints[0]?.[0] !== points.current[0]?.[0] ||
          newPoints[0]?.[1] !== points.current[0]?.[1])) ||
      (newPoints.length && newPoints.length !== points.current.length)
    ) {
      points.current = newPoints;
    }

    // if there is no items displayed on map, all items of "points" are equal to 0 and error is "bounds are not valid"
    // to avoid this, we return an empty array
    if (points.current && !points.current.find((p) => p !== 0)) {
      return [];
    } else return points.current;
  }, [
    parcelPoints,
    equipmentPoints,
    devicePoints,
    tracksPoint,
    points,
    observationsPoints,
    driverPoints,
    locationPoints,
    specificLocationPoints,
  ]);
}

export function useMapOptions(map, options = {}) {
  const scale = useMemo(() => {
    if (!map) return null;
    return Leaflet.control.scale({ imperial: false });
  }, [map]);

  useEffect(() => {
    if (!map) return () => {};
    const { displayScale } = options;
    displayScale && scale && scale.addTo(map);
    return () => {
      scale && scale.remove();
    };
  }, [map, options, scale]);
}

export function useCallOnMapMove(map, call) {
  const callback = useCallback(() => {
    const bounds = map.getBounds();
    if (!bounds) return;
    return call({
      n: bounds.getNorth(),
      e: bounds.getEast(),
      s: bounds.getSouth(),
      w: bounds.getWest(),
    });
  }, [call, map]);

  useEffect(() => {
    map.on('dragend', callback);
    map.on('zoomend', callback);
    return () => {
      map.off('dragend', callback);
      map.off('zoomend', callback);
    };
  }, [map, callback]);

  return callback;
}

export function useDrawMarkersOnMap(map, items, dataExtractor, events, limitDate) {
  const [markers, markersLayersGroup] = useMemo(() => {
    if (!items || !items.length) return [[], Leaflet.layerGroup([])];
    const markersLayers = [];
    const markers = items
      .map((item) => {
        return { item, data: dataExtractor(item) };
      })
      .filter(
        ({ data }) => data.location && data.location.longitude !== undefined && data.location.latitude !== undefined,
      )
      .map(({ item, data }) => {
        const { location, Icon, iconColor, backgroundColor, iconSize, iconAnchor, healthIssue } = data;
        const coords = { lat: location.latitude, lng: location.longitude };
        const Icons = Array.isArray(Icon) ? Icon : [Icon];
        const iconLength = Icons.length;
        const theMarker = getStaticHTMLFromComponent(<MarkerIcon size={40} color={iconColor} length={iconLength} />);
        const backgroundMarker = Leaflet.marker(coords, {
          icon: Leaflet.divIcon({
            className: '',
            html: theMarker,
            iconSize: [20 * iconLength, 20],
            iconAnchor: [(45 * iconLength) / 2, 45],
          }),
        });
        const icon = Icon
          ? getStaticHTMLFromComponent(
              <div>
                <Icon color={iconColor} size={iconSize || 24} circled backgroundColor={backgroundColor} />
                {healthIssue && (
                  <div style={{ position: 'absolute', right: 0, top: -5 }}>
                    <WarningIcon size={13} backgroundColor={colors('red', 500)} color={'white'} circled />
                  </div>
                )}
              </div>,
            )
          : '<div></div>';
        const iconMarker = Leaflet.marker(coords, {
          icon: Leaflet.divIcon({
            className: '',
            html: icon,
            iconSize: [40 * iconLength, 40],
            iconAnchor: iconAnchor || [(45 * iconLength) / 2, 45],
          }),
        });
        const layerGroup = Leaflet.layerGroup([backgroundMarker, iconMarker]);
        Object.keys(events || {}).forEach((event) => {
          backgroundMarker.on(event, () => events[event](item, layerGroup, { backgroundMarker, iconMarker }));
          iconMarker.on(event, () => events[event](item, layerGroup, { backgroundMarker, iconMarker }));
        });
        markersLayers.push(layerGroup);
        return {
          backgroundMarker,
          iconMarker,
          item,
          layerGroup,
        };
      });
    const markersLayersGroup = Leaflet.layerGroup(markersLayers);
    return [markers, markersLayersGroup];
  }, [items, dataExtractor, events]);

  useEffect(() => {
    if (limitDate) {
      markers.forEach(({ item, backgroundMarker, iconMarker }) => {
        if (moment(item.occurred_at).isAfter(limitDate)) {
          backgroundMarker.setOpacity(0);
          iconMarker.setOpacity(0);
        } else {
          backgroundMarker.setOpacity(1);
          iconMarker.setOpacity(1);
        }
      });
    }
  }, [limitDate, markers]);

  useEffect(() => {
    if (!map || !items || !items.length) return () => {};
    map.addLayer(markersLayersGroup);
    return () => {
      markers.forEach(({ backgroundMarker, iconMarker }) => {
        Object.keys(events || {}).forEach((event) => {
          backgroundMarker.off(event);
          iconMarker.off(event);
        });
      });
      markersLayersGroup.removeFrom(map);
    };
  }, [map, items, events, dataExtractor, markersLayersGroup, markers]);
  return markers;
}

export function useBuildEquipmentIcon({ backgroundColor, iconColor, healthIssue, Icons, size, borderColor }) {
  const iconLength = useMemo(() => Icons?.length || 0, [Icons]);
  const padding = 6;
  const iconSizeWithPadding = useMemo(() => size + 2 * padding, [size, padding]);
  const backgroundMarkerDivIcon = useMemo(() => {
    if (!backgroundColor || !iconLength) {
      return null;
    }
    const theMarker = getStaticHTMLFromComponent(
      <MarkerIcon size={iconSizeWithPadding} color={backgroundColor} borderColor={borderColor} length={iconLength} />,
    );
    return Leaflet.divIcon({
      className: '',
      html: theMarker,
      iconSize: [iconSizeWithPadding * iconLength, iconSizeWithPadding],
      iconAnchor: [(iconSizeWithPadding * iconLength) / 2, iconSizeWithPadding],
    });
  }, [backgroundColor, borderColor, iconLength, iconSizeWithPadding]);

  const iconMarkerDivIcon = useMemo(() => {
    if (!size || !iconLength || !iconColor || !Icons || !Array.isArray(Icons)) {
      return null;
    }
    const icon = getStaticHTMLFromComponent(
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '38px',
          gap: padding + 'px',
        }}
      >
        {Icons.map((Icon, index) =>
          Icon ? <Icon key={index} color={iconColor} size={size} /> : <div key={index}></div>,
        )}
        {healthIssue ? (
          <div style={{ position: 'absolute', right: -5, top: -5 }}>
            <WarningIcon size={13} backgroundColor={colors('red', 500)} color={'white'} circled />
          </div>
        ) : null}
      </div>,
    );
    return Leaflet.divIcon({
      className: '',
      html: icon,
      iconSize: [iconSizeWithPadding * iconLength, iconSizeWithPadding],
      iconAnchor: [(iconSizeWithPadding * iconLength) / 2, iconSizeWithPadding],
    });
  }, [iconColor, size, Icons, iconLength, healthIssue, iconSizeWithPadding]);

  return { backgroundMarkerDivIcon, iconMarkerDivIcon };
}

export function useDrawMarkerOnMap(
  map,
  item,
  location,
  Icon,
  iconColor,
  backgroundColor,
  events,
  healthIssue = false,
  size = 25,
) {
  const coords = useMemo(() => ({ lat: location.latitude, lng: location.longitude }), [location]);
  const Icons = useMemo(() => (Array.isArray(Icon) ? Icon : [Icon]), [Icon]);
  const { backgroundMarkerDivIcon, iconMarkerDivIcon } = useBuildEquipmentIcon({
    backgroundColor,
    iconColor,
    healthIssue,
    Icons,
    size,
    borderColor: item?.color,
  });
  const backgroundMarker = useMemo(() => {
    if (coords.lat === undefined || coords.lng === undefined) return null;
    return Leaflet.marker(coords, {
      icon: backgroundMarkerDivIcon,
    });
  }, [coords, backgroundMarkerDivIcon]);

  const iconMarker = useMemo(() => {
    if (coords.lat === undefined || coords.lng === undefined) return null;
    return Leaflet.marker(coords, {
      icon: iconMarkerDivIcon,
    });
  }, [coords, iconMarkerDivIcon]);

  const layerGroup = useMemo(
    () => (backgroundMarker && iconMarker ? Leaflet.layerGroup([backgroundMarker, iconMarker]) : null),
    [iconMarker, backgroundMarker],
  );

  useEffect(() => {
    if (!map || !layerGroup) return () => {};
    layerGroup.addTo(map);
    return () => {
      layerGroup.removeFrom(map);
    };
  }, [layerGroup, map]);

  useEffect(() => {
    if (!map || !backgroundMarker || !iconMarker) return () => {};

    Object.keys(events || {}).forEach((event) => {
      backgroundMarker.on(event, () => events[event](item, layerGroup, { backgroundMarker, iconMarker }));
      iconMarker.on(event, () => events[event](item, layerGroup, { backgroundMarker, iconMarker }));
    });

    return () => {
      Object.keys(events || {}).forEach((event) => {
        backgroundMarker.off(event);
        iconMarker.off(event);
      });
    };
  }, [map, backgroundMarker, iconMarker, item, layerGroup, events]);

  return useMemo(
    () => ({
      backgroundMarker,
      iconMarker,
      item,
      layerGroup,
    }),
    [backgroundMarker, iconMarker, item, layerGroup],
  );
}

function processPointAndPoly(e, layer) {
  const pt = turf.point([e.latlng.lat, e.latlng.lng]);
  const ring = layer._latlngs[0].map((latlng) => [latlng.lat, latlng.lng]);
  ring.push(ring[0]);
  const poly = turf.polygon([ring]);
  return [pt, poly];
}

let overParcel = null;
export function useDrawCirclesOnMap(map, items, dataExtractor, events, forwardEventsToParcels) {
  const canvasRenderer = useRef(Leaflet.canvas({ pane: 'overlayPane' }));
  const forwardClickToParcelsCB = useCallback(
    (e) => {
      e.originalEvent.stopPropagation();
      map.eachLayer((l) => {
        if (l.options && l._layers && l.options.type === 'parcel') {
          Object.keys(l._layers).forEach((id) => {
            const layer = l._layers[id];
            const [pt, poly] = processPointAndPoly(e, layer);
            booleanPointInPolygon(pt, poly) && layer.fire('click', { ...e });
          });
        }
      });
    },
    [map],
  );
  const forwardMouseMoveToParcelsCB = useCallback(
    (e) => {
      map.eachLayer((l) => {
        if (l.options && l._layers && l.options.type === 'parcel') {
          Object.keys(l._layers).forEach((id) => {
            const layer = l._layers[id];
            const [pt, poly] = processPointAndPoly(e, layer);
            const { parcel_id } = layer.feature.geometry.properties || {};
            if (booleanPointInPolygon(pt, poly)) {
              if (parcel_id !== overParcel) {
                overParcel = parcel_id;
                layer.fire('mouseout', { ...e });
                layer.fire('mouseover', { ...e });
              } else {
                layer.fire('mousemove', { ...e });
              }
            } else {
              if (parcel_id === overParcel) {
                overParcel = null;
              }
              layer.fire('mouseout', { ...e });
            }
          });
        }
      });
    },
    [map],
  );
  const [markers, markersLayersGroup] = useMemo(() => {
    if (!items || !items.length) return [[], Leaflet.layerGroup([])];
    const markersLayers = [];
    const markers = items
      .map((item) => {
        return { item, data: dataExtractor(item) };
      })
      .filter(({ data }) => data.location && (data.location.longitude || data.location.latitude))
      .map(({ item, data }) => {
        const { location, color, radius, borderColor, pane, renderer, borderWidth, className } = data;
        const coords = { lat: location.latitude, lng: location.longitude };
        const opts = { padding: 0.5 };
        if (pane) {
          opts.pane = pane;
        }
        const myRenderer = renderer === 'svg' ? Leaflet.svg(opts) : canvasRenderer.current;
        const circle = Leaflet.circleMarker(coords, {
          renderer: myRenderer,
          color: borderColor || 'transparent',
          fill: true,
          fillColor: color || 'transparent',
          fillOpacity: 1,
          radius: radius || 4,
          stroke: true,
          weight: borderWidth || 2,
          className: className || '',
        });

        Object.keys(events || {}).forEach((event) => {
          circle.on(event, (e) => events[event](item, circle, e));
        });
        markersLayers.push(circle);
        return circle;
      });
    const markersLayersGroup = Leaflet.layerGroup(markersLayers);
    return [markers, markersLayersGroup];
  }, [items, dataExtractor, events]);

  useEffect(() => {
    if (!map || !items || !items.length) return () => {};
    const canvas = canvasRenderer.current;
    if (forwardEventsToParcels) {
      map.on('click', forwardClickToParcelsCB);
      map.on('mousemove', forwardMouseMoveToParcelsCB);
    }
    map.addLayer(markersLayersGroup);
    return () => {
      if (forwardEventsToParcels) {
        map.off('click', forwardClickToParcelsCB);
        map.off('mousemove', forwardMouseMoveToParcelsCB);
      }
      canvas.removeFrom(map);
      markers.forEach((circle) => {
        Object.keys(events || {}).forEach((event) => {
          circle.off(event);
        });
      });
      markersLayersGroup.removeFrom(map);
    };
  }, [
    map,
    items,
    events,
    dataExtractor,
    forwardEventsToParcels,
    forwardClickToParcelsCB,
    forwardMouseMoveToParcelsCB,
    markers,
    markersLayersGroup,
  ]);
  return markers;
}

export function useStyleBuilders(styles = []) {
  return useCallback(
    (feature) => {
      return styles.reduce((style, styleBuilder) => {
        return { ...style, ...styleBuilder(feature) };
      }, {});
    },
    [styles],
  );
}

export function useTranslatedInstructionsToDraw({ key, start, cont, end }) {
  const { t } = useContext(I18nContext);
  useEffect(() => {
    Leaflet.drawLocal.draw.handlers[key].tooltip.start = t(start);
    Leaflet.drawLocal.draw.handlers[key].tooltip.cont = t(cont);
    Leaflet.drawLocal.draw.handlers[key].tooltip.end = t(end);
  }, [t, start, cont, end, key]);
}

function useSpecificLocationPoints(mask) {
  return mask ? JSON.parse(mask.split('=')[1]) : undefined;
}
