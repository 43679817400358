import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const SpreaderIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M40.4,23.4h0.9c0.9,0,1.7,0.8,1.7,1.7v0c0,0.9-0.8,1.7-1.7,1.7H28"
        />
        <g>
          <g>
            <g>
              <path
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
					M17,18.3c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S21.7,18.3,17,18.3z M17,30c-1.8,0-3.3-1.5-3.3-3.3
					s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S18.8,30,17,30z"
              />
            </g>
          </g>
        </g>
        <g>
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="25.5"
            y1="26.8"
            x2="39.4"
            y2="26.8"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="6.2"
            y1="26.8"
            x2="8.1"
            y2="26.8"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M40.4,23.4v-5.9c0-1.7-1.4-3.1-3.1-3.1H11.4c-0.7,0-1.3,0-1.7,0"
          />
        </g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M9.7,14.4l1-3.6c0.2-0.6-0.2-1.2-0.8-1.4L8,8.8C7.4,8.6,6.8,9,6.6,9.6l-0.9,3.2c-0.2,0.8-0.9,1.4-1.8,1.5l-0.6,0.1l0.5,0.4
		c0.7,0.5,1,1.4,0.7,2.2l0,0c-0.2,0.8-0.9,1.4-1.8,1.5l-0.6,0.1L2.7,19c0.7,0.5,1,1.4,0.7,2.2l0,0c-0.2,0.8-0.9,1.4-1.8,1.5L1,22.8
		l0.5,0.4c0.7,0.5,1,1.4,0.7,2.2L1,29.7c-0.2,0.6,0.2,1.2,0.8,1.4l1.9,0.6c0.6,0.2,1.2-0.2,1.4-0.8l1.2-4.2"
        />
        <g>
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M20.7,14.4l3.1-3.9c0.9-1.1,2.5-1.2,3.5-0.1l3.5,4"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M30.7,14.4l2.3-2.9c0.6-0.8,1.9-0.9,2.6-0.1l2.6,2.9"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M13.3,14.4l2.3-2.9c0.6-0.8,1.9-0.9,2.6-0.1l2.6,2.9"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
