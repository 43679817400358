export const DeleteIcon = (props) => (
  <svg fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`} viewBox="0 0 42 42">
    <g>
      <path
        fill="none"
        stroke={props.color}
        d="M18.4,16.9l0.1-1.2c0-0.6,0.5-1.1,1.1-1.1h2.8c0.6,0,1.1,0.5,1.1,1.1l0.1,1.2"
      />
      <g>
        <path
          fill="none"
          stroke={props.color}
          d="M23.6,27.3h-5.2c-1.1,0-2.1-0.9-2.1-2.1l-1-8.3h11.3l-1,8.3C25.7,26.4,24.8,27.3,23.6,27.3z"
        />
        <line fill="none" stroke={props.color} x1="14.5" y1="16.9" x2="27.5" y2="16.9" />
      </g>
      <g>
        <line fill="none" stroke={props.color} x1="22.8" y1="23.9" x2="19.2" y2="20.3" />
        <line fill="none" stroke={props.color} x1="19.2" y1="23.9" x2="22.8" y2="20.3" />
      </g>
    </g>
  </svg>
);
