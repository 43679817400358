import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const PlowIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <ellipse
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="36.9"
          cy="30.9"
          rx="6.1"
          ry="6.2"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1"
          y1="12.3"
          x2="10.5"
          y2="16.4"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		33,26.1 29.1,21.4 10.5,21.4 10.5,16.4 36.9,16.4 36.9,24.6 	"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		10.5,21.4 1,21.4 10.5,16.4 	"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M27.7,16.4C25.8,9.1,20,6.9,20,6.9v9.5L27.7,16.4z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M18.2,16.4c-1.9-7.3-7.6-9.5-7.6-9.5v9.5L18.2,16.4z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M20,21.4v9.5c0,0,5.7-2.2,7.6-9.5L20,21.4z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M10.5,21.4v9.5c0,0,5.7-2.2,7.6-9.5L10.5,21.4z"
        />
      </g>
    </svg>
  </CircledIcon>
);
