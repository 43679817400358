import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const InterventionDetailsIcon = ({
  color = '#000',
  size = 30,
  backgroundColor = colors('grey'),
  circled = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 22.6 27"
    >
      <g transform="translate(1 1)">
        <path
          d="M71.386,2.991h-2.42A1.877,1.877,0,0,0,67.2,4.908V24.082A1.877,1.877,0,0,0,68.966,26H86.035A1.876,1.876,0,0,0,87.8,24.082V4.908a1.876,1.876,0,0,0-1.765-1.917H83.469"
          transform="translate(-67.2 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M83.312,2.991A2.045,2.045,0,0,0,81.252,1H73.6a2.093,2.093,0,0,0-2.06,1.991v2.36H83.312Z"
          transform="translate(-67.2 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
