import { useContext } from 'react';
import { MapContext } from '../../contexts/map';
import { useDrawDevicesOnMap } from './effects';

const Devices = function ({ devices, events }) {
  const { map } = useContext(MapContext);
  useDrawDevicesOnMap(map, devices, events);
  return null;
};

export default Devices;
