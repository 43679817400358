import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const FarmerIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 21.5 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M402.353,74.151a6.021,6.021,0,0,1,.239,1.726,5.843,5.843,0,0,1-.36,2.083,4.783,4.783,0,0,1-1.017,1.632,4.544,4.544,0,0,1-1.574,1.061,5.684,5.684,0,0,1-4.071,0,4.547,4.547,0,0,1-1.577-1.061,4.785,4.785,0,0,1-1.018-1.632,6.222,6.222,0,0,1,0-4.166"
          transform="translate(-387.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M400.211,80.414a10.121,10.121,0,0,1,1.657.478,8.94,8.94,0,0,1,3.114,1.983,8.644,8.644,0,0,1,1.927,3.069,8.217,8.217,0,0,1,.438,1.556h-19.5a7.913,7.913,0,0,1,.43-1.556,8.727,8.727,0,0,1,1.956-3.069,8.835,8.835,0,0,1,3.09-1.982,9.94,9.94,0,0,1,1.3-.4"
          transform="translate(-387.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="17.547"
          transform="translate(0.984 6.665)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M402.392,74.2h-9.576l1.927-6.7h5.723Z"
          transform="translate(-387.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
