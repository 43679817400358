import { connect } from 'react-redux';
import { getBinary, getClusterImage } from '../../api/api';
import { Context } from './context';

const Provider = function ({ children, getBinary, getClusterImage }) {
  return (
    <Context.Provider
      value={{
        getBinary,
        getClusterImage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const ReduxConnectedProvider = connect(null, () => ({
  getBinary: (id, params) => getBinary(id, params),
  getClusterImage: (clusterId) => getClusterImage(clusterId),
}))(Provider);
export { ReduxConnectedProvider as Provider };
