import { useContext } from 'react';
import { CentralModal } from '@karnott/modals';
import { I18nContext } from '../contexts/I18nProvider';
import { ANALYTICS, sendAnalyticsBtnClickEvent } from '../utils/AnalyticsConstants';

const GoToMobileApp = function ({ isOpen, onRequestClose }) {
  const { t } = useContext(I18nContext);

  return (
    <CentralModal
      showModal={isOpen}
      onClose={() => {
        sendAnalyticsBtnClickEvent(window)(ANALYTICS.LABEL.APP.CONTINUE_TO_WEBSITE);
        onRequestClose();
      }}
      cancelLabel={t('GoToMobileApp.continue')}
      saveLabel={t('GoToMobileApp.download_mobile_app')}
      onSave={() => {
        sendAnalyticsBtnClickEvent(window)(ANALYTICS.LABEL.APP.GO_TO_APPLICATION_STORE);
        if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.karnott';
        }
        if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
          window.location.href = 'https://itunes.apple.com/fr/app/karnott/id1451207222?l=fr&ls=1&mt=8';
        }
      }}
      width="90%"
      maxWidth="550px"
    >
      <p>{t('GoToMobileApp.info')}</p>
    </CentralModal>
  );
};

export default GoToMobileApp;
