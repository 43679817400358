import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { openLeafletPopup } from '../../../../utils/map';
import { driverTooltipData } from './effects';
import { DriverTooltip } from './tooltip';

export function openDriverTooltip(driverProps, layer, labels) {
  const tooltip = <DriverTooltip {...{ driverProps, labels }} />;
  const popupOptions = {
    direction: 'top',
    offset: [-3, -38],
    closeButton: false,
    interactive: true,
    maxWidth: 400,
  };
  openLeafletPopup(layer, tooltip, popupOptions);
}

export function useDriverEventsRef(labels, setOverTrackID) {
  const history = useHistory();

  return useRef({
    click: (d) => history.push(`/drivers/${d.id}/`),
    mouseover: (d, l, { iconMarker, backgroundMarker }) => {
      if (d?.activeSession) {
        setOverTrackID(d.activeSession.deviceSessionId);
      }
      const driverProps = driverTooltipData(d, history);
      openDriverTooltip(driverProps, iconMarker, labels);
      iconMarker.setZIndexOffset(10000);
      backgroundMarker.setZIndexOffset(10000);
    },
    mouseout: (d, l, { iconMarker, backgroundMarker }) => {
      if (d?.activeSession) {
        setOverTrackID(null);
      }
      iconMarker.setZIndexOffset(0);
      backgroundMarker.setZIndexOffset(0);
    },
  });
}
