export const DriverIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <g>
        <path
          fill="none"
          stroke={props.color}
          d="M25.2,18.7c0.3,0.2,0.4,0.4,0.4,0.7c0,0.9-2,1.6-4.5,1.6s-4.5-0.7-4.5-1.6c0-0.2,0.1-0.4,0.3-0.6"
        />
        <g>
          <path
            fill="none"
            stroke={props.color}
            d="M21,14.6c-1.7,0-3.4,1.1-4.4,1.8c-1,0.8-0.9,1.7,0.2,2.3c0,0,0,0,0,0c0,0,0,0,0.1,0c0.6,0,7.7,0,8.2,0
				c0,0,0,0,0.1,0c0,0,0,0,0,0c1.1-0.6,1.2-1.5,0.2-2.3C24.4,15.6,22.7,14.6,21,14.6z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke={props.color}
            d="M21,14.6c-1.7,0-3.4,1.1-4.4,1.8c-1,0.8-0.9,1.7,0.2,2.3c0,0,0,0,0,0c0,0,0,0,0.1,0c0.6,0,7.7,0,8.2,0
				c0,0,0,0,0.1,0c0,0,0,0,0,0c1.1-0.6,1.2-1.5,0.2-2.3C24.4,15.6,22.7,14.6,21,14.6z"
          />
        </g>
      </g>
      <g>
        <path
          fill="none"
          stroke={props.color}
          d="M17.1,20.3V22c-0.5,0.1-0.9,0.6-0.9,1.1c0,0.6,0.4,1.1,1,1.1c0.1,0.8,0.5,1.5,1.1,2.1C19,27,20,27.4,21,27.4
			h0c1,0,2-0.4,2.8-1.1c0.6-0.6,0.9-1.3,1.1-2.1c0.6-0.1,1-0.5,1-1.1c0-0.6-0.4-1-0.9-1.1v-1.7"
        />
      </g>
      <path fill="none" stroke={props.color} d="M19.6,24.2c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6" />
    </g>
  </svg>
);
