import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const FitDataIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 20.5 20.5"
    >
      <defs>
        <clipPath>
          <rect width="20.5" height="20.5" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clipPath)">
        <path
          d="M15.117,19.75H19.75V15.117"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M19.75,5.383V.75H15.117"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M.75,15.117V19.75H5.383"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M.75,5.383V.75H5.383"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="4.103"
          cy="4.103"
          r="4.103"
          transform="translate(6.147 6.147)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
