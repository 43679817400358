import { Component } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { I18nContext } from '../contexts/I18nProvider';
import logo from '../images/karnott_logo.svg';
import Sentry from '../utils/Sentry';

const ErrorBoundaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  img {
    max-width: 33%;
  }
`;

const Redirect = styled.div`
  color: ${colors('orange', 'dark')};
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
`;

export class ErrorBoundaryContainer extends Component {
  static contextType = I18nContext;

  constructor(props) {
    super(props);
    this.state = { hasError: false, isChunk: false };
  }

  static getDerivedStateFromError(error) {
    Sentry.getInstance().reportError(error);
    const chunkFailedMessage = /Loading chunk \d+ failed/;
    return { hasError: true, isChunk: chunkFailedMessage.test(error.message) };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.isChunk) {
        return (
          <ErrorBoundaryWrapper>
            <img alt="karnott" src={logo} />
            <div>{this.context.t('ErrorBoundary.chunk.error')}</div>
            <Redirect
              onClick={() => {
                window.location.reload();
              }}
            >
              {this.context.t('ErrorBoundary.chunk.reload')}
            </Redirect>
          </ErrorBoundaryWrapper>
        );
      } else {
        return (
          <ErrorBoundaryWrapper>
            <img alt="karnott" src={logo} />
            <div>{this.context.t('ErrorBoundary.error')}</div>
            <Redirect
              onClick={() => {
                window.location.replace('/');
              }}
            >
              {this.context.t('ErrorBoundary.back_to_dashboard')}
            </Redirect>
          </ErrorBoundaryWrapper>
        );
      }
    }

    return this.props.children;
  }
}
