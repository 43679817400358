import { Actions } from '../actions';

const initialState = {
  tasks: [],
  loading: true,
  lastFetched: null,
  metadataByType: {},
};

const tasks = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.GET_TASK:
      return {
        ...state,
        tasks: [...state.tasks.filter((task) => task.id !== payload.task.id), payload.task],
        lastFetched: payload.task,
      };
    case Actions.GET_TASKS:
      return {
        ...state,
        tasks: payload.tasks,
      };
    case Actions.DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== payload.id),
      };
    case Actions.TASKS_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case Actions.GET_TASK_METADATA:
      return {
        ...state,
        metadataByType: {
          ...state.metadataByType,
          [payload.metadata[0].task_type]: payload.metadata,
        },
      };
    case Actions.TASK_EMAIL_SENT:
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.id === payload.id ? { ...task, mail_count: (task.mail_count || 0) + 1 } : task,
        ),
      };
    default:
      return state;
  }
};

export default tasks;
