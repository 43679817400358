import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const HelpIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '40%' : '100%'}
      height={circled ? '40%' : '100%'}
      viewBox="0 0 10 17"
    >
      <path
        d="M1035.655,207.151c0-3.081,4.7-3.277,4.7-5.87,0-1.2-.9-1.884-2.445-1.884-1.356,0-2.276.686-2.276,2.056a1.332,1.332,0,1,1-2.664,0c0-2.789,1.841-4.453,4.94-4.453,3.2,0,5.06,1.542,5.06,4.281,0,3.939-4.624,4.232-4.624,5.7a.99.99,0,0,0,.242.588,1.3,1.3,0,0,1,.218.733,1.321,1.321,0,0,1-1.307,1.321,1.287,1.287,0,0,1-.945-.391,2.863,2.863,0,0,1-.9-2.08m.17,5.26a1.447,1.447,0,0,1,1.6-1.639,1.431,1.431,0,0,1,1.574,1.639,1.4,1.4,0,0,1-1.574,1.589,1.421,1.421,0,0,1-1.6-1.589"
        transform="translate(-1032.967 -197)"
        fill={color}
      />
    </svg>
  </CircledIcon>
);
