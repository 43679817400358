import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ClustersIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 28.5 28.5"
    >
      <defs>
        <clipPath>
          <rect width="28.5" height="28.5" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clipPath)">
        <path
          d="M12.733,13.651a1.231,1.231,0,0,1,.318.92v1.593h-.807V14.695A.726.726,0,0,0,12.1,14.2a.53.53,0,0,0-.419-.163.642.642,0,0,0-.486.188.782.782,0,0,0-.181.561v1.376h-.807V12.327h.807v1.345a1.064,1.064,0,0,1,.388-.246,1.4,1.4,0,0,1,.5-.085,1.143,1.143,0,0,1,.835.31"
          fill={color}
        />
        <path
          d="M15.9,13.648a1.175,1.175,0,0,1,.346.928v1.587h-.755v-.345a.9.9,0,0,1-.847.387,1.319,1.319,0,0,1-.556-.109.833.833,0,0,1-.36-.3.777.777,0,0,1-.124-.435.722.722,0,0,1,.292-.609,1.483,1.483,0,0,1,.9-.222h.642a.516.516,0,0,0-.161-.407.705.705,0,0,0-.481-.142,1.386,1.386,0,0,0-.436.07,1.144,1.144,0,0,0-.365.189l-.29-.564a1.736,1.736,0,0,1,.546-.248,2.427,2.427,0,0,1,.654-.088,1.449,1.449,0,0,1,.993.308m-.688,1.918a.527.527,0,0,0,.228-.281V15h-.554c-.33,0-.5.108-.5.325a.293.293,0,0,0,.122.246.554.554,0,0,0,.334.091.7.7,0,0,0,.367-.1"
          fill={color}
        />
        <path
          d="M18.292,14.044v.4H16.7v-.316l.814-.769a.848.848,0,0,0,.175-.213.387.387,0,0,0,.045-.176.229.229,0,0,0-.086-.194.393.393,0,0,0-.251-.068.576.576,0,0,0-.25.052.477.477,0,0,0-.187.159l-.356-.229a.835.835,0,0,1,.341-.289,1.138,1.138,0,0,1,.5-.1,1.013,1.013,0,0,1,.412.077.635.635,0,0,1,.274.217.562.562,0,0,1,.1.33.724.724,0,0,1-.073.322,1.228,1.228,0,0,1-.28.344l-.485.458Z"
          fill={color}
        />
        <circle
          cx="8.5"
          cy="8.5"
          r="8.5"
          transform="translate(5.75 5.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="13.5"
          cy="13.5"
          r="13.5"
          transform="translate(0.75 0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
