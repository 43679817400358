import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const EnjambeurIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <path
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
	M10.9,43h2.7c0.6,0,1.1-0.5,1.1-1.1v-5.1c0-0.6-0.5-1.1-1.1-1.1h-2.7c-0.6,0-1.1,0.5-1.1,1.1v5.1C9.8,42.5,10.3,43,10.9,43z"
      />
      <path
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
	M30.3,43h2.7c0.6,0,1.1-0.5,1.1-1.1v-5.1c0-0.6-0.5-1.1-1.1-1.1h-2.7c-0.6,0-1.1,0.5-1.1,1.1v5.1C29.2,42.5,29.7,43,30.3,43z"
      />
      <polyline
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	12.3,35.7 12.3,22.8 31.7,22.8 31.7,35.7 "
      />
      <polygon
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	29.4,22.8 14.9,22.8 12.4,1 31.6,1 "
      />
      <g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M31.8,9.8h2.5c0.4,0,0.7-0.3,0.7-0.7V5.9c0-0.4-0.3-0.7-0.7-0.7h-2.5c-0.4,0-0.7,0.3-0.7,0.7v3.3C31.1,9.5,31.4,9.8,31.8,9.8z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M9.7,9.8h2.5c0.4,0,0.7-0.3,0.7-0.7V5.9c0-0.4-0.3-0.7-0.7-0.7H9.7C9.3,5.2,9,5.5,9,5.9v3.3C9,9.5,9.3,9.8,9.7,9.8z"
        />
      </g>
      <path
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
	M16.9,22.8v-1.7c0-2.2,1.8-3.9,3.9-3.9h2.3c2.2,0,3.9,1.8,3.9,3.9v1.7"
      />
      <line
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="13.1"
        y1="5.3"
        x2="30.9"
        y2="5.3"
      />
    </svg>
  </CircledIcon>
);
