import { useCallback, useContext, useEffect, useMemo } from 'react';
import moment from 'moment';
import { colors } from '@karnott/colors';
import { EyeIcon, ImplementIcon, InterventionDetailsIcon, PlotsIcon } from '@karnott/icons';
import { LOCALE_FORMAT } from '../../../../constants';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { UIContext } from '../../../../contexts/ui';
import { useDrawMarkersOnMap } from '../../effects';

export function useDrawObservationsOnMap(map, observations, events) {
  const { focusedObservationId } = useContext(UIContext);

  const dataExtractor = useCallback(
    (o) => {
      let bgColor = colors('orange');
      if (o.color) bgColor = o.color;
      if (o.id === focusedObservationId) bgColor = colors('black', 'dark');

      return {
        location: { latitude: o.location_latitude, longitude: o.location_longitude },
        Icon: EyeIcon,
        iconColor: colors('white'),
        backgroundColor: bgColor,
        iconSize: 34,
        iconAnchor: [19.5, 42],
      };
    },
    [focusedObservationId],
  );

  const markers = useDrawMarkersOnMap(map, observations, dataExtractor, events);

  useEffect(() => {
    markers.forEach((m) => {
      const { item, iconMarker, backgroundMarker } = m;
      if (focusedObservationId === item.id) {
        iconMarker.setZIndexOffset(10000);
        backgroundMarker.setZIndexOffset(10000);
      } else {
        iconMarker.setZIndexOffset(0);
        backgroundMarker.setZIndexOffset(0);
      }
    });
  }, [focusedObservationId, markers]);
}

export function useObservationsCoords(observations) {
  return useMemo(() => {
    let obs = [];
    if (observations && observations.length) {
      obs = observations;
    }
    return obs
      .filter((o) => o.location_latitude && o.location_longitude)
      .reduce((value, observation) => {
        return value.concat([[observation.location_latitude, observation.location_longitude]]);
      }, []);
  }, [observations]);
}

export function useObservationTooltipLabels() {
  const { t } = useContext(I18nContext);
  return useMemo(
    () => ({
      last_update: t('Tooltip.last_updater'),
      see: t('Commons.see'),
      archived: t('Observations.status_archived'),
    }),
    [t],
  );
}

export function observationTooltipData(observation, history) {
  let action = () => {},
    Icon,
    name,
    imageCount = observation.binaries?.length || 0;
  if (observation.parcel_id) {
    action = () => history.replace(`/parcels/${observation.parcel_id}/observations`);
    Icon = PlotsIcon;
    if (observation.parcel?.name) {
      name = observation.parcel.name;
    }
  } else if (observation.device_instance_id && observation.equipment_session_id) {
    if (observation.equipment_instance?.id) {
      action = () =>
        history.replace(
          `/equipments/${observation.equipment_instance.id}/sessions/${observation.equipment_session_id}/observations`,
        );
    }
    Icon = InterventionDetailsIcon;
    const fromDate = observation.equipment_session?.from_date || observation.equipment_session?.fromDate;
    if (fromDate) {
      name = moment(fromDate).format(LOCALE_FORMAT);
    } else {
      name = moment(observation.occurred_at).format(LOCALE_FORMAT);
    }
  } else if (observation.equipment_instance_id) {
    action = () => history.replace(`/equipments/${observation.equipment_instance_id}/observations`);
    Icon = ImplementIcon;
    name = observation.equipment_instance.label;
  }

  return {
    occuredAt: moment(observation.occurred_at).format(LOCALE_FORMAT),
    updatedAt: moment(observation.updated_at).format(LOCALE_FORMAT),
    updater: observation.updater_firstname + ' ' + observation.updater_lastname,
    content: observation.content,
    author: observation.firstname + ' ' + observation.lastname,
    onClick: action,
    categoryLabel: observation.categoryLabel,
    categoryColor: observation.color || colors('orange'),
    Icon: Icon,
    name: name,
    status: observation.status,
    imageCount,
  };
}
