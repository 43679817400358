import { useContext } from 'react';
import { PermissionsContext } from '../contexts/permissions';

export const PermissionSwitcher = function ({ children, authorizations, action, clusterAuthorization }) {
  const { satisfies } = useContext(PermissionsContext);
  if (satisfies({ authorizations, action, clusterAuthorization })) {
    return children;
  }
  return null;
};
