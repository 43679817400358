import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const PlotsIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 24.001 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M1157.625,77.94,1154.6,80l11,7.5,11-7.5-3.028-2.063"
          transform="translate(-1154.597 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1165.6,67.5l-11,7.5,11,7.5,11-7.5Z"
          transform="translate(-1154.597 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
