import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const FieldRollerIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M5.2,34.5L5.2,34.5c-0.5,0-0.9-0.4-0.9-0.9v-4.6c0-0.5,0.4-0.9,0.9-0.9h10.2h0c0.5,0,0.9,0.4,0.9,0.9v4.6c0,0.5-0.4,0.9-0.9,0.9
		H5.2z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M28.6,34.5L28.6,34.5c-0.5,0-0.9-0.4-0.9-0.9v-4.6c0-0.5,0.4-0.9,0.9-0.9h10.2h0c0.5,0,0.9,0.4,0.9,0.9v4.6c0,0.5-0.4,0.9-0.9,0.9
		H28.6z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M4.1,30.9H1.5c-0.3,0-0.5-0.2-0.5-0.5v-3.9c0-1.2,1-2.1,2.1-2.1h14.3c1.2,0,2.1,1,2.1,2.1v3.9c0,0.3-0.2,0.5-0.5,0.5h-2.6"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M27.6,30.9h-2.7c-0.3,0-0.5-0.2-0.5-0.5v-3.9c0-1.2,1-2.1,2.1-2.1h14.3c1.2,0,2.1,1,2.1,2.1v3.9c0,0.3-0.2,0.5-0.5,0.5h-2.6"
        />
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			10.6,24.4 18.4,18 25.9,18 33.7,24.3 		"
          />

          <polygon
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			25.9,18 18.4,18 21.3,9.5 22.1,9.5 23,9.5 		"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
