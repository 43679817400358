import { useContext } from 'react';
import { Button } from '@karnott/buttons';
import { FullScreenIcon } from '@karnott/icons';
import { I18nContext } from '../../../contexts/I18nProvider';
import { MapOverlayContext } from '../contexts/overlay';

const FullScreenControl = function ({ selected, onDashboard }) {
  const { toggleFullScreen, toggleFullScreenDashboard } = useContext(MapOverlayContext);
  const { t } = useContext(I18nContext);

  return (
    <Button
      Icon={FullScreenIcon}
      onClick={onDashboard ? toggleFullScreenDashboard : toggleFullScreen}
      message={t('Controls.fullscreen')}
      primary
      outlinedNeutral
      tooltipOrientation="left"
      noWrap
      outlined={selected}
      badge={selected}
    />
  );
};

export default FullScreenControl;
