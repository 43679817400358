export const KarnottWithNameIcon = ({ size, color }) => (
  <svg width={size * (21 / 8) || 210} height={size || 80} viewBox="0 0 567 270">
    <g id="Interface" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="splash" transform="translate(-102.000000, -252.000000)" fillRule="nonzero">
        <g id="Karnott_mark_color" transform="translate(102.000000, 252.000000)">
          <path
            fill={color}
            d="M215.46,112.63h9.38v27.11l25.44-27.11H262.2L239.87,137l22.66,31.24H251.39l-18.44-24.33-8.11,8.74V168.2h-9.38Z"
          />
          <path
            fill={color}
            d="M303.7,168.2h-8.11v-5.64q-5.25,6.28-14.07,6.28a16.13,16.13,0,0,1-10.93-3.74,12.51,12.51,0,0,1-4.33-10q0-6.24,4.61-9.34t12.48-3.1h11.53v-1.59q0-8.43-9.38-8.43-5.88,0-12.24,4.29l-4-5.57a27.38,27.38,0,0,1,17.49-6.12q7.47,0,12.2,3.78t4.73,11.88Zm-8.9-15.74v-3.58h-10q-9.62,0-9.62,6a5.37,5.37,0,0,0,2.38,4.73,11.65,11.65,0,0,0,6.64,1.63,12,12,0,0,0,7.43-2.38A7.63,7.63,0,0,0,294.8,152.46Z"
          />
          <path
            fill={color}
            d="M338.28,134.25q-6.6,0-9.94,4.29T325,150.07V168.2h-8.9V125.91H325v8.51a18.43,18.43,0,0,1,5.84-6.52,14.08,14.08,0,0,1,7.83-2.62l.08,9Z"
          />
          <path
            fill={color}
            d="M356.57,145.3v22.9h-8.9V125.91h8.9v7.71a16,16,0,0,1,14.07-8.35q7.15,0,11.57,4.37t4.41,12.64V168.2h-8.9V145q0-11.69-9.7-11.69a11.65,11.65,0,0,0-8,3.06Q356.57,139.42,356.57,145.3Z"
          />
          <path
            fill={color}
            d="M434.4,162.52a23.16,23.16,0,0,1-31.8,0,22,22,0,0,1,0-30.92,23.16,23.16,0,0,1,31.8,0,22,22,0,0,1,0,30.92ZM408.92,157a13.83,13.83,0,0,0,19.16,0,15.06,15.06,0,0,0,0-20,13.83,13.83,0,0,0-19.16,0,15.07,15.07,0,0,0,0,20Z"
          />
          <path
            fill={color}
            d="M461.18,133.06v21.46a7,7,0,0,0,1.59,4.81,5.64,5.64,0,0,0,4.45,1.79,7.58,7.58,0,0,0,5.49-2.78l3.66,6.28A15.17,15.17,0,0,1,466,168.84a13.36,13.36,0,0,1-9.7-3.93q-4-3.94-4-10.61V133.06H447v-7.16h5.33V112.63h8.9v13.28h11.13v7.16Z"
          />
          <path
            fill={color}
            d="M494.42,133.06v21.46a7,7,0,0,0,1.59,4.81,5.64,5.64,0,0,0,4.45,1.79,7.58,7.58,0,0,0,5.49-2.78l3.66,6.28a15.17,15.17,0,0,1-10.37,4.21,13.35,13.35,0,0,1-9.7-3.93q-4-3.94-4-10.61V133.06h-5.33v-7.16h5.33V112.63h8.9v13.28h11.13v7.16Z"
          />
          <path
            fill={color}
            d="M158,113.87a24.14,24.14,0,0,0-8.24,1.44,35.83,35.83,0,0,1,4.42,11.54,11.85,11.85,0,1,1-2.15,21.43,114.85,114.85,0,0,1-7.76,17,205,205,0,0,0,13.73,20s17.62-22.13,22.78-39.09a24.08,24.08,0,0,0,1.39-7.27c0-.29,0-.59,0-.87A24.19,24.19,0,0,0,158,113.87Z"
          />
          <path
            fill={color}
            d="M80.88,149.91a11.85,11.85,0,1,1,2.2-23.48,36.07,36.07,0,0,1,4.63-11.57,24.19,24.19,0,0,0-31,23.2c0,.29,0,.58,0,.87a24.17,24.17,0,0,0,1.38,7.26c5.16,17,22.79,39.1,22.79,39.1A205.49,205.49,0,0,0,93.72,166.8,121,121,0,0,1,85.4,149,11.79,11.79,0,0,1,80.88,149.91Z"
          />
          <path
            fill={color}
            d="M149.7,131.93v-.19a31,31,0,0,0-4.53-14.14h0c-.08-.13-.17-.26-.25-.39q-.3-.48-.62-.94l-.39-.55c-.25-.35-.51-.69-.77-1l-.27-.36c-.35-.43-.72-.85-1.09-1.27l-.39-.42q-.42-.45-.85-.88l-.41-.4c-.4-.38-.81-.76-1.23-1.12l-.13-.11q-.62-.52-1.26-1l-.43-.32q-.53-.39-1.07-.75l-.41-.27q-.73-.48-1.48-.91l-.26-.14q-.65-.36-1.31-.69l-.48-.23c-.42-.2-.85-.39-1.28-.58l-.36-.16q-.82-.34-1.66-.63l-.39-.13q-.67-.22-1.35-.41l-.52-.14c-.5-.13-1-.25-1.51-.35l-.27-.06c-.59-.12-1.19-.22-1.79-.3l-.49-.06c-.46-.06-.93-.1-1.4-.14l-.54,0c-.62,0-1.25-.06-1.88-.06s-1.36,0-2,.07a31.13,31.13,0,0,0-24.05,14.06h0l0,0q-.41.63-.79,1.28l0,.06c-.25.43-.49.86-.72,1.3l0,.07q-.34.66-.65,1.33l0,.08q-.31.68-.59,1.37l0,.07q-.28.7-.52,1.42l0,.06c-.16.48-.31,1-.45,1.47v0q-.21.75-.39,1.52v0q-.17.78-.31,1.58h0v0c0,.15,0,.3-.07.46q-.08.53-.15,1.06c0,.21,0,.43-.07.64s-.06.66-.09,1,0,.37,0,.56c0,.52,0,1.05,0,1.58,0,.37,0,.75,0,1.12a31.05,31.05,0,0,0,1.78,9.36c.33,1.1.72,2.22,1.13,3.35l.05.14c.21.58.43,1.16.66,1.74l.11.27q.33.83.68,1.67l.16.37c.23.53.46,1.07.7,1.6L93,154l.7,1.53.27.56c.23.49.47,1,.71,1.47l.3.6c.24.48.48,1,.73,1.44l.28.54.8,1.51h0a251.61,251.61,0,0,0,21.5,32.64l0,0,.15.19,0,0,.15.19s13.47-16.92,22.7-34.69h0c.32-.61.63-1.22.93-1.83l.12-.24c.28-.56.55-1.12.82-1.68l.2-.42c.24-.51.48-1,.71-1.53l.27-.6q.31-.69.61-1.38l.32-.76c.17-.41.35-.82.51-1.23s.25-.62.37-.94.28-.71.41-1.06.28-.77.42-1.16l.3-.83c.23-.66.44-1.32.64-2l0-.09,0-.08c.25-.7.47-1.42.67-2.14v0q.3-1.12.52-2.27c0-.09,0-.18,0-.27.13-.69.24-1.38.31-2.08s.15-1.6.18-2.41c0-.38,0-.75,0-1.12C149.76,133.23,149.74,132.58,149.7,131.93Zm-31.09,17.22a20.67,20.67,0,0,1-4.33-.66,15.26,15.26,0,0,1-7.68-5.23l-.12-.16c-.17-.22-.34-.44-.5-.68s-.21-.33-.31-.5-.17-.25-.24-.38c-.18-.3-.34-.61-.5-.92h0c-.23-.46-.43-.94-.61-1.43l-.09-.26c-.11-.31-.21-.62-.3-.93s-.14-.5-.2-.76-.07-.34-.1-.51c-.08-.39-.14-.78-.18-1.17,0-.1,0-.19,0-.29,0-.45-.07-.91-.07-1.38s0-.86.06-1.29c0-.16,0-.33,0-.49s.08-.55.13-.83.13-.66.2-1v0a15.25,15.25,0,1,1,14.81,18.87Z"
          />
          <path
            fill={color}
            d="M140.35,78l-6.49-2.81a3.26,3.26,0,0,0-4.32,1.73l-4,9.25V77.89a3.41,3.41,0,0,0-3.42-3.32h-7.07a3.23,3.23,0,0,0-3.22,3.32v8.27l-4.06-9.25a3.35,3.35,0,0,0-4.39-1.73L96.92,78a3.32,3.32,0,0,0-1.73,4.37l7.72,17.7a32.69,32.69,0,0,1,9-3,43.06,43.06,0,0,1,4.5-.57h0c.76,0,1.48-.08,2.25-.08s1.6,0,2.37.08a45.42,45.42,0,0,1,4.59.57,44.45,44.45,0,0,1,8.87,2.94l7.64-17.69A3.33,3.33,0,0,0,140.35,78Z"
          />
          <path fill={color} d="M507.08,184.68a1.83,1.83,0,1,1,1.31.55A1.78,1.78,0,0,1,507.08,184.68Z" />
        </g>
      </g>
    </g>
  </svg>
);
