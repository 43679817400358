import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const KarnottIcon = ({
  color = '#000',
  size = 30,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 29 14.5"
    >
      <g transform="translate(1 1)">
        <path
          d="M1050.7,16.557l-2.362,2.753a1.269,1.269,0,0,1-.959.44h-19.753a1.264,1.264,0,0,1-.958-.44l-2.362-2.753a1.258,1.258,0,0,1-.3-.817V11.261a1.258,1.258,0,0,1,.3-.817l2.362-2.753a1.259,1.259,0,0,1,.958-.441h19.753a1.27,1.27,0,0,1,.959.441l2.362,2.753a1.256,1.256,0,0,1,.3.817V15.74A1.256,1.256,0,0,1,1050.7,16.557Z"
          transform="translate(-1024 -7.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1045.573,11.084a.913.913,0,1,1-.916.913.915.915,0,0,1,.916-.913"
          transform="translate(-1024 -7.25)"
          fill={color}
        />
        <path
          d="M1042.377,11.084a.913.913,0,1,1-.916.913.915.915,0,0,1,.916-.913"
          transform="translate(-1024 -7.25)"
          fill={color}
        />
        <path
          d="M1039.18,11.084a.913.913,0,1,1-.917.913.915.915,0,0,1,.917-.913"
          transform="translate(-1024 -7.25)"
          fill={color}
        />
        <line
          x1="26.334"
          transform="translate(0.417 8.99)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
