export const CrownIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <polyline fill="none" stroke={props.color} points="18.2,21.9 21.1,17.8 23.9,21.9 	" />
      <polyline fill="none" stroke={props.color} points="15.2,26.5 15.3,17.8 18.1,21.9 	" />
      <polyline fill="none" stroke={props.color} points="23.9,21.9 26.8,17.8 26.8,26.5 	" />
      <path fill="none" stroke={props.color} d="M26.8,26.6c-1.5,0.4-3.6,0.6-6,0.6c-2.1,0-4.1-0.2-5.6-0.5" />
      <circle fill="none" stroke={props.color} cx="21.1" cy="16.3" r="1.5" />
      <circle fill="none" stroke={props.color} cx="15.2" cy="16.3" r="1.5" />
      <circle fill="none" stroke={props.color} cx="26.8" cy="16.3" r="1.5" />
    </g>
  </svg>
);
