import { useCallback } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { ImplementPopup } from '@karnott/map';
import { family, spacing } from '../../../../ui/theme';
import { ANALYTICS, sendAnalyticsTooltipClickEvent } from '../../../../utils/AnalyticsConstants';
import IcTime from '../../../icons/IcTime';

const EquipmentTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-family: ${family('body')};
`;

const EquipmentTooltipRow = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
`;

const Separator = styled.div`
  margin: ${spacing / 8}px;
`;

export function EquipmentTooltip({ equipmentProps, labels }) {
  const {
    Icon,
    name,
    isActive,
    type,
    cluster,
    lastDate,
    deviceType,
    deviceName,
    deviceBattery,
    batteryCharging,
    equipmentId,
    healthIssue,
    history,
  } = equipmentProps;

  const actionCB = useCallback(() => {
    sendAnalyticsTooltipClickEvent(window)(ANALYTICS.LABEL.TOOLTIPS.EQUIPMENT);
    history.push(`/equipments/${equipmentId}/`);
  }, [history, equipmentId]);

  const openDiag = useCallback(() => {
    sendAnalyticsTooltipClickEvent(window)(ANALYTICS.LABEL.TOOLTIPS.DIAGNOSE);
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSetvNwPpoQIAxPgxwU-ERPJ0Pq0pwhW2uN5HENdus01YD_NWA/viewform',
      '_blank',
      'noopener noreferrer',
    );
  }, []);

  return (
    <ImplementPopup
      Icon={Icon}
      implementLabel={name}
      isActive={isActive}
      implementType={type}
      clusterName={cluster}
      lastDate={lastDate}
      deviceType={deviceType}
      deviceLabel={deviceName}
      warning={healthIssue}
      deviceBattery={deviceBattery}
      batteryCharging={batteryCharging}
      chipAction={actionCB}
      labels={labels}
      diagnoseAction={openDiag}
    />
  );
}

export function EquipmentSessionPointTooltip({ point }) {
  return (
    <EquipmentTooltipWrapper>
      <EquipmentTooltipRow>
        <IcTime />
        <Separator />
        {moment(point.location_date).format('HH:mm')}
      </EquipmentTooltipRow>
    </EquipmentTooltipWrapper>
  );
}
