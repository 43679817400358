import { colors } from '@karnott/colors';

export const WarningIcon = ({
  color = colors('white'),
  size = 30,
  backgroundColor = colors('red'),
  outlineColor = colors('white'),
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20.8 20.8">
    <g>
      <circle cx="10.4" cy="10.4" r="9.4" fill={backgroundColor} />
      <path
        d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4,10.4-4.7,10.4-10.4S16.1,0,10.4,0Zm0,18.8c-4.6,0-8.4-3.8-8.4-8.4S5.8,2,10.4,2s8.4,3.8,8.4,8.4-3.8,8.4-8.4,8.4Z"
        fill={outlineColor}
      />
    </g>
    <g>
      <path
        d="M9.9,12.6h.9c.3,0,.5-.2,.6-.5l.3-7.4c0-.1,0-.3-.2-.4-.1-.1-.2-.2-.4-.2h-1.4c-.1,0-.3,0-.4,.2-.1,.1-.2,.3-.2,.4l.2,7.4c0,.3,.3,.5,.6,.5Z"
        fill={color}
      />
      <path
        d="M11.4,14.3h0c-.5-.5-1.5-.5-2,0-.3,.3-.4,.6-.4,1s.1,.7,.4,1c.3,.3,.6,.4,1,.4s.7-.1,1-.4c.3-.3,.4-.6,.4-1s-.1-.7-.4-1Z"
        fill={color}
      />
    </g>
  </svg>
);
