import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Track = styled.div`
  flex: 1;
  position: relative;
  height: ${pixelSpacing('xSmall')};
  border-radius: ${spacing('xSmall') / 2}px;
  background-color: ${colors('grey', 200)};
`;

const SelectedTrack = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: ${spacing('xSmall') / 2}px;
  background-color: ${colors('green')};
`;

const Handle = styled.div<{
  hide?: boolean;
}>`
  position: absolute;
  top: ${-size('xLarge') / 2 + spacing('xSmall') / 2}px;
  height: ${pixelSize('xLarge')};
  width: ${pixelSize('xLarge')};
  background-color: ${colors('green', 'dark')};
  border-radius: calc(${pixelSize('xLarge')} / 2);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.06),
    0 3px 6px rgba(0, 0, 0, 0.13);
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
`;

const FormattedValue = styled.div`
  position: absolute;
  top: 100%;
  color: ${colors('grey')};
  white-space: nowrap;
  font-size: ${pixelSize('small')};
  width: 24px;
  text-align: center;
`;

type SliderValue = {
  start?: number;
  end: number;
};

type SliderEvent = (value: SliderValue) => void;

type Movement = {
  dx: number;
  dy: number;
};

type Props = {
  /** The minimum possible value */
  min?: number;
  /** The maximum possible value */
  max?: number;
  /**
   * The value of the slider. If the `start` property is not set, then the slider selects only one value, instead of a
   * range
   */
  value: SliderValue;
  /** Callback when the value changes */
  onChange?: SliderEvent;
  /** Callback when the dragging motion has ended */
  onDragEnd?: SliderEvent;
  /** Function to display the values */
  format?: (value: number) => string;
};

/** A slider to choose a numeric value, or a numeric range */
export function Slider({ min = 0, max = 100, value, onChange, onDragEnd, format }: Props) {
  const container = useRef<HTMLDivElement>(null);

  const [width] = UIHooks.useContainerSize(container);
  const isMultiple = useMemo(() => {
    return value && value.start !== undefined && value.start !== null;
  }, [value]);

  const [start, end] = useMemo(() => {
    if (isMultiple) {
      return [value.start!, value.end];
    } else {
      return [min, value.end];
    }
  }, [value, isMultiple, min]);

  const distanceUnit = useMemo(() => {
    return width ? width / (max - min) : 1;
  }, [min, max, width]);

  const [startDistance, endDistance] = useMemo(() => {
    return [(start - min) * distanceUnit, (end - min) * distanceUnit];
  }, [min, start, end, distanceUnit]);

  const updateEndFromDelta = useCallback(
    (onEvent: SliderEvent | undefined) => {
      return (deltas: Movement) => {
        if (onEvent) {
          let endValue = end + deltas.dx / distanceUnit;
          if (endValue >= max) {
            endValue = max;
          }
          if (endValue <= start) {
            endValue = start;
          }
          onEvent({ start: isMultiple ? start : undefined, end: endValue });
        }
      };
    },
    [end, distanceUnit, max, start, isMultiple],
  );

  const updateStartFromDelta = useCallback(
    (onEvent: SliderEvent | undefined) => {
      return (deltas: Movement) => {
        if (onEvent) {
          let startValue = start + deltas.dx / distanceUnit;
          if (startValue <= min) {
            startValue = min;
          }
          if (startValue >= end) {
            startValue = end;
          }
          onEvent({ start: startValue, end });
        }
      };
    },
    [distanceUnit, start, end, min],
  );

  const [endRef] = UIHooks.useDraggableContainer<HTMLDivElement>(
    updateEndFromDelta(onChange),
    updateEndFromDelta(onDragEnd),
  );
  const [startRef] = UIHooks.useDraggableContainer<HTMLDivElement>(
    updateStartFromDelta(onChange),
    updateStartFromDelta(onDragEnd),
  );
  return (
    <Container>
      <Track ref={container}>
        <SelectedTrack style={{ left: startDistance, width: Math.abs(endDistance - startDistance) }} />
        <Handle ref={startRef} hide={!isMultiple} style={{ left: startDistance - size('xLarge') / 2 }}>
          {format ? <FormattedValue>{format(value.start || 0)}</FormattedValue> : null}
        </Handle>
        <Handle ref={endRef} style={{ left: endDistance - size('xLarge') / 2 }}>
          {format ? <FormattedValue>{format(value.end)}</FormattedValue> : null}
        </Handle>
      </Track>
    </Container>
  );
}
