import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dhWidth from 'dom-helpers/query/width';
import mixpanel from 'mixpanel-browser';
import { INTERCOM_APP_ID } from '../constants';
import { UIContext } from '../contexts/ui';
import { sendAnalyticsLoginEvent, sendAnalyticsNavigationEvent } from '../utils/AnalyticsConstants';
import Sentry from './Sentry';

const WithAnalytics = (Wrapped) => {
  function Wrap({ user, hideIntercomMessenger }) {
    const { demoAccount } = useContext(UIContext);
    const location = useLocation();

    useEffect(() => {
      const w = dhWidth(document);
      if (import.meta.env.PROD) {
        if (user?.id) {
          let userID = user?.id;
          let userEmail = user?.email;
          let userName = user?.fullname;
          let userHash = user?.intercom_hash;
          let hide_default_launcher = hideIntercomMessenger;
          if (user?.current_application === 'Backend') {
            userID = 1;
            userEmail = 'support@karnott.fr';
            userName = 'Support Karnott';
            hide_default_launcher = true;
          }
          mixpanel.identify(userID);
          mixpanel.people.set({ Email: userEmail, Name: userName });
          if (demoAccount) {
            userEmail = user.fullname;
          }
          const intercomSettings = {
            app_id: INTERCOM_APP_ID,
            email: userEmail,
            user_id: userID,
            name: userName,
            horizontal_padding: 20,
            vertical_padding: 20,
            hide_default_launcher,
            user_hash: userHash,
          };
          if (w < 1180) {
            intercomSettings.alignment = 'left';
          }
          window.Intercom('boot', intercomSettings);
          Sentry.getInstance().setUser({
            email: userEmail,
            id: userID,
          });
        }
      }
      if (user?.id) {
        let userID = user?.id;
        let userEmail = user?.email;
        sendAnalyticsLoginEvent(window)({ email: userEmail, id: userID });
      }
    }, [user, demoAccount, hideIntercomMessenger]);

    useEffect(() => {
      if (import.meta.env.PROD) {
        mixpanel.track_pageview({ page: location.pathname });
        window.Intercom('update', { last_request_at: `${parseInt(new Date().getTime() / 1000)}` });
      }
      sendAnalyticsNavigationEvent(window)({ pathname: location.pathname });
    }, [location.pathname, location.hash]);

    return <Wrapped />;
  }

  const mapStateToProps = (state) => ({
    user: state.user,
    hideIntercomMessenger: state.ui.hideIntercomMessenger,
  });

  return connect(mapStateToProps, null)(Wrap);
};

export default WithAnalytics;
