export const AddressIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <path
        fill="none"
        stroke={props.color}
        d="M26.2,18.7c0,3-5.2,9.9-5.2,9.9s-5.2-6.9-5.2-9.9s2.3-5.3,5.2-5.3S26.2,15.8,26.2,18.7z"
      />
      <circle fill="none" stroke={props.color} cx="21" cy="18.6" r="2.4" />
    </g>
  </svg>
);
