import moment from 'moment-timezone';
import { createSelector } from '@reduxjs/toolkit';
import { REPORTS_CONSTANTS } from '../constants/reportConstants';
import { clusterContains, cropContains, parcelTypeContains } from '../utils';
import { parcelContains } from '../utils/parcels';
import { getActiveClusters, getClusters, getCrops, getParams, getParcels, getSearchFilter } from './index';

const getParcelIdFromMatch = (state, { match }) => {
  const params = match.params;
  return params && params.id ? params.id : params.parcelId ? params.parcelId : null;
};

const getParcel = createSelector([getParcels, getParcelIdFromMatch], (parcels, id) => parcels.parcelById[id]);

export const getCurrentParcel = createSelector([getParcels, getClusters, getCrops], (parcels, clusters, crops) => {
  const parcel = parcels.lastFetchedParcel;
  if (!parcel) return null;
  return {
    ...parcel,
    cluster: clusters.clusterById[parcel?.cluster_id],
    crop: crops.cropByID[parcel?.current_crop_id],
    current_tenant_cluster: clusters.clusterById[parcel?.current_tenant_cluster_id],
  };
});

const getImportedParcels = createSelector([getParcels], (parcels) => parcels.importedParcels);

const getParcelsIds = createSelector([getParcels], (parcels) => parcels.parcelsIds);

export const getParcelsStoredById = createSelector([getParcels], (parcels) => parcels.parcelById);

export const getParcelsAsArray = createSelector([getParcels], (parcels) =>
  parcels.parcelsIds.map((id) => parcels.parcelById[id]),
);

// Compute complex data
export const getVisibleParcels = createSelector(
  [getActiveClusters, getClusters, getSearchFilter, getParcelsIds, getParcelsStoredById, getCrops],
  (activeClusters, clusters, filter, parcelsIds, parcelById, crops) =>
    parcelsIds
      .map((id) => parcelById[id])
      .filter(
        (p) =>
          (!activeClusters?.length ||
            activeClusters.includes(p.cluster_id) ||
            (p.current_tenant_cluster_id && activeClusters.includes(p.current_tenant_cluster_id)) ||
            (p.owner_cluster_id && activeClusters.includes(p.owner_cluster_id))) &&
          p.cluster_id,
      )
      .map((parcel) => {
        const modifiedParcel = { ...parcel };
        modifiedParcel.cluster = clusters.clusterById[parcel.cluster_id];
        if (parcel.current_crop_id) {
          modifiedParcel.crop = crops.cropByID[parcel.current_crop_id];
        }
        modifiedParcel.current_tenant_cluster = clusters.clusterById[parcel.current_tenant_cluster_id];
        modifiedParcel.hidden =
          !parcelContains(parcel, filter) &&
          !clusterContains(clusters.clusterById[parcel.cluster_id], filter) &&
          !parcelTypeContains(parcel, filter) &&
          !cropContains(crops.cropByID[parcel.current_crop_id], filter);
        return modifiedParcel;
      }),
);

export const getAllParcels = createSelector(
  [getClusters, getParcelsIds, getParcelsStoredById],
  (clusters, parcelsIds, parcelById) =>
    parcelsIds
      .map((id) => parcelById[id])
      .filter((p) => p.cluster_id)
      .map((parcel) => ({
        ...parcel,
        cluster: clusters.clusterById[parcel.cluster_id],
        current_tenant_cluster: clusters.clusterById[parcel.current_tenant_cluster_id],
      })),
);

export const getAllParcelsWithoutInactives = createSelector([getAllParcels], (parcels) =>
  parcels.filter((parcel) => parcel.status !== 'INACTIVE'),
);

export const getAllActiveFieldParcels = createSelector([getAllParcelsWithoutInactives], (parcels) =>
  parcels.filter((parcel) => parcel.category === 'field'),
);

export const getImportedParcelsWithCluster = createSelector(
  [getClusters, getImportedParcels],
  (clusters, importedParcels) => {
    if (!importedParcels) return null;

    const updatedSuccessParcels = importedParcels.success
      ? importedParcels.success.map((successParcel) => ({
          ...successParcel,
          cluster: clusters.clusterById[successParcel.cluster_id],
          current_tenant_cluster: clusters.clusterById[successParcel.current_tenant_cluster_id],
        }))
      : [];

    const updatedErrorParcels = importedParcels.error
      ? importedParcels.error.map((errorParcel) => ({
          ...errorParcel,
          cluster: clusters.clusterById[errorParcel.cluster_id],
        }))
      : [];

    return {
      ...importedParcels,
      success: updatedSuccessParcels,
      error: updatedErrorParcels,
    };
  },
);

export const getParcelDataSelector = () =>
  createSelector([getParcel, getClusters, getCrops], (parcel, clusters, crops) => {
    if (!parcel) return null;
    return {
      ...parcel,
      cluster: clusters.clusterById[parcel?.cluster_id],
      crop: crops.cropByID[parcel?.current_crop_id],
      current_tenant_cluster: clusters.clusterById[parcel?.current_tenant_cluster_id],
    };
  });

export const getParcelHistoryData = createSelector(
  [getParams, getParcels, getClusters],
  (params, parcels, clusters) => {
    const { id } = params;
    const history = parcels.clusterParcelsByParcelId[id];
    if (!history) return [];

    return history
      .map((cp) => ({
        ...cp,
        cluster: clusters && clusters.clusterById && clusters.clusterById[cp.clusterId],
      }))
      .toSorted((a, b) => {
        if (!a.fromDate) {
          return 1;
        }
        if (!b.fromDate) {
          return -1;
        }
        return moment(a.fromDate).isBefore(moment(b.fromDate)) ? 1 : -1;
      });
  },
);

export const getParcelsCropHistory = createSelector([getParcels], (parcels) => parcels.cropHistory);

export const getAllVisibleParcelsWithoutInactives = createSelector([getVisibleParcels], (parcels) =>
  parcels.filter((parcel) => parcel.status !== 'INACTIVE'),
);

const getParcelsWorksites = createSelector([getParcels], (parcels) => parcels.parcelsWorksites);

export const getAnonymousParcelsWorksites = createSelector([getParcelsWorksites], (worksites) => {
  const groupByParcel = {};
  for (const worksite of worksites.filter(
    (w) => w.device_session_status === REPORTS_CONSTANTS.SESSIONS.device_session_status.computed,
  )) {
    const parcelId = worksite.parcel_id;
    groupByParcel[parcelId] ??= {
      parcelId: parcelId,
      parcelArea: worksite.parcel_area,
      parcelName: worksite.parcel_name,
      equipmentIds: [],
      numberOfSession: 0,
      totalArea: 0,
      totalDistance: 0,
      totalMotorDuration: 0,
      originalWorksites: [],
      earliestDate: worksite.from_date,
      latestDate: worksite.to_date,
    };

    if (!groupByParcel[parcelId].equipmentIds.includes(worksite.equipment_id)) {
      groupByParcel[parcelId].equipmentIds.push(worksite.equipment_id);
    }
    groupByParcel[parcelId].numberOfSession += worksite.number_of_equipment_session;
    groupByParcel[parcelId].totalArea += worksite.total_area;
    groupByParcel[parcelId].totalDistance += worksite.total_distance;
    groupByParcel[parcelId].totalMotorDuration += worksite.total_motor_duration;
    if (new Date(worksite.from_date) < new Date(groupByParcel[parcelId].earliestDate)) {
      groupByParcel[parcelId].earliestDate = worksite.from_date;
    }
    if (new Date(worksite.to_date) > new Date(groupByParcel[parcelId].latestDate)) {
      groupByParcel[parcelId].latestDate = worksite.to_date;
    }
    groupByParcel[parcelId].originalWorksites.push(worksite);
  }

  return Object.values(groupByParcel);
});
