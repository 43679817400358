import Icon from 'react-icon-base';

const IcTime = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M12 1.5A10.5 10.5 0 1 0 22.5 12 10.51 10.51 0 0 0 12 1.5zm4 14.33a1 1 0 0 1-1.4.17l-3.67-2.86a1 1 0 0 1-.39-.79v-4.6a1 1 0 0 1 2 0v4.12l3.28 2.56a1 1 0 0 1 .18 1.4z" />
    </g>
  </Icon>
);

export default IcTime;
