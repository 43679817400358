import { useContext } from 'react';
import { Button } from '@karnott/buttons';
import { ClustersIcon } from '@karnott/icons';
import { I18nContext } from '../../../contexts/I18nProvider';
import { UIContext } from '../../../contexts/ui';
import { MapOverlayContext } from '../contexts/overlay';

function ToggleMarkerClusterControl() {
  const { t } = useContext(I18nContext);
  const { toggleMarkerClusters } = useContext(MapOverlayContext);
  const { showMarkerClusters } = useContext(UIContext);

  return (
    <Button
      Icon={ClustersIcon}
      onClick={toggleMarkerClusters}
      message={t('Controls.marker_cluster_fields')}
      primary
      outlinedNeutral
      tooltipOrientation="left"
      noWrap
      outlined={showMarkerClusters}
      badge={showMarkerClusters}
    />
  );
}

export default ToggleMarkerClusterControl;
