import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';

// OBSERVATION - PARCEL

const receiveParcelObservations = (parcelId, observations) => ({
  type: Actions.OBSERVATIONS_PARCEL_SUCCESS,
  payload: { assetId: parcelId, observations },
});

export const fetchParcelObservations = (parcelId, startDate, endDate) => (dispatch) => {
  return Api.getParcelObservations(parcelId, startDate, endDate).then(
    (observations) => dispatch(receiveParcelObservations(parcelId, observations)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

// OBSERVATION - EQUIPMENT

const receiveEquipmentObservations = (equipmentId, observations) => ({
  type: Actions.OBSERVATIONS_EQUIPMENT_SUCCESS,
  payload: { assetId: equipmentId, observations },
});

export const fetchEquipmentObservations = (equipmentId, fromDate, toDate) => (dispatch) => {
  return Api.getEquipmentObservations(equipmentId, fromDate, toDate).then(
    (observations) => dispatch(receiveEquipmentObservations(equipmentId, observations)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

// OBSERVATION - EQUIPMENT SESSION

const receiveEquipmentSessionObservations = (equipmentSessionId, observations) => ({
  type: Actions.OBSERVATIONS_EQUIPMENT_SESSION_SUCCESS,
  payload: { assetId: equipmentSessionId, observations },
});

export const fetchEquipmentSessionObservations = (equipmentSessionId) => (dispatch) => {
  return Api.getEquipmentSessionObservations(equipmentSessionId).then(
    (observations) => dispatch(receiveEquipmentSessionObservations(equipmentSessionId, observations)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

// OBSERVATION - GENERAL

const receiveObservations = (observations) => ({
  type: Actions.OBSERVATIONS_SUCCESS,
  payload: { observations },
});

const addNewObservation = (observation, assetId) => ({
  type: Actions.OBSERVATIONS_ADD,
  payload: { observation, assetId },
});

const observationUpdated = (observation, assetId) => ({
  type: Actions.OBSERVATIONS_UPDATE,
  payload: { observation, assetId },
});

const removeObservationFromState = (observation, assetId) => ({
  type: Actions.OBSERVATIONS_DELETE,
  payload: { observation, assetId },
});

export const fetchObservations = (params) => (dispatch) => {
  return Api.getObservations(params).then(
    (observations) => dispatch(receiveObservations(observations)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

const patchFilesToObservation = (observationId, assetId, files) => (dispatch) => {
  if (!files) return Promise.reject('no file provided');
  return Api.postFilesToObservation(observationId, files).then((newFiles) => {
    dispatch(observationFilesAdded(observationId, assetId, newFiles));
    return Promise.resolve();
  });
};

const observationFilesAdded = (observationId, assetId, newFiles) => ({
  type: Actions.OBSERVATIONS_ADD_FILES,
  payload: { observation: { id: observationId }, assetId, newFiles },
});
const observationFilesDeleted = (observationId, assetId, deletedFileIds) => ({
  type: Actions.OBSERVATIONS_DELETE_FILES,
  payload: { observation: { id: observationId }, assetId, deletedFileIds },
});

export const removeFilesToObservation = (observationId, assetId, files) => (dispatch) => {
  if (!files) return Promise.reject('no file provided');
  const fileIds = files.map(({ id, binary }) => (id ? id : binary?.id ? binary.id : null));
  return Promise.all(fileIds.map((id) => Api.removeBinary(id))).then(() => {
    dispatch(observationFilesDeleted(observationId, assetId, fileIds));
    return Promise.resolve();
  });
};

export const addObservation = (params, assetId) => (dispatch) =>
  Api.addObservation(params).then(
    async (observation) => {
      if (params?.files?.length) {
        dispatch(addNewObservation({ ...observation, binaries: { pending: params.files.length } }, assetId));
        dispatch(patchFilesToObservation(observation.id, assetId, params.files));
      } else {
        dispatch(addNewObservation(observation, assetId));
      }
      return Promise.resolve();
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const updateObservation = (observationID, payload, assetId) => (dispatch) => {
  const { location_latitude, location_longitude } = payload;
  let richPayload = payload;
  if (!location_latitude && !location_longitude) {
    richPayload = { ...payload, location_latitude: null, location_longitude: null };
  }
  return Api.updateObservation(observationID, richPayload).then(
    async (updatedObs) => {
      dispatch(observationUpdated(updatedObs, assetId));
      if (payload?.deletedFiles?.length) {
        dispatch(removeFilesToObservation(observationID, assetId, payload.deletedFiles));
      }
      if (payload?.newFiles?.length) {
        dispatch(patchFilesToObservation(observationID, assetId, payload.newFiles));
      }
      return Promise.resolve();
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const deleteObservation = (observation, assetId) => (dispatch) =>
  Api.deleteObservation(observation).then(
    () => dispatch(removeObservationFromState(observation, assetId)),
    (e) => errorHasOccurred(e, dispatch),
  );

export const focusObservation = (id) => ({
  type: Actions.OBSERVATION_FOCUS,
  id,
});

const observationCategoriesSuccess = (payload) => ({
  type: Actions.OBSERVATION_CATEGORIES_SUCCESS,
  payload,
});

export const fetchObservationCategories = () => (dispatch) => {
  Api.getObservationCategories().then(
    (res) => dispatch(observationCategoriesSuccess(res)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

const observationCategoryCreateSuccess = (payload) => ({
  type: Actions.OBSERVATION_CATEGORY_CREATE_SUCCESS,
  payload,
});

export const createObservationCategory = (payload) => (dispatch) => {
  return Api.createObservationCategory(payload).then(
    (res) => {
      dispatch(observationCategoryCreateSuccess(res));
      return res;
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const observationCategoryUpdated = (payload) => ({
  type: Actions.OBSERVATION_CATEGORY_UPDATED,
  payload,
});

export const updateObservationCategory = (obsCatID, payload) => (dispatch) =>
  Api.updateObservationCategory(obsCatID, payload).then(
    (result) => dispatch(observationCategoryUpdated(result)),
    (e) => errorHasOccurred(e, dispatch),
  );

const observationCategoryDeleted = (payload) => ({
  type: Actions.OBSERVATION_CATEGORY_DELETED,
  payload,
});

export const deleteObservationCategory = (obsCatID) => (dispatch) =>
  Api.deleteObservationCategory(obsCatID).then(
    () => dispatch(observationCategoryDeleted(obsCatID)),
    (e) => errorHasOccurred(e, dispatch),
  );

const observationsRestoredSuccess = (payload) => ({
  type: Actions.OBSERVATIONS_RESTORED_SUCCESS,
  payload,
});

export const restoreObservations = (obsIDs) => (dispatch) =>
  Api.restoreObservations(obsIDs).then(
    () => dispatch(observationsRestoredSuccess(obsIDs)),
    (e) => errorHasOccurred(e, dispatch),
  );

const observationsArchivedSuccess = (payload) => ({
  type: Actions.OBSERVATIONS_ARCHIVED_SUCCESS,
  payload,
});

export const archiveObservations = (obsIDs) => (dispatch) =>
  Api.archiveObservations(obsIDs).then(
    () => dispatch(observationsArchivedSuccess(obsIDs)),
    (e) => errorHasOccurred(e, dispatch),
  );

const observationsDeletedSuccess = (payload) => ({
  type: Actions.OBSERVATIONS_DELETED_SUCCESS,
  payload,
});

export const deleteObservations = (obsIDs) => (dispatch) =>
  Api.deleteObservations(obsIDs).then(
    () => {
      dispatch(observationsDeletedSuccess(obsIDs));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const archiveObservation = (observation, assetId) => (dispatch) =>
  Api.archiveObservation(observation.id).then(
    (updatedObs) => dispatch(observationUpdated(updatedObs, assetId)),
    (e) => errorHasOccurred(e, dispatch),
  );

export const restoreObservation = (observation, assetId) => (dispatch) =>
  Api.restoreObservation(observation.id).then(
    (updatedObs) => dispatch(observationUpdated(updatedObs, assetId)),
    (e) => errorHasOccurred(e, dispatch),
  );
