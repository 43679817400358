export const SurfaceIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <polygon fill="none" stroke={props.color} points="31.1,26.1 10.9,26.1 15.9,15.9 26.1,15.9 	" />
      <line fill="none" stroke={props.color} x1="21" y1="15.9" x2="21" y2="26" />
      <line fill="none" stroke={props.color} x1="18.4" y1="15.9" x2="16.4" y2="26" />
      <line fill="none" stroke={props.color} x1="23.9" y1="15.9" x2="25.9" y2="26" />
      <line fill="none" stroke={props.color} x1="13.9" y1="19.9" x2="28.1" y2="19.9" />
    </g>
  </svg>
);
