export const ArrowBackIcon = (props) => (
  <svg height={`${props.size || 39}`} width={`${props.size || 39}`} viewBox="0 0 24 24">
    <g>
      <path
        fill="none"
        stroke={props.color}
        strokeLinejoin="round"
        strokeWidth="2px"
        strokeLinecap="round"
        d="M11.38 18.5L4.88 12l6.5-6.5"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeLinejoin="round"
        strokeWidth="2px"
        strokeLinecap="round"
        d="M5.12 12h14"
      />
    </g>
  </svg>
);
