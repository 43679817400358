import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const PhotoIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 24 22"
    >
      <g transform="translate(1 1)">
        <ellipse
          cx="5.004"
          cy="5.054"
          rx="5.004"
          ry="5.054"
          transform="translate(5.995 5.549)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1354.3,67.5l-2.013,2.222h-3.493a2.219,2.219,0,0,0-2.195,2.222V85.278a2.219,2.219,0,0,0,2.2,2.222h17.6a2.218,2.218,0,0,0,2.2-2.222V71.944a2.218,2.218,0,0,0-2.2-2.222h-3.492L1360.9,67.5Z"
          transform="translate(-1346.597 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
