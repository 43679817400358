import { Shade, colors, rgbaColors } from '@karnott/colors';

export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';
export const INFO = 'info';

const spacings = {
  xLarge: 64,
  large: 32,
  regularge: 24,
  regular: 16,
  medium: 12,
  small: 8,
  xSmall: 4,
} as const;

const sizes = {
  xLarge: 24,
  large: 17,
  regular: 14,
  small: 12,
  xSmall: 10,
} as const;

const zIndexs = {
  ground: 0,
  ui: 1000,
  map: 2000,
  menu: 3000,
  modal: 4000,
  sky: 90000,
} as const;

const durations = {
  short: 100,
  regular: 200,
  long: 300,
} as const;

const colorSeverity = {
  [SUCCESS]: 'green',
  [INFO]: 'grey',
  [WARNING]: 'orange',
  [DANGER]: 'red',
} as const;

const fontFamilies = {
  default: 'Ubuntu',
  overstatement: 'Montserrat',
} as const;

function suffixValue(value: number, suffix?: string) {
  if (!value) {
    return null;
  }
  if (suffix) {
    return `${value}${suffix}`;
  }
  return value;
}

export function zIndex(name: keyof typeof zIndexs = 'ground', offset = 0) {
  return (zIndexs[name] || 0) + offset;
}

export function fontFamily(name: keyof typeof fontFamilies = 'default') {
  return fontFamilies[name];
}

export function spacing(spacingName?: keyof typeof spacings): number;
export function spacing(spacingName: keyof typeof spacings, suffix: string): string;
export function spacing(spacingName: keyof typeof spacings = 'regular', suffix?: string) {
  const value = spacings[spacingName];
  return suffixValue(value, suffix) as string | number;
}

export function pixelSpacing(spacingName: keyof typeof spacings = 'regular') {
  const value = spacings[spacingName];
  return suffixValue(value, 'px') as string;
}

export function size(sizeName?: keyof typeof sizes): number;
export function size(sizeName: keyof typeof sizes, suffix: string): string;
export function size(sizeName: keyof typeof sizes = 'regular', suffix?: string) {
  const value = sizes[sizeName];
  return suffixValue(value, suffix) as string | number;
}

export function pixelSize(sizeName: keyof typeof sizes = 'regular') {
  const value = sizes[sizeName];
  return suffixValue(value, 'px') as string;
}

export const ELEVATION = {
  grounded: 'box-shadow: none;',
  elevated: `box-shadow: 0 3px 6px ${rgbaColors('black', 600, 0.06)}, 0 3px 6px ${rgbaColors('black', 600, 0.16)};`,
  added: `box-shadow: 0px 0px 16px 0px ${rgbaColors('green', undefined, 0.8)};`,
} as const;

export function elevation({ added, elevated }: { added?: boolean; elevated?: boolean }) {
  if (added) {
    return ELEVATION.added;
  }
  if (elevated) {
    return ELEVATION.elevated;
  }
  return ELEVATION.grounded;
}

export type Severity = keyof typeof colorSeverity;

export function getColorSeverity(severity: Severity = INFO) {
  return colorSeverity[severity];
}

export function getHexColorSeverity(severity: Severity = INFO, requestedShade: Shade) {
  return colors(getColorSeverity(severity), requestedShade);
}

export function duration(type: keyof typeof durations = 'regular') {
  return durations[type];
}

export function msDuration(type?: keyof typeof durations) {
  return suffixValue(duration(type), 'ms') as string;
}
