import { MouseEvent, ReactNode, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { DeleteIcon } from '@karnott/icons';
import { pixelSize, pixelSpacing, size, zIndex } from '@karnott/theme';

type Props = {
  /** The body of the modal */
  children: ReactNode;
  /** Whether the save button is disabled */
  continueDisabled?: boolean;
  /** The id of the modal (useful for testing) */
  id?: string;
  /** Callback called when the close button is clicked */
  onClose: () => void;
  /** Callback called when the cancel button is clicked */
  onCancel?: () => void;
  /** Whether the modal is opened */
  showModal: boolean;
  /** Title of the modal */
  title?: string;
  /** Label of the cancel button */
  cancelLabel: string;
  /** Label of the save button */
  saveLabel: string;
  /** Callback called when the save button is clicked */
  onSave: () => void;
  /** Width or the modal */
  width?: string;
  /** Whether the save button is loading */
  isOnSaveLoading?: boolean;
  /** Fix the header and the footer at the top and bottom of the screen, scrolling only the body */
  fixedHeaderAndFooter?: boolean;
  /** Whether or not the `onClose` callback should be called when clicking the backdrop of the modal */
  closeOnOuterClick?: boolean;
};

/** A modal coming from the right of the screen, to input some information */
export const ModalFromTheRight = function ({
  children,
  continueDisabled = false,
  id,
  onClose,
  onCancel = onClose,
  showModal,
  title,
  cancelLabel,
  saveLabel,
  onSave,
  width = '648px',
  isOnSaveLoading = false,
  fixedHeaderAndFooter = false,
  closeOnOuterClick = true,
}: Props) {
  const modalRef = useRef(null);
  const onModalClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (closeOnOuterClick && modalRef && e.target === modalRef.current) {
        onClose();
      }
    },
    [closeOnOuterClick, onClose],
  );
  return (
    <ModalFromTheRightContainer show={showModal}>
      <ModalFromTheRightBackground width={width} show={showModal}></ModalFromTheRightBackground>
      <ModalFromTheRightContentContainer ref={modalRef} onClick={onModalClick}>
        <ModalFromTheRightContent id={id} width={width} show={showModal} fixed={fixedHeaderAndFooter}>
          {title ? (
            <ModalFromTheRightHeader fixed={fixedHeaderAndFooter}>
              <ModalFromTheRightTitle>{title}</ModalFromTheRightTitle>
              <ModalFromTheRightClose onClick={onClose}>
                <DeleteIcon
                  circled={true}
                  backgroundColor={'transparent'}
                  size={size('xLarge')}
                  color={colors('grey')}
                />
              </ModalFromTheRightClose>
            </ModalFromTheRightHeader>
          ) : null}
          <ModalFromTheRightBody fixed={fixedHeaderAndFooter}>{children}</ModalFromTheRightBody>
          <ModalFromTheRightFooter fixed={fixedHeaderAndFooter}>
            <div>
              <Button outlined title={cancelLabel} onClick={onCancel} />
            </div>
            <div>
              <Button
                loading={isOnSaveLoading}
                title={saveLabel}
                onClick={onSave}
                success
                disabled={continueDisabled}
              />
            </div>
          </ModalFromTheRightFooter>
        </ModalFromTheRightContent>
      </ModalFromTheRightContentContainer>
    </ModalFromTheRightContainer>
  );
};

const ModalFromTheRightContainer = styled.div<{
  top?: string;
  show: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: end;
  position: fixed;
  top: ${(props) => props.top || '0'};
  right: 0;
  height: 100%;
  width: ${(props) => (props.show ? '100vw' : '0')};
  transition: background-color 0.2s;
  background-color: ${(props) => (props.show ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0)')};
  z-index: ${zIndex('modal')};
  overflow: hidden;
`;

const ModalFromTheRightBackground = styled.div<{
  show: boolean;
  width?: string;
}>`
  position: absolute;
  right: ${({ show, width }) => (show ? '0' : width ? '-' + width : '-648px')};
  top: 0;
  bottom: 0;
  height: 100%;
  width: ${({ width }) => (width ? width : '648px')};
  transition: right 0.5s;
  background-color: ${colors('grey', 100)};
`;
// we need a content container because of select with position absolute list
//   ==> if the position absolute content is outside the box, it will not be displayed
const ModalFromTheRightContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: inherit;
  flex-direction: column;
  align-items: end;
`;

const ModalFromTheRightContent = styled.div<{
  show: boolean;
  width?: string;
  fixed: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '648px')};
  ${({ fixed }) =>
    fixed
      ? css`
          height: 100%;
        `
      : css`
          gap: ${pixelSpacing('regular')} 0;
          height: inherit;
          overflow-x: hidden;
        `}
  right: ${({ show, width }) => (show ? '0' : width ? '-' + width : '-648px')};
  transition: right 0.5s;
`;

const ModalFromTheRightTitle = styled.h3`
  padding: 0;
  margin: 0;
  color: ${colors('black')};
  font-size: 19px;
`;

const ModalFromTheRightHeader = styled.div<{
  fixed: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pixelSpacing()};
  border-bottom: 1px solid ${colors('grey', 200)};
  background-color: ${colors('grey', 100)};
  ${({ fixed }) =>
    fixed
      ? css`
          position: sticky;
          top: 0;
          z-index: 1;
        `
      : css`
          margin-top: ${pixelSpacing('small')};
        `}
`;

const ModalFromTheRightClose = styled.div`
  display: flex;
  font-size: ${pixelSize('xLarge')};
  color: ${colors('grey', 200)};
  cursor: pointer;
`;

const ModalFromTheRightFooter = styled.div<{
  fixed: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pixelSpacing('regular')};
  border-top: 1px solid ${colors('grey', 200)};
  background-color: ${colors('grey', 100)};
  ${({ fixed }) =>
    fixed
      ? css`
          position: sticky;
          bottom: 0;
        `
      : null}
`;

const ModalFromTheRightBody = styled.div<{
  fixed: boolean;
}>`
  position: relative;
  display: flex;
  padding: ${pixelSpacing()};
  flex-direction: column;
  ${({ fixed }) =>
    fixed
      ? css`
          height: 100%;
          overflow-x: hidden;
        `
      : null}
`;
