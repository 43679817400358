export const LogoutIcon = ({ color, size }) => (
  <svg viewBox="0 0 24 24" width={`${size || 24}`} height={`${size || 24}`}>
    <g>
      <g>
        <g>
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            d="M17.15 9.15L20 12l-2.85 2.85"
          />
        </g>
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          d="M20 12h-9.85"
        />
        <path fill={color} fillRule="evenodd" d="M14.31 16.7V20H6V4h8.31v3.3" />
      </g>
    </g>
  </svg>
);
