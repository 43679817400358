import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const BroyIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="22.4"
            cy="3.9"
            r="2.8"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M33.1,19.9c-0.5-0.7-1.4-1.2-2.3-1.2c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8c0.1,0,0.3,0,0.4,0"
          />
        </g>

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="29.4"
          y1="19"
          x2="24.3"
          y2="6.1"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		20.7,6.1 18.6,15.1 11.3,19 	"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M14.9,25v-2.4c0-2-1.6-3.6-3.6-3.6l0,0c-2,0-3.6,1.6-3.6,3.6V25"
        />

        <polygon
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1,30.6 21.2,30.6 19.8,25.2 2.4,25.2 	"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		21.2,30.6 21.2,35 1,35 1,30.6 	"
        />

        <polygon
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		43,35 26.8,35 34.6,16.2 43,16.2 	"
        />
      </g>
    </svg>
  </CircledIcon>
);
