import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const DeleteIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19.053 19.053">
      <g transform="translate(1.026 1.026)">
        {!circled && (
          <path
            d="M709.483,10.677a8.5,8.5,0,1,1,5.194,10.84A8.509,8.509,0,0,1,709.483,10.677Z"
            transform="translate(-709 -5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        )}
        <line
          x2="5.617"
          y2="5.617"
          transform="translate(5.692 5.692)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="5.617"
          y2="5.617"
          transform="translate(5.691 5.692)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
