import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ChenillardIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="15.2"
          y1="20.8"
          x2="15.2"
          y2="12"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="11.4"
          y1="11.9"
          x2="19"
          y2="11.9"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="7"
          y1="25.8"
          x2="35.5"
          y2="25.8"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="35.5"
          y1="36.2"
          x2="7"
          y2="36.2"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M30,30H8.4c-1.3,0-2.3-0.9-2.3-1.9v-0.4c0-1.1,1-1.9,2.3-1.9h24.5"
        />

        <circle
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="35.2"
          cy="31"
          r="5.2"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M26.6,30.2c0,0.3,0.1,0.5,0.1,0.8c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2c0-0.3,0-0.6,0.1-0.9"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M12.1,30.2c0,0.3,0.1,0.5,0.1,0.8c0,2.9-2.3,5.2-5.2,5.2S1.8,33.9,1.8,31s2.3-5.2,5.2-5.2"
        />
        <g>
          <g>
            <g>
              <line
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="23.3"
                y1="14.5"
                x2="13.2"
                y2="25.7"
              />
            </g>
          </g>
        </g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M23.2,14.5h14.3c3,0,5.5,2.5,5.5,5.5v6.4c0,1.4-1.2,2.6-2.6,2.6"
        />
        <g>
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M13.6,25.8H1l0-3.5V7.8h4.7c0,0,1,5.3,1.1,7.1S5.7,21,5.7,21l11.5,0"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
