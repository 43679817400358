import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const SoilIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="1"
            y1="28.9"
            x2="1"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="6.3"
            y1="30.7"
            x2="6.3"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="16.8"
            y1="30.7"
            x2="16.8"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="37.8"
            y1="30.7"
            x2="37.8"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="43"
            y1="29"
            x2="43"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="27.3"
            y1="30.7"
            x2="27.3"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="32.5"
            y1="30.7"
            x2="32.5"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="22"
            y1="30.7"
            x2="22"
            y2="33.5"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="11.5"
            y1="30.7"
            x2="11.5"
            y2="33.5"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M27.7,22.4h12.3l3.1,5.2v2.2c0,0.3-0.2,0.5-0.5,0.5H1.5c-0.3,0-0.5-0.2-0.5-0.5v-2.2l3.1-5.2h12.1"
          />
        </g>
        <g>
          <polygon
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			29.5,25.5 14.5,25.5 19.6,17 24.4,17 		"
          />

          <polygon
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			24.4,17 19.6,17 21,10.5 23,10.5 		"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
