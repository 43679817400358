import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const GrapeVarietyIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 22 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M1349.4,17.6a3.07,3.07,0,0,1,3.3.5,3.021,3.021,0,1,0-4-1.5A1.691,1.691,0,0,0,1349.4,17.6Z"
          transform="translate(-1347.5 -3.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1353.4,21.7a3.07,3.07,0,0,0-.5-3.3,3.087,3.087,0,1,1,1.5,4.1A8.736,8.736,0,0,1,1353.4,21.7Z"
          transform="translate(-1347.5 -3.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1350.9,12.3h.5a3.371,3.371,0,0,1,1.7.5,3.041,3.041,0,1,0-3.5-2.5A2.969,2.969,0,0,0,1350.9,12.3Z"
          transform="translate(-1347.5 -3.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1355.2,16.6a2.813,2.813,0,0,1,2.2.5,3.041,3.041,0,0,0-1-6,2.97,2.97,0,0,0-2.5,2.5,3.372,3.372,0,0,1,.5,1.7v.5A3.044,3.044,0,0,0,1355.2,16.6Z"
          transform="translate(-1347.5 -3.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1359.1,16.3a3.505,3.505,0,0,0-.9,1.7,3.371,3.371,0,0,1,.5,1.7v.5a3.023,3.023,0,1,0,5-3.4A3.113,3.113,0,0,0,1359.1,16.3Z"
          transform="translate(-1347.5 -3.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1364.085,16.793a5.559,5.559,0,0,0,2.815-.893c-.1-1.3-2.2-3.3-2.2-3.3a7.022,7.022,0,0,0,2.8-3.1c-1.1-1.2-3.8-.9-3.8-.9a6.462,6.462,0,0,0,.3-5.1,7.06,7.06,0,0,0-5.5,2.9,3.921,3.921,0,0,0-1.7-2.3,6.835,6.835,0,0,0-1.8,3.342"
          transform="translate(-1347.5 -3.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="3.1"
          cy="3.1"
          r="3.1"
          transform="translate(0 13.8)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
