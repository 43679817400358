import { useEffect, useState } from 'react';
import { RemoteConfig } from '../../utils/RemoteConfig';

export function useRemoteConfigValue(name, type) {
  const [value, setValue] = useState(RemoteConfig.CONFIG_DEFAULTS[name]);
  useEffect(() => {
    function listener() {
      RemoteConfig.getInstance()
        .configValueIsEnabled(name)
        .then((v) => {
          switch (type) {
            case 'boolean':
              setValue(v.asBoolean());
              break;
            case 'string':
              setValue(v.asString());
              break;
            case 'number':
              setValue(v.asNumber());
              break;
            default:
              setValue(v.asString());
              break;
          }
        });
    }
    RemoteConfig.getInstance().addFetchListener(listener);
    return () => {
      RemoteConfig.getInstance().removeFetchListener(listener);
    };
  }, [type, name]);

  return value;
}
