import { useCallback } from 'react';
import { colors } from '@karnott/colors';
import { getUnitIcon } from '../../../../utils/equipments';
import { useDrawMarkersOnMap } from '../../effects';

export function useDrawUnitLocationsOnMap(map, unitLocations, events) {
  const dataExtractor = useCallback(
    (o) => ({
      location: { latitude: o.location_latitude, longitude: o.location_longitude },
      Icon: getUnitIcon(o.equipment_model),
      iconColor: colors('white'),
      backgroundColor: colors('orange'),
      iconSize: 35,
      iconAnchor: [20, 43],
    }),
    [],
  );
  useDrawMarkersOnMap(map, unitLocations, dataExtractor, events);
}
