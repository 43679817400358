export const CheckIcon = ({ color, size }) => (
  <svg viewBox="0 0 24 24" fill={color} width={`${size || 24}`} height={`${size || 24}`}>
    <g>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M4.5 12.43l4.72 4.72L19.5 6.86"
      />
    </g>
  </svg>
);
