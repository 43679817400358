import { Actions } from '../../actions';

const initialState = {
  list: [],
  categories: [],
};

const general = (state = initialState, action) => {
  const { type, payload } = action;
  const { observation, observations } = payload || {};

  switch (type) {
    case Actions.OBSERVATIONS_SUCCESS:
      return {
        ...state,
        list: (observations || []).reverse(),
      };
    case Actions.OBSERVATIONS_ADD:
      return {
        ...state,
        list: [observation].concat(state.list),
      };
    case Actions.OBSERVATIONS_DELETE:
      return {
        ...state,
        list: state.list.filter((o) => o.id !== observation.id),
      };
    case Actions.PARCEL_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter((o) => o.parcel_id && o.parcel_id !== payload),
      };
    case Actions.EQUIPMENT_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter((o) => o.equipment_instance_id && o.equipment_instance_id !== payload),
      };
    case Actions.OBSERVATION_FOCUS:
      return {
        ...state,
        focusedObservationId: action.id,
      };
    case Actions.OBSERVATION_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
      };
    case Actions.OBSERVATION_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, payload],
      };
    case Actions.OBSERVATION_CATEGORY_UPDATED: {
      const categoriesWithUpdated = [...state.categories];
      categoriesWithUpdated.forEach((c, i) => {
        if (c.id === payload.id) {
          categoriesWithUpdated[i] = payload;
        }
      });
      return {
        ...state,
        categories: categoriesWithUpdated,
      };
    }
    case Actions.OBSERVATION_CATEGORY_DELETED: {
      const categoriesWithDeleted = [...state.categories].filter((c) => c.id !== payload);
      return {
        ...state,
        categories: categoriesWithDeleted,
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default general;
