export const CLUSTER_CONSTANTS = {
  PLUGINS_NAMES: {
    share: 'share_equipment_session',
    speed: 'show_speed',
    count_bale: 'count_bale',
    crop_management: 'crop_management',
    observation: 'observation',
    user_role_management: 'user_role_management',
    ign_tiles: 'ign_tiles',
    worksite_report: 'worksite_report',
    printing_maps: 'printing_maps',
    real_time: 'real_time',
    smag_farmer: 'smag_farmer',
    rent: 'rent_field',
    phyto: 'phyto',
  },
  ACTIVITIES: {
    culture: 'culture',
    viticulture: 'viticulture',
    maraichage: 'maraichage',
    elevage: 'elevage',
    ostreiculture: 'ostreiculture',
    arboriculture: 'arboriculture',
    deneigement: 'deneigement',
    sylviculture: 'sylviculture',
  },
};

export const CLUSTER_TYPE = {
  ETA: 'ETA',
  CUMA: 'CUMA',
  FARM: 'FARM',
  CONCESSIONNAIRE: 'CONCESSIONNAIRE',
};
