import { useContext, useMemo } from 'react';
import { MapContext } from '../../contexts/map';
import { useStyleBuilders } from '../../effects';
import { useDrawTracksOnMap, useOverTracks, useTracksDefaultStyle } from '../Tracks/effects';
import { useDrawDriverOnMap, useDriversSession } from './effects';

const Driver = function ({ driver, events, hideDriverTooltip }) {
  const { map } = useContext(MapContext);
  useDrawDriverOnMap(map, driver, events, hideDriverTooltip);
  return null;
};

const Drivers = function ({
  drivers,
  events,
  hideActiveSession,
  hideDriverTooltip,
  trackEvents,
  overTrackID,
  overStyle,
  tracksWithoutFlags,
}) {
  const { map } = useContext(MapContext);
  const activeSessions = useDriversSession(drivers, hideActiveSession);
  const defaultTrackStyle = useTracksDefaultStyle();
  const styles = useMemo(() => [defaultTrackStyle], [defaultTrackStyle]);
  const trackStyles = useStyleBuilders(styles);
  const [tracksMap, decoratorsMap] = useDrawTracksOnMap(
    map,
    activeSessions,
    trackEvents,
    trackStyles,
    tracksWithoutFlags,
    false,
  );
  useOverTracks({ overTrackID, tracksMap, decoratorsMap, trackStyles, overStyle });
  return (
    <>
      {(drivers || []).map((e) => (
        <Driver key={`map-driver-${e.id}`} driver={e} events={events} hideDriverTooltip={hideDriverTooltip} />
      ))}
    </>
  );
};

export default Drivers;
