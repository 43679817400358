const defaultPalette = {
  orange: {
    600: '#F07D2B',
    500: '#F3942C',
    400: '#FAC58A',
    300: '#FFDBB4',
  },
  green: {
    600: '#67AA25',
    500: '#76C22C',
    400: '#BBD89F',
    300: '#E2F2D3',
    200: '#F1F7EC',
  },
  yellow: {
    600: '#AFA21B',
    500: '#DBCB22',
    400: '#E2d54E',
  },
  bleen: {
    600: '#1A625E',
    500: '#238B85',
    400: '#69BFBA',
  },
  cyan: {
    600: '#138F91',
    500: '#1DD7D9',
    400: '#8FEFF0',
  },
  sepia: {
    600: '#75675C',
    500: '#8A755D',
    400: '#A5916E',
  },
  red: {
    600: '#D11F1F',
    500: '#F22727',
    400: '#FA9090',
    300: '#FCDDDD',
  },
  black: {
    600: '#0C0C0C',
    500: '#3C3C3C',
    400: '#5C5C5C',
  },
  grey: {
    600: '#656565',
    500: '#8F8F8F',
    400: '#B1B1B1',
    300: '#CBCBCB',
    200: '#E0E0E0',
    100: '#F4F4F4',
  },
  white: {
    600: '#E0E0E1',
    500: '#FFFFFF',
    400: '#E0E0DF',
  },
  purple: {
    500: '#7036A6',
    400: '#B58BF2',
  },
} as const;

export type ColorName = keyof typeof defaultPalette;

const shades = {
  darker: 700,
  dark: 600,
  default: 500,
  light: 400,
  lighter: 300,
} as const;

type ShadeName = keyof typeof shades;
type ShadeValue = 100 | 200 | (typeof shades)[ShadeName];
export type Shade = ShadeName | ShadeValue;

function mapShadeNameToValue(requestedShade?: ShadeName) {
  if (!requestedShade) return 500;
  return ({
    darker: 700,
    dark: 600,
    default: 500,
    light: 400,
    lighter: 300,
  }[requestedShade] || 500) as ShadeValue;
}

export function colors(color: ColorName, requestedShade: Shade = 500) {
  let shade: ShadeValue | null = null;

  if (typeof requestedShade === 'string') shade = mapShadeNameToValue(requestedShade);
  if (typeof requestedShade === 'number') shade = requestedShade;

  if (
    !color ||
    typeof color !== 'string' ||
    (shade !== null && (typeof shade !== 'number' || shade % 100 !== 0 || !shade))
  ) {
    return colors._palette['black'][500];
  }
  const palette = colors._palette[color] as Record<ShadeValue, string>;
  if (palette) {
    return palette[shade || 500];
  } else {
    console.error(`unknown color combination: colors(${color}, ${requestedShade})`);
    return colors._palette['black'][500];
  }
}

function hexToRgbA(hex: string, alpha: number) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let value = hex.substring(1).split('');
    if (value.length === 3) {
      // we know that these values exist
      value = [value[0]!, value[0]!, value[1]!, value[1]!, value[2]!, value[2]!];
    }
    const color = Number('0x' + value.join(''));
    if (typeof alpha !== 'number' || alpha < 0 || alpha > 1) {
      console.warn('bad alpha for hex to rgba');
      return null;
    }
    return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',')},${alpha})`;
  }
  console.warn('bad hex for hex to rgba');
  return null;
}

export function rgbaColors(color: ColorName, requestedShade: Shade = 500, alpha: number) {
  const hex = colors(color, requestedShade);
  if (hex) {
    return hexToRgbA(hex, alpha);
  } else {
    console.warn(`unknown color "${color}" with shade "${requestedShade}"`);
    return null;
  }
}

//https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
function shadeBlend(percent: number, color1: string, color2?: string) {
  const n = percent < 0 ? percent * -1 : percent,
    u = Math.round,
    w = parseInt;
  if (color1.length > 7) {
    const f = color1.split(','),
      t = (color2 ? color2 : percent < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(','),
      R = w(f[0]!.slice(4)),
      G = w(f[1]!),
      B = w(f[2]!);
    return (
      'rgb(' +
      (u((w(t[0]!.slice(4)) - R) * n) + R) +
      ',' +
      (u((w(t[1]!) - G) * n) + G) +
      ',' +
      (u((w(t[2]!) - B) * n) + B) +
      ')'
    );
  } else {
    const f = w(color1.slice(1), 16),
      t = w((color2 ? color2 : percent < 0 ? '#000000' : '#FFFFFF').slice(1), 16),
      R1 = f >> 16,
      G1 = (f >> 8) & 0x00ff,
      B1 = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (u(((t >> 16) - R1) * n) + R1) * 0x10000 +
        (u((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
        (u(((t & 0x0000ff) - B1) * n) + B1)
      )
        .toString(16)
        .slice(1)
    );
  }
}

export function shadeHex(hex: string, percent: number) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex) && -1 < percent && 1 > percent) {
    return shadeBlend(percent, hex);
  }
  console.warn('bad hex for hex to rgba');
  return null;
}

colors._palette = defaultPalette;
