export const SirenIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <line fill="none" stroke={props.color} x1="21" y1="22.9" x2="21" y2="24.6" />
      <rect x="16.3" y="19.6" fill="none" stroke={props.color} width="9.4" height="7.8" />
      <path
        fill="none"
        stroke={props.color}
        d="M17.9,17.7c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1v1.9h-6.1V17.7z"
      />
    </g>
  </svg>
);
