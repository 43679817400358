import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CLUSTER_CONSTANTS } from '../../constants/clusterConstants';
import { getClustersWithRole } from '../../selectors/clusters';
import { isPluginActivated } from '../../utils/clusters';
import { Context } from './context';

export const Provider = function ({ children }) {
  const clustersWithRole = useSelector(getClustersWithRole);

  const hasOverallRentPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.rent)),
    [clustersWithRole],
  );
  const clusterHasRentPlugin = useCallback((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.rent), []);

  const hasOverallCropManagementPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.crop_management)),
    [clustersWithRole],
  );

  const clusterHasCropManagementPlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.crop_management),
    [],
  );

  const hasOverallObservationPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.observation)),
    [clustersWithRole],
  );

  const clusterHasObservationPlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.observation),
    [],
  );

  const clusterHasCountUnitPlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.count_bale),
    [],
  );

  const hasOverallUserRoleManagementPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.user_role_management)),
    [clustersWithRole],
  );

  const clusterHasUserRoleManagementPlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.user_role_management),
    [],
  );

  const clusterHasShareSessionPlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.share),
    [],
  );

  const clusterHasSpeedPlugin = useCallback((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.speed), []);

  const hasOverallIgnTilesPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.ign_tiles)),
    [clustersWithRole],
  );

  const hasOverallWorksiteReportPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.worksite_report)),
    [clustersWithRole],
  );

  const clusterHasWorksiteReportPlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.worksite_report),
    [],
  );

  const hasOverallPlansPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.printing_maps)),
    [clustersWithRole],
  );

  const hasOverallRealTimePlugin = useMemo(() => {
    if (!clustersWithRole?.length) {
      return undefined;
    } else {
      return clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.real_time));
    }
  }, [clustersWithRole]);

  const clusterHasRealTimePlugin = useCallback(
    (c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.real_time),
    [],
  );

  const hasOverallPhytoPlugin = useMemo(() => {
    if (!clustersWithRole?.length) {
      return undefined;
    } else {
      return clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.phyto));
    }
  }, [clustersWithRole]);

  const clusterHasPhytoPlugin = useCallback((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.phyto), []);

  const hasOverallSmagPlugin = useMemo(
    () => clustersWithRole.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.smag_farmer)),
    [clustersWithRole],
  );
  const isContextLoaded = useMemo(() => clustersWithRole && clustersWithRole.length > 0, [clustersWithRole]);

  return (
    <Context.Provider
      value={{
        clusterHasCountUnitPlugin,
        clusterHasCropManagementPlugin,
        clusterHasObservationPlugin,
        clusterHasShareSessionPlugin,
        clusterHasSpeedPlugin,
        clusterHasUserRoleManagementPlugin,
        clusterHasWorksiteReportPlugin,
        clusterHasRealTimePlugin,
        clusterHasRentPlugin,
        clusterHasPhytoPlugin,
        hasOverallCropManagementPlugin,
        hasOverallObservationPlugin,
        hasOverallUserRoleManagementPlugin,
        hasOverallIgnTilesPlugin,
        hasOverallWorksiteReportPlugin,
        hasOverallPlansPlugin,
        hasOverallRealTimePlugin,
        hasOverallSmagPlugin,
        hasOverallRentPlugin,
        hasOverallPhytoPlugin,
        isContextLoaded,
      }}
    >
      {children}
    </Context.Provider>
  );
};
