import { useContext, useEffect } from 'react';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { convertDDToDMS } from '../../../../utils';
import { L as Leaflet } from '../../../../utils/LeafletOverrides';

export function useDrawLocationsOnMap(map, locations) {
  const { t } = useContext(I18nContext);
  useEffect(() => {
    const markers = [];
    locations.forEach((location) => {
      const marker = Leaflet.marker(Leaflet.latLng(location.location_latitude, location.location_longitude), {
        icon: Leaflet.divIcon({
          html: `<div class="location-tooltip">
                    <div class="location-tooltip-title">
                      ${t('Observations.chosen_place')}
                    </div>
                    <div class="location-tooltip-value">
                       ${convertDDToDMS(location?.location_latitude, false)}&nbsp;
                       ${convertDDToDMS(location?.location_longitude, true)}
                    </div>
                 </div>`,
          className: '',
          draggable: true,
          iconAnchor: [90, 56],
        }),
      });
      markers.push(marker);
    });
    markers.forEach((m) => m.addTo(map));
    return () => {
      markers.forEach((m) => m.removeFrom(map));
    };
  }, [map, locations, t]);
}

export function useLocationCoords(locations = []) {
  return locations.map((loc) => [[loc.location_latitude, loc.location_longitude]]);
}
