import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const PieceIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 22 16.5"
    >
      <g transform="translate(1 1)">
        <ellipse
          cx="10"
          cy="3.666"
          rx="10"
          ry="3.666"
          transform="translate(0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M344.329,145.084c0,2.024-4.476,3.666-10,3.666s-10-1.642-10-3.666"
          transform="translate(-324.329 -134.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y2="7.093"
          transform="translate(0 3.666)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y2="7.093"
          transform="translate(20 3.666)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
