import { ComponentProps, Fragment, MouseEvent, ReactNode, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { MenuIcon } from '@karnott/icons';
import { pixelSpacing } from '@karnott/theme';
import { Button } from './button';

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('small')};
`;

const Group = styled.div<{
  length: number;
}>`
  display: flex;
  flex-direction: column;
  ${({ length }) =>
    length > 1 &&
    css`
      & button:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      & button:not(:last-child):not(button.badge) {
        border-bottom: none;
      }
      & button:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      & button.badge + button {
        border-top: none;
      }
    `}
`;

type Props = {
  /** The buttons to display */
  buttons: ReactNode[];
  /** Additional buttons to display when the "more" button is toggled */
  moreButtons?: ReactNode[];
  /** Props to pass to the "more" button */
  moreButtonProps?: ComponentProps<typeof Button>;
  /** Callback to run when the "more" button is clicked, opening the buttons */
  onOpen?: () => void;
  /** Callback to run when the "more" button is clicked, closing the buttons */
  onClose?: () => void;
  /** Whether the "more" buttons are initially open */
  initiallyOpen?: boolean;
};

/** A connected group of buttons, usually displayed over a map */
export function ButtonGroup({ buttons, moreButtons, moreButtonProps, onOpen, onClose, initiallyOpen }: Props) {
  const [moreOpened, setMoreOpened] = useState(initiallyOpen || false);

  const onMoreButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      moreButtonProps?.onClick?.(e);
      moreOpened ? onClose?.() : onOpen?.();
      setMoreOpened(!moreOpened);
    },
    [moreButtonProps, moreOpened, onClose, onOpen],
  );

  return (
    <GroupWrapper data-testid="button-group">
      {moreButtons?.length && moreOpened ? (
        <Group length={moreButtons.length}>
          {moreButtons.map((button, index) => (
            <Fragment key={index}>{button}</Fragment>
          ))}
        </Group>
      ) : null}
      <Group length={(buttons?.length ?? 0) + (moreButtons?.length ? 1 : 0)}>
        {moreButtons?.length ? (
          <Button
            large
            primary
            outlinedNeutral={!moreOpened}
            Icon={MenuIcon}
            {...moreButtonProps}
            onClick={onMoreButtonClick}
          />
        ) : null}
        {buttons?.map((button, index) => <Fragment key={index}>{button}</Fragment>)}
      </Group>
    </GroupWrapper>
  );
}
