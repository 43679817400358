import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const CloseIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '43%' : '60%'}
      height={circled ? '43%' : '60%'}
      viewBox="0 0 9.432 9.432"
    >
      <g transform="translate(0.707 0.707)" key="svg-circled">
        <line
          x2="8.018"
          y2="8.018"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          key="line1-circled"
        />
        <line
          x1="8.018"
          y2="8.018"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          key="line2-circled"
        />
      </g>
    </svg>
  </CircledIcon>
);
