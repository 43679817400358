import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const TraitementIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M15,35.4h1.9c0.5,0,0.9-0.4,0.9-0.9v-2.9c0-0.5-0.4-0.9-0.9-0.9H15c-0.5,0-0.9,0.4-0.9,0.9v2.9C14.1,35,14.5,35.4,15,35.4z"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M27.1,35.4H29c0.5,0,0.9-0.4,0.9-0.9v-2.9c0-0.5-0.4-0.9-0.9-0.9h-1.9c-0.5,0-0.9,0.4-0.9,0.9v2.9C26.2,35,26.6,35.4,27.1,35.4z"
          />
        </g>

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="22"
          y1="13.3"
          x2="22"
          y2="8.6"
        />
        <g>
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="34.2"
            y1="35.1"
            x2="34.2"
            y2="8.6"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="9.8"
            y1="35.1"
            x2="9.8"
            y2="8.6"
          />
        </g>

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		43,35.1 43,8.6 1,8.6 1,35.1 	"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		29.3,20.6 29.3,30.7 14.7,30.7 14.7,20.6 	"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M14.7,21.5v-2.4c0-3.1,2.5-5.7,5.7-5.7h3.3c3.1,0,5.7,2.5,5.7,5.7v2.4"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="15"
          y1="20.9"
          x2="29"
          y2="20.9"
        />
      </g>
    </svg>
  </CircledIcon>
);
