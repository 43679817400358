import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const FarmhouseIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 27 17"
    >
      <g transform="translate(1 1)">
        <path
          d="M665,137l1.829,4.2H656.463"
          transform="translate(-641.83 -134)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="10.366"
          transform="translate(14.634 15)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M649.146,134h10.366L665,137H654.634"
          transform="translate(-641.83 -134)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M656.464,141.2l-1.829-4.2-5.488-3-5.488,3-1.829,4.2V149h14.634Z"
          transform="translate(-641.83 -134)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          width="5.585"
          height="6.6"
          transform="translate(3.951 8.4)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y1="7.8"
          transform="translate(25 7.2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
