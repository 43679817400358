import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const HayMakingIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <g>
            <g>
              <g>
                <ellipse
                  fill="none"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  cx="32"
                  cy="27.2"
                  rx="3.1"
                  ry="1.7"
                />
                <g>
                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M22.9,25.6c2.4-0.7,5.1-0.5,7.1,0.3"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M23.5,29.7c0.8-1.4,3-2.4,5.4-2.6"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M29.1,32.3c-1.2-1.3-0.9-2.9,0.5-4"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M36.4,31.9c-2.4-0.4-4.1-1.5-4.6-2.9"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M41.1,28.8c-2.4,0.7-5.1,0.5-7.1-0.3"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M40.5,24.7c-0.8,1.4-3,2.4-5.4,2.6"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M34.8,22.1c1.2,1.3,0.9,2.9-0.5,4"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M27.5,22.5c2.5,0.4,4.3,1.6,4.7,3"
                  />
                </g>
              </g>
            </g>

            <polyline
              fill="none"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
				38.5,11.7 32,16.5 32,25.4 			"
            />
          </g>
          <g>
            <g>
              <g>
                <ellipse
                  fill="none"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  cx="10.1"
                  cy="27.2"
                  rx="3.1"
                  ry="1.7"
                />
                <g>
                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M1,25.6c2.4-0.7,5.1-0.5,7.1,0.3"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M1.7,29.7c0.8-1.4,3-2.4,5.4-2.6"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M7.3,32.3c-1.2-1.3-0.9-2.9,0.5-4"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M14.6,31.9c-2.5-0.4-4.2-1.6-4.6-2.9"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M19.3,28.8c-2.4,0.7-5.1,0.5-7.1-0.3"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M18.6,24.7c-0.8,1.4-3,2.4-5.4,2.6"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M13,22.1c1.2,1.3,0.9,2.9-0.5,4"
                  />

                  <path
                    fill="none"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
							M5.7,22.5c2.5,0.4,4.3,1.6,4.7,3"
                  />
                </g>
              </g>
            </g>

            <polyline
              fill="none"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
				16.7,11.7 10.1,16.5 10.1,25.4 			"
            />
          </g>
        </g>

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="43"
          y1="11.7"
          x2="6.1"
          y2="11.7"
        />
      </g>
    </svg>
  </CircledIcon>
);
