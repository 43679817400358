import { colors } from '@karnott/colors';
import { PARCELS_CONSTANTS } from '../../../../constants/parcelsConstants';
import { color } from '../../../../ui/theme';

export function buildParcelStyle(parcelProperties) {
  if (!parcelProperties) return {};
  if (parcelProperties.error) {
    return {
      color: color('white'),
      fillColor: colors('red'),
      fillOpacity: 0.4,
      weight: 2,
    };
  }
  if (!parcelProperties.cluster_id) {
    return {
      color: color('white'),
      fillColor: colors('orange'),
      fillOpacity: 0.4,
      weight: 2,
    };
  }
  if (
    (parcelProperties.status === PARCELS_CONSTANTS.STATUS.BEING_RENT && !parcelProperties.current_tenant_cluster) ||
    parcelProperties.status === PARCELS_CONSTANTS.STATUS.RENT_OVER
  ) {
    return {
      color: color('white'),
      fillColor: color('smoke'),
      fillOpacity: 0.7,
      weight: 2,
    };
  }
  if (parcelProperties.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
    return {
      color: color('white'),
      fillColor: color('sepia', 'light'),
      fillOpacity: 0.7,
      weight: 2,
    };
  }
  if (parcelProperties.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
    return {
      color: color('white'),
      fillColor: color('yellow'),
      fillOpacity: 0.4,
      weight: 2,
    };
  }
  return {
    color: color('white'),
    fillColor: color('cyan'),
    fillOpacity: 0.4,
    weight: 2,
  };
}
