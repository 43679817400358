import { ChangeEvent, ComponentProps, FocusEvent, MouseEvent, forwardRef } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { KIcon } from '@karnott/icons';
import { msDuration, pixelSize, pixelSpacing } from '@karnott/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:hover > input::placeholder,
  &:focus-within > input::placeholder {
    color: ${colors('black', 600)};
  }
  &:hover > div,
  &:focus-within > div,
  &:hover > input,
  &:focus-within > input,
  &:hover button,
  &:focus-within button {
    border-color: ${colors('orange')};
  }
  &:hover > div,
  &:focus-within > div {
    background-color: ${colors('orange')};
  }
  &:hover > div svg *,
  &:focus-within > div svg * {
    stroke: ${colors('white')};
  }
  button:hover {
    background-color: ${colors('orange')};
  }
`;

const LeftIconContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1.5px solid #e0e0e0;
  transition: all ${msDuration('short')} linear;
  outline: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  vertical-align: middle;
  gap: 8px;
`;

const Input = styled.input<{
  hasLeftIcon: boolean;
  hasButton: boolean;
}>`
  all: unset;
  transition: all ${msDuration('short')} linear;
  width: 100%;
  height: 40px;
  font-size: ${pixelSize('large')};
  border: 1.5px solid ${colors('grey', 200)};
  border-left: ${({ hasLeftIcon }) => hasLeftIcon && 'none'};
  border-right: ${({ hasButton }) => hasButton && 'none'};
  border-top-left-radius: ${({ hasLeftIcon }) => (hasLeftIcon ? 0 : '5px')};
  border-bottom-left-radius: ${({ hasLeftIcon }) => (hasLeftIcon ? 0 : '5px')};
  border-top-right-radius: ${({ hasButton }) => (hasButton ? 0 : '5px')};
  border-bottom-right-radius: ${({ hasButton }) => (hasButton ? 0 : '5px')};
  padding-left: ${pixelSpacing('medium')};
  background-color: ${colors('white')};
  ::placeholder {
    color: ${colors('grey')};
    transition: color ${msDuration('short')} ease-in-out;
  }
`;

type Props = {
  /** Displays an icon on the left */
  LeftIcon?: KIcon;
  /** Displays a button on the right */
  ButtonIcon?: KIcon;
  /** Props to pass to the button */
  buttonProps?: ComponentProps<typeof Button>;
  /** Placeholder of the input */
  placeholder?: string;
  /** Value of the input */
  value: string;
  /** Callback when the value of the input changes */
  onValueChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /** Callback when the button is clicked */
  onButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
  /** Callback when the input is focused */
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  /** Callback when the input is blurred */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

export const ButtonedInput = forwardRef<HTMLInputElement, Props>(function ButtonedInputComponent(
  { LeftIcon, ButtonIcon, buttonProps, placeholder, value, onValueChange, onButtonClick, onFocus, onBlur }: Props,
  ref,
) {
  return (
    <Container>
      {LeftIcon && (
        <LeftIconContainer>
          <LeftIcon size={20} color={colors('orange')} />
        </LeftIconContainer>
      )}
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onValueChange}
        onFocus={onFocus}
        onBlur={onBlur}
        hasLeftIcon={!!LeftIcon}
        hasButton={!!ButtonIcon}
        ref={ref}
      />
      {ButtonIcon && (
        <Button onClick={onButtonClick} Icon={ButtonIcon} large primary outlinedNeutral {...buttonProps} />
      )}
    </Container>
  );
});
