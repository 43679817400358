import { useContext } from 'react';
import { SnacksContext } from '../contexts/SnacksProvider';

function InnerWithSnacks(props, Wrapped) {
  const { sendSnack, sendImportantSnack, clearSnacks, sendPageview, sendEvent, setTitle } = useContext(SnacksContext);

  return (
    <Wrapped
      {...props}
      {...{
        sendSnack,
        sendImportantSnack,
        clearSnacks,
        sendPageview,
        sendEvent,
        setTitle,
      }}
    />
  );
}

const withSnacks = (Wrapped) => (props) => InnerWithSnacks(props, Wrapped);

export default withSnacks;
