import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const CropIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 16.3 27"
    >
      <g transform="translate(1 1)">
        <path
          d="M203.85,15.265a6.6,6.6,0,0,0-4.253-1.618,1.067,1.067,0,0,0-.88.368,1.221,1.221,0,0,0-.367.882,6.32,6.32,0,0,0,1.613,4.265,6.6,6.6,0,0,0,4.254,1.618h.073a1.176,1.176,0,0,0,.807-.368,1.22,1.22,0,0,0,.366-.882A6.146,6.146,0,0,0,203.85,15.265Z"
          transform="translate(-198.35 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M203.85,8.868A6.6,6.6,0,0,0,199.6,7.25a1.067,1.067,0,0,0-.88.368,1.221,1.221,0,0,0-.367.882,6.32,6.32,0,0,0,1.613,4.265,6.6,6.6,0,0,0,4.254,1.618h.073a1.176,1.176,0,0,0,.807-.368,1.22,1.22,0,0,0,.366-.882A6.5,6.5,0,0,0,203.85,8.868Z"
          transform="translate(-198.35 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M211.4,13.647a6.6,6.6,0,0,0-4.253,1.618,5.977,5.977,0,0,0-1.614,4.264,1.076,1.076,0,0,0,.367.883,1.177,1.177,0,0,0,.807.367h.073a6.6,6.6,0,0,0,4.253-1.617A5.982,5.982,0,0,0,212.65,14.9a1.073,1.073,0,0,0-.367-.882A.891.891,0,0,0,211.4,13.647Z"
          transform="translate(-198.35 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M211.4,7.25a6.6,6.6,0,0,0-4.253,1.618,5.977,5.977,0,0,0-1.614,4.264,1.076,1.076,0,0,0,.367.883,1.177,1.177,0,0,0,.807.367h.073a6.6,6.6,0,0,0,4.253-1.617A5.982,5.982,0,0,0,212.65,8.5a1.073,1.073,0,0,0-.367-.882A1.35,1.35,0,0,0,211.4,7.25Z"
          transform="translate(-198.35 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M206.343,9.75h0a6.6,6.6,0,0,0,1.907-4.191,6.2,6.2,0,0,0-1.834-4.191,1.211,1.211,0,0,0-.88-.368,1.35,1.35,0,0,0-.88.368,6.421,6.421,0,0,0-1.906,4.191,6.206,6.206,0,0,0,1.833,4.191,1.211,1.211,0,0,0,.88.368A1.35,1.35,0,0,0,206.343,9.75Z"
          transform="translate(-198.35 -1)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y2="15.882"
          transform="translate(7.113 9.118)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
