import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const SeederIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1.8,34.8h1.9c0.5,0,0.8-0.4,0.8-0.8v-2.8c0-0.5-0.4-0.8-0.8-0.8H1.8c-0.5,0-0.8,0.4-0.8,0.8V34C1,34.5,1.4,34.8,1.8,34.8z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M40.3,34.8h1.9c0.5,0,0.8-0.4,0.8-0.8v-2.8c0-0.5-0.4-0.8-0.8-0.8h-1.9c-0.5,0-0.8,0.4-0.8,0.8V34C39.5,34.5,39.8,34.8,40.3,34.8z"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="41.9"
          y1="30.4"
          x2="1.8"
          y2="30.4"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="31.8"
          y1="25.3"
          x2="12.2"
          y2="25.3"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M30.6,14.3L29.9,10c0-0.5-0.4-0.9-0.9-0.9H14.2c-0.5,0-0.9,0.4-0.9,0.9l-0.7,4.3H30.6z"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M24.9,19.4H29c0.5,0,0.9-0.4,0.9-0.9l0.7-4.3"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M12.6,14.3l0.7,4.3c0,0.5,0.4,0.9,0.9,0.9h4.6"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		24.9,19.4 24.9,21.9 19,21.9 19,19.4 	"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="21.8"
          y1="25.3"
          x2="21.8"
          y2="34.8"
        />
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			16.1,25.3 12.2,30.4 12.2,34.8 		"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="12.2"
            y1="25.3"
            x2="1.8"
            y2="30.4"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="19"
            y1="21.9"
            x2="16.1"
            y2="25.3"
          />
        </g>
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			27.9,25.3 31.4,30.4 31.4,34.8 		"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="31.8"
            y1="25.3"
            x2="42.1"
            y2="30.4"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="24.9"
            y1="21.9"
            x2="27.5"
            y2="25.1"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
