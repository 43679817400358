import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const CalendarIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 23.3 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M87.514,87.5H67.682a.735.735,0,0,1-.734-.735v-16.9a.736.736,0,0,1,.734-.736H87.513a.734.734,0,0,1,.734.736v16.9A.734.734,0,0,1,87.514,87.5Z"
          transform="translate(-66.947 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M88.247,77.494h-21.3V69.869a.737.737,0,0,1,.735-.736h19.83a.736.736,0,0,1,.735.736Z"
          transform="translate(-66.947 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y2="4.055"
          transform="translate(5.681)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y2="4.055"
          transform="translate(15.619)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
