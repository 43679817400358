import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const EyeIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 27.105 17.297"
    >
      <g transform="translate(1.05 1)">
        <path
          d="M141.537,5.85A15.031,15.031,0,0,0,129.331,12.4a1.767,1.767,0,0,0,0,2.059,14.576,14.576,0,0,0,20.147,4.193,14.232,14.232,0,0,0,4.191-4.193,1.763,1.763,0,0,0,0-2.059A14.7,14.7,0,0,0,141.537,5.85Z"
          transform="translate(-129 -5.85)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="7.647"
          cy="7.647"
          r="7.647"
          transform="translate(4.89 0.003)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="2.354"
          cy="2.354"
          r="2.354"
          transform="translate(10.183 5.297)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
