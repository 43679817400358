import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ImplementIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 27 22.6"
    >
      <g transform="translate(1 1)">
        <path
          d="M1237.938,16a3.9,3.9,0,1,1-4.063,3.9A3.984,3.984,0,0,1,1237.938,16Z"
          transform="translate(-1217 -3.2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="4.687"
          y2="2.497"
          transform="translate(10.547 7.023)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1232.234,10.223l-1.484-5.774a1.687,1.687,0,0,0-1.641-1.249h-8.2a1.4,1.4,0,0,0-1.406,1.4v7.413"
          transform="translate(-1217 -3.2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1242,19.9V11.862a1.6,1.6,0,0,0-1.641-1.639h-8.125"
          transform="translate(-1217 -3.2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1223.25,11.315a6.243,6.243,0,1,1-6.25,6.242A6.246,6.246,0,0,1,1223.25,11.315Z"
          transform="translate(-1217 -3.2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="4.219"
          transform="translate(12.578 15.606)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
