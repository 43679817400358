import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@karnott/colors';
import { COLOR_ID, PARCELS_CONSTANTS } from '../constants/parcelsConstants';
import { PluginsContext } from '../contexts/plugins';
import { getActiveParcelColoration } from '../selectors/parcelColors';
import { getUniqueTagFromParcel, parcelHasClusterColor, parcelHasCropColor, parcelHasTagColor } from '../utils/parcels';

export function useParcelColoration() {
  const { hasOverallCropManagementPlugin } = useContext(PluginsContext);

  const activeColoration = useSelector(getActiveParcelColoration);
  const parcelsById = useSelector((state) => state.parcels.parcelById);

  return useCallback(
    (parcelId) => {
      if (!activeColoration.title) {
        return;
      }

      const parcel = parcelsById[parcelId];
      if (!parcel) return colors('orange');

      const cropId = parcel.current_crop_id;
      const tagId = getUniqueTagFromParcel(parcel);
      const clusterId = parcel.current_tenant_cluster_id || parcel.cluster_id;

      for (const item of activeColoration?.items || []) {
        if (
          activeColoration.id === COLOR_ID.CROPS
            ? parcel.category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE &&
              hasOverallCropManagementPlugin &&
              parcelHasCropColor(cropId, item)
            : activeColoration.id === COLOR_ID.CLUSTERS
              ? parcelHasClusterColor(clusterId, item)
              : parcelHasTagColor(tagId, item)
        ) {
          return item.color;
        }
      }
    },
    [
      activeColoration?.id,
      activeColoration?.items,
      activeColoration?.title,
      hasOverallCropManagementPlugin,
      parcelsById,
    ],
  );
}

export function useParcelColor({ withCurrentColoration = true } = { withCurrentColoration: true }) {
  const parcelsById = useSelector((state) => state.parcels.parcelById);

  const getParcelColorWithColoration = useParcelColoration();
  const getParcelColorWithoutColoration = useCallback(
    (parcelId) => {
      const parcel = parcelsById[parcelId];

      if (!parcel?.cluster_id) {
        return colors('orange');
      }
      if (parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
        return colors('yellow');
      }
      if (
        parcel.status === PARCELS_CONSTANTS.STATUS.RENT_OVER ||
        parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT ||
        parcel.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE
      ) {
        return colors('grey', 200);
      }
      return colors('cyan');
    },
    [parcelsById],
  );

  return useCallback(
    (parcelId) => {
      return (
        (withCurrentColoration && getParcelColorWithColoration(parcelId)) || getParcelColorWithoutColoration(parcelId)
      );
    },
    [getParcelColorWithColoration, getParcelColorWithoutColoration, withCurrentColoration],
  );
}
