import { Actions } from '../../actions';
import abstractObservationReducer from './abstract/abstractAssetObservationReducer';

export const parcelsObservationsReducer = abstractObservationReducer({
  success: Actions.OBSERVATIONS_PARCEL_SUCCESS,
  add: Actions.OBSERVATIONS_ADD,
  remove: Actions.OBSERVATIONS_DELETE,
  update: Actions.OBSERVATIONS_UPDATE,
  deleteFiles: Actions.OBSERVATIONS_DELETE_FILES,
  addFiles: Actions.OBSERVATIONS_ADD_FILES,
});

export const equipmentSessionsObservationsReducer = abstractObservationReducer({
  success: Actions.OBSERVATIONS_EQUIPMENT_SESSION_SUCCESS,
  add: Actions.OBSERVATIONS_ADD,
  remove: Actions.OBSERVATIONS_DELETE,
  update: Actions.OBSERVATIONS_UPDATE,
  deleteFiles: Actions.OBSERVATIONS_DELETE_FILES,
  addFiles: Actions.OBSERVATIONS_ADD_FILES,
});

export const equipmentsObservationsReducer = abstractObservationReducer({
  success: Actions.OBSERVATIONS_EQUIPMENT_SUCCESS,
  add: Actions.OBSERVATIONS_ADD,
  remove: Actions.OBSERVATIONS_DELETE,
  update: Actions.OBSERVATIONS_UPDATE,
  deleteFiles: Actions.OBSERVATIONS_DELETE_FILES,
  addFiles: Actions.OBSERVATIONS_ADD_FILES,
});
