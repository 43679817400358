import { v4 } from 'uuid';
import { createAppSlice } from '.';
import { errorHasOccurred } from '../actions';
import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { I18n, LocaleKey } from '../i18n';
import store from '../reducers/store';
import { Values } from '../utils/types';

type SnackId = string;

export type SnackType = Values<typeof SNACK_CONSTANTS.TYPE>;
export type SnackAction = Values<typeof SNACK_CONSTANTS.ACTION>;
export type Snack = {
  message: string;
  duration?: number;
  type?: SnackType;
  action?: SnackAction;
};

type SnackWithId = {
  id: SnackId;
} & Snack;

type State = {
  byId: Record<SnackId, SnackWithId>;
  queue: SnackId[];
};

const initialState: State = {
  byId: {},
  queue: [],
};

function addIdToSnack(snack: Snack): { payload: SnackWithId } {
  return { payload: { id: v4(), ...snack } };
}

export const snacksSlice = createAppSlice({
  name: 'snacks',
  initialState,
  reducers: (create) => ({
    snackAdded: create.preparedReducer(addIdToSnack, (state, { payload }) => {
      state.byId[payload.id] = payload;
      state.queue.push(payload.id);
    }),
    criticalSnackAdded: create.preparedReducer(addIdToSnack, (state, { payload }) => {
      state.byId[payload.id] = payload;
      state.queue.unshift(payload.id);
    }),
    snackDismissed: create.reducer<SnackId>((state, { payload }) => {
      state.queue = state.queue.filter((id) => id !== payload);
    }),
    snacksCleared: create.reducer((state) => {
      state.queue = [];
    }),
  }),
});

export async function alerting(call: () => Promise<unknown>): Promise<unknown> {
  try {
    return await call();
  } catch (e) {
    throw errorHasOccurred(e, store.dispatch);
  }
}

export function success(messageKey: LocaleKey, duration = 3000, action: SnackAction = 'OK') {
  store.dispatch(snackAdded({ type: 'success', duration, message: I18n.t(messageKey), action }));
}

export const { snackAdded, criticalSnackAdded, snacksCleared, snackDismissed } = snacksSlice.actions;

export default snacksSlice.reducer;
