import { Actions } from '.';

export const selectClusters = (payload) => ({
  type: Actions.SELECT_CLUSTERS,
  payload,
});

export const clearClusters = (payload) => ({
  type: Actions.CLEAR_CLUSTERS,
  payload,
});

export const updateSearch = (payload) => ({
  type: Actions.UPDATE_SEARCH,
  payload,
});

export const setTitle = (payload) => ({
  type: Actions.UPDATE_TITLE,
  payload,
});

export const toggleFullScreen = () => ({
  type: Actions.TOGGLE_FULLSCREEN,
});

export const toggleFullScreenDashboard = () => ({
  type: Actions.TOGGLE_FULLSCREEN_DASHBOARD,
});

export const setShowSessionReplay = (show) => ({
  type: Actions.SET_SHOW_SESSION_REPLAY,
  payload: { show },
});

export const toggleParcelSessionsTracks = () => ({
  type: Actions.TOGGLE_PARCEL_SESSIONS_TRACKS,
});

export const toggleEquipmentSessionsTracks = () => ({
  type: Actions.TOGGLE_EQUIPMENT_SESSIONS_TRACKS,
});

export const setParcelsBoxesOffset = (payload) => ({
  type: Actions.SET_PARCELS_BOXES_OFFSET,
  payload,
});

export const setEquipmentsBoxesOffset = (payload) => ({
  type: Actions.SET_EQUIPMENTS_BOXES_OFFSET,
  payload,
});

export const setEquipmentPageOffset = (payload) => ({
  type: Actions.SET_EQUIPMENT_PAGE_OFFSET,
  payload,
});

export const setParcelPageOffset = (payload) => ({
  type: Actions.SET_PARCEL_PAGE_OFFSET,
  payload,
});

export const setWorkedParcelsPageOffset = (payload) => ({
  type: Actions.SET_WORKED_PARCELS_PAGE_OFFSET,
  payload,
});

export const toggleUseIgnTiles = () => ({
  type: Actions.TOGGLE_USE_IGN_TILES,
});

export const toggleParcelTypes = (payload) => ({
  type: Actions.TOGGLE_PARCEL_TYPES,
  payload,
});

export const setParcelTypes = (payload) => ({
  type: Actions.SET_PARCEL_TYPES,
  payload,
});

const clearParcelTypes = () => ({
  type: Actions.CLEAR_PARCEL_TYPES,
});

export const toggleCrops = (payload) => ({
  type: Actions.TOGGLE_CROPS,
  payload,
});

export const setCrops = (payload) => ({
  type: Actions.SET_CROPS,
  payload,
});

export const clearCrops = () => ({
  type: Actions.CLEAR_CROPS,
});

export const setAreaRange = (payload) => ({
  type: Actions.SET_AREA_RANGE,
  payload,
});

export const clearAreaRange = () => ({
  type: Actions.CLEAR_AREA_RANGE,
});

const resetTags = (payload) => ({
  type: Actions.RESET_TAGS,
  payload,
});

export const setTags = (payload) => ({
  type: Actions.SET_TAGS,
  payload,
});

const setDefaultTags = (payload) => ({
  type: Actions.SET_DEFAULT_PARCEL_TAGS,
  payload,
});

export const setInitialTags = (payload) => (dispatch) => {
  dispatch(setTags(payload.map((id) => ({ id }))));
  dispatch(setDefaultTags(payload));
};

export const toggleTags = (payload) => ({
  type: Actions.TOGGLE_TAGS,
  payload,
});

export const toggleParcelIds = (payload) => ({
  type: Actions.TOGGLE_PARCEL_IDS,
  payload,
});

export const setParcelIds = (payload) => ({
  type: Actions.SET_PARCEL_IDS,
  payload,
});

export const clearParcelIds = () => ({
  type: Actions.CLEAR_PARCEL_IDS,
});

export const hideIntercomMessenger = (payload) => ({
  type: Actions.HIDE_INTERCOM_MESSENGER,
  payload,
});

export const resetParcelFilters = () => (dispatch) => {
  dispatch(clearClusters());
  dispatch(clearParcelTypes());
  dispatch(clearCrops());
  dispatch(clearAreaRange());
  dispatch(resetTags());
};
