import { useContext, useMemo } from 'react';
import { MapContext } from '../../contexts/map';
import { useStyleBuilders } from '../../effects';
import { useDrawTracksOnMap, useOverTracks, useTracksDefaultStyle } from './effects';

const Tracks = function ({
  tracks,
  events,
  styleOverrides,
  overTrackID,
  overStyle,
  tracksWithoutFlags,
  withEnd,
  withEndIcons,
}) {
  const { map } = useContext(MapContext);
  const defaultTrackStyle = useTracksDefaultStyle();
  const styles = useMemo(() => [defaultTrackStyle].concat(styleOverrides || []), [defaultTrackStyle, styleOverrides]);
  const trackStyles = useStyleBuilders(styles);

  const [tracksMap, decoratorsMap] = useDrawTracksOnMap(
    map,
    tracks,
    events,
    trackStyles,
    tracksWithoutFlags,
    withEnd,
    withEndIcons,
  );
  useOverTracks({ overTrackID, tracksMap, decoratorsMap, trackStyles, overStyle });
  return null;
};

export default Tracks;
