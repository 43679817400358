import transform from 'lodash-es/transform';
import { schema } from 'normalizr';

const { Entity } = schema;

const underscoreToCamelCase = (word) => {
  if (typeof word === 'string') {
    return word.replace(/_(.)/g, (match) => match[1].toUpperCase());
  }
  return word;
};
export const transformEntityWithCamelCase = (entity) =>
  transform(
    entity,
    (result, value, key) => {
      if (key === 'authorizations') {
        result[key] = value;
        return;
      }
      const newKey = underscoreToCamelCase(key);
      if (value != null && value.constructor === Object) {
        result[newKey] = transformEntityWithCamelCase(value);
      } else {
        result[newKey] = value;
      }
    },
    {},
  );
// User
const user = new Entity('user');
const withAuthor = { author: user, lastUpdater: user };

// Rights
const rights = new Entity('rights');

// Clusters
const cluster = new Entity('cluster', { ...withAuthor, rights });

// Parcels
export const parcel = new Entity('parcel', {
  ...withAuthor,
  cluster,
});

// Crops
export const crop = new Entity('crop');

// Devices
const deviceModel = new Entity(
  'deviceModel',
  { ...withAuthor },
  {
    idAttribute: 'equipmentType',
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);
export const deviceInstance = new Entity(
  'deviceInstance',
  {
    ...withAuthor,
    cluster,
    deviceModel,
  },
  {
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);

// Beacons
export const beacon = new Entity(
  'beacon',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);

// Equipments
export const equipmentModel = new Entity(
  'equipmentModel',
  { ...withAuthor },
  {
    processStrategy: (entity) => ({
      ...transformEntityWithCamelCase(entity),
      equipmentType: entity.type,
    }),
  },
);
export const equipmentInstance = new Entity(
  'equipmentInstance',
  {
    ...withAuthor,
  },
  {
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);

export const equipmentHasDevice = new Entity(
  'equipmentHasDevice',
  {
    ...withAuthor,
    deviceInstance,
    equipmentInstance,
  },
  {
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);
equipmentInstance.define({ equipmentHasDevices: [equipmentHasDevice] });

// Sessions
export const parcelSession = new Entity(
  'parcelSession',
  {
    ...withAuthor,
    parcel,
  },
  {
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);

export const equipmentSession = new Entity(
  'equipmentSession',
  {
    parcelSessions: [parcelSession],
  },
  {
    processStrategy: (entity) => ({
      ...transformEntityWithCamelCase(entity),
    }),
  },
);

export const clusterParcel = new Entity(
  'clusterParcel',
  {
    parcel,
    cluster,
  },
  {
    processStrategy: (entity) => transformEntityWithCamelCase(entity),
  },
);
