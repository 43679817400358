import { useContext, useMemo } from 'react';
import { MapContext } from '../../contexts/map';
import { useStyleBuilders } from '../../effects';
import { parcelPropertiesDataExtractor, useDrawParcelsOnMap, useParcelsDefaultStyle } from './effects';

const Parcels = function ({
  parcels,
  events,
  forceNoMarkerCluster,
  styleOverrides,
  parcelOverTracks,
  parcelsCanvasRenderer,
  parcelsNamesDraggable,
}) {
  const { map } = useContext(MapContext);
  const defaultParcelStyle = useParcelsDefaultStyle();
  const styles = useMemo(() => [defaultParcelStyle].concat(styleOverrides || []), [defaultParcelStyle, styleOverrides]);
  const parcelStyles = useStyleBuilders(styles);
  useDrawParcelsOnMap(
    map,
    parcels,
    events,
    parcelStyles,
    parcelPropertiesDataExtractor,
    parcelOverTracks,
    parcelsCanvasRenderer,
    parcelsNamesDraggable,
    forceNoMarkerCluster,
  );

  return null;
};

export default Parcels;
