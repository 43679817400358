import { useCallback, useEffect, useMemo, useState } from 'react';

export function useListSelection<T>(
  multiple: boolean,
  onChange: null | ((selection: T[]) => void),
  initialItems: T[] = [],
  items: T[] = [],
  areItemsEquals: (a: T, b: T) => boolean = (a, b) => a === b,
) {
  const [selection, _setSelection] = useState(initialItems ?? []);

  const setSelection = useCallback(
    (newSelection: T[]) => {
      onChange && onChange(newSelection);
      _setSelection(newSelection);
    },
    [onChange],
  );

  const toggleItemSelection = useCallback(
    (item: T) => {
      let newSelection: T[];
      if (selection && !!selection.find((other) => areItemsEquals(item, other))) {
        newSelection = !multiple ? [] : selection.filter((i) => !areItemsEquals(i, item));
      } else {
        newSelection = !multiple ? [item] : selection.concat([item]);
      }
      setSelection(newSelection);
    },
    [selection, setSelection, areItemsEquals, multiple],
  );

  const clearSelection = useCallback(() => {
    setSelection([]);
  }, [setSelection]);

  const selectAll = useCallback(() => {
    setSelection(items);
  }, [items, setSelection]);

  return { multiple, toggleItemSelection, selection, setSelection, clearSelection, selectAll, areItemsEquals };
}

function useListQueryFilter<T>(
  list: T[],
  getItemLabel: (item: T) => string,
): [filteredList: T[], query: string, setQuery: React.Dispatch<React.SetStateAction<string>>] {
  const [query, setQuery] = useState('');

  const filteredList = useMemo(() => {
    if (!query) return list;
    return (list || []).filter((i) => getItemLabel(i).toLocaleLowerCase().includes(query.toLocaleLowerCase()));
  }, [list, query, getItemLabel]);

  return [filteredList, query, setQuery];
}

function useRadio<T extends { checked?: boolean }>(initialItems: T[]) {
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const checkedItem = useMemo(() => items.find((item) => item.checked), [items]);

  const setCheckedItemByIndex = useCallback((index: number) => {
    setItems((prevItems) => prevItems.map((item, i) => ({ ...item, checked: i === index })));
  }, []);

  return { items, checkedItem, setCheckedItemByIndex };
}

export const FormHooks = {
  useListSelection,
  useListQueryFilter,
  useRadio,
};
