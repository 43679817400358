import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const CoupeIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="11.9"
          y1="13.3"
          x2="32.3"
          y2="13.3"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		26.3,13.4 26.3,22.7 17.5,22.7 17.5,13.4 	"
        />
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			33.1,37.6 33.1,6.4 43,6.4 		"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="33.1"
            y1="15.4"
            x2="36.3"
            y2="18.6"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="33.1"
            y1="22.1"
            x2="36.3"
            y2="25.3"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="33.1"
            y1="28.8"
            x2="36.3"
            y2="31.9"
          />
        </g>
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			10.9,37.6 10.9,6.4 1,6.4 		"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="10.9"
            y1="15.4"
            x2="7.7"
            y2="18.6"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="10.9"
            y1="22.1"
            x2="7.7"
            y2="25.3"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="10.9"
            y1="28.8"
            x2="7.7"
            y2="31.9"
          />
        </g>
        <g>
          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			27.8,28.1 27.8,37.6 16.1,37.6 16.1,28.1 		"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M16.1,28.9v-2.7c0-2,1.4-3.6,3.1-3.6h5.5c1.7,0,3.1,1.6,3.1,3.6v2.7"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
