export const ExportIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <g>
        <line fill="none" stroke={props.color} x1="21" y1="23.6" x2="21" y2="14.6" />
        <g>
          <line fill="none" stroke={props.color} x1="21" y1="24.3" x2="24.8" y2="20.4" />
          <line fill="none" stroke={props.color} x1="21" y1="24.3" x2="17.2" y2="20.4" />
        </g>
      </g>
      <line fill="none" stroke={props.color} x1="17.2" y1="27.4" x2="24.8" y2="27.4" />
    </g>
  </svg>
);
