import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const AreaMeasureIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '55%' : '100%'}
      height={circled ? '55%' : '100%'}
      viewBox="0 0 26.499 26.466"
    >
      <g transform="translate(0.75 0.75)">
        <path
          d="M143.309,6.589l.02,0a2.921,2.921,0,1,0-2.918-2.944"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M140.411,3.647l0,.024a2.919,2.919,0,0,0,2.9,2.918"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M124.173,6.591a2.92,2.92,0,0,0,2.918-2.92l0-.024"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M127.089,3.647a2.92,2.92,0,1,0-2.918,2.944h0"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M143.309,19.877l.02,0a2.92,2.92,0,1,1-2.921,2.921l0-.013"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M143.309,19.877a2.918,2.918,0,0,0-2.9,2.906"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M124.173,19.8a2.92,2.92,0,0,1,2.918,2.92c0,.023-.006.044-.007.067"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M124.173,19.8h0a2.921,2.921,0,1,0,2.914,2.987"
          transform="translate(-121.25 -0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y2="13.288"
          transform="translate(22.06 5.839)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          strokeDasharray="1 3"
        />
        <line
          y2="13.205"
          transform="translate(2.923 5.841)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          strokeDasharray="1 3"
        />
        <line
          x2="13.325"
          transform="translate(5.835 22.033)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          strokeDasharray="1 3"
        />
        <line
          x2="13.322"
          transform="translate(5.839 2.897)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          strokeDasharray="1 3"
        />
        <path
          d="M132.233,12.6a1.231,1.231,0,0,1,.318.92V15.11h-.807V13.641a.726.726,0,0,0-.144-.493.53.53,0,0,0-.419-.163.642.642,0,0,0-.486.188.782.782,0,0,0-.181.561V15.11h-.807V11.273h.807v1.345a1.064,1.064,0,0,1,.388-.246,1.4,1.4,0,0,1,.5-.085,1.143,1.143,0,0,1,.835.31"
          transform="translate(-121.25 -0.75)"
          fill={color}
        />
        <path
          d="M135.4,12.6a1.175,1.175,0,0,1,.346.928V15.11h-.755v-.346a.9.9,0,0,1-.847.387,1.319,1.319,0,0,1-.556-.108.827.827,0,0,1-.36-.3.777.777,0,0,1-.124-.435.725.725,0,0,1,.292-.61,1.49,1.49,0,0,1,.9-.222h.642a.514.514,0,0,0-.161-.406.705.705,0,0,0-.481-.142,1.4,1.4,0,0,0-.437.07,1.139,1.139,0,0,0-.364.189l-.291-.564a1.742,1.742,0,0,1,.547-.248,2.422,2.422,0,0,1,.654-.088,1.449,1.449,0,0,1,.993.308m-.688,1.918a.531.531,0,0,0,.228-.282v-.284h-.554q-.5,0-.5.325a.29.29,0,0,0,.122.246.553.553,0,0,0,.333.091.7.7,0,0,0,.368-.1"
          transform="translate(-121.25 -0.75)"
          fill={color}
        />
        <path
          d="M137.792,12.991v.4h-1.6v-.316l.814-.769a.847.847,0,0,0,.175-.213.387.387,0,0,0,.045-.176.229.229,0,0,0-.086-.194.393.393,0,0,0-.251-.068.576.576,0,0,0-.25.052.477.477,0,0,0-.187.159l-.356-.229a.826.826,0,0,1,.341-.288,1.124,1.124,0,0,1,.5-.1,1.013,1.013,0,0,1,.412.077.635.635,0,0,1,.274.217.562.562,0,0,1,.1.33.724.724,0,0,1-.073.322,1.227,1.227,0,0,1-.28.344l-.485.458Z"
          transform="translate(-121.25 -0.75)"
          fill={color}
        />
      </g>
    </svg>
  </CircledIcon>
);
