import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ChronoIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 26.999 27"
    >
      <g transform="translate(1 1)">
        <ellipse
          cx="10.511"
          cy="10.554"
          rx="10.511"
          ry="10.554"
          transform="translate(0 3.892)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M1.3,0H2.665a1.3,1.3,0,0,1,1.3,1.3v2.79a1.3,1.3,0,0,1-1.3,1.3H1.3A1.3,1.3,0,0,1,0,4.088V1.3A1.3,1.3,0,0,1,1.3,0Z"
          transform="translate(21.037 11.753)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          width="5.362"
          height="3.891"
          rx="1.297"
          transform="translate(7.771)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          width="12.07"
          height="5.192"
          rx="0.989"
          transform="translate(4.459 12.226)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
