export const CloseIcon = (props) => (
  <svg height={`${props.size || 24}`} width={`${props.size || 24}`} viewBox="0 0 24 24">
    <g>
      <path
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M6.34 6.34l11.32 11.32"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M17.66 6.34L6.34 17.66"
      />
    </g>
  </svg>
);
