import omit from 'lodash-es/omit';
import uniq from 'lodash-es/uniq';
import { Actions } from '../actions';

const initialState = {
  isFetching: false,
  clusterById: {},
  clustersIds: [],
  clusterToEdit: null,
  clusterPartners: {},
  clusterPartnersFetched: false,
};

const clusters = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.CLUSTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.CLUSTERS_SUCCESS: {
      const clusters = {};
      payload.forEach((cluster) => (clusters[cluster.id] = cluster));
      return {
        ...state,
        clustersIds: payload.map((cluster) => cluster.id),
        clusterById: clusters,
        isFetching: false,
      };
    }
    case Actions.CLUSTER_SUCCESS:
      return {
        ...state,
        clustersIds: uniq(state.clustersIds.concat(payload.id)),
        clusterById: {
          ...state.clusterById,
          [payload.id]: {
            ...state.clusterById[payload.id],
            ...payload,
          },
        },
      };
    case Actions.CLUSTER_UNLINKED:
      return {
        ...state,
        clustersIds: state.clustersIds.filter((id) => id !== action.payload),
        clusterById: omit(state.clusterById, action.payload),
      };
    case Actions.CLUSTER_TO_EDIT:
      return {
        ...state,
        clusterToEdit: payload,
      };
    case Actions.CLEAR_CLUSTER_TO_EDIT:
      return {
        ...state,
        clusterToEdit: null,
      };
    case Actions.CLUSTERS_PARTNERS_RETRIEVED: {
      if (!payload) return state;
      const clusterPartners = {};
      payload.forEach((p) => {
        if (clusterPartners[p.cluster_id]) {
          clusterPartners[p.cluster_id].linked_from = clusterPartners[p.cluster_id].linked_from.concat(p.linked_from);
          clusterPartners[p.cluster_id].partner = clusterPartners[p.cluster_id].partner.concat(p.partner);
        } else {
          clusterPartners[p.cluster_id] = p;
          clusterPartners[p.cluster_id].linked_from = [p.linked_from];
          clusterPartners[p.cluster_id].partner = [p.partner];
        }
      });
      return {
        ...state,
        clusterPartners,
        clusterPartnersFetched: true,
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default clusters;
