export const MailIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <g>
        <rect x="13.7" y="15.9" fill="none" stroke={props.color} width="14.6" height="10.3" />
        <g>
          <polyline fill="none" stroke={props.color} points="13.7,15.9 21,22.9 28.3,15.9 			" />
        </g>
      </g>
    </g>
  </svg>
);
