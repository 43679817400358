import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const BeaconIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 22 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M19.667,87.5H7.528a1.213,1.213,0,0,1-.857-.355L3.953,84.427a1.212,1.212,0,0,1-.355-.858V71.431a1.212,1.212,0,0,1,.355-.858l2.718-2.718a1.213,1.213,0,0,1,.857-.355H19.667a1.213,1.213,0,0,1,.857.355l2.718,2.718a1.213,1.213,0,0,1,.356.858V83.569a1.213,1.213,0,0,1-.356.858l-2.718,2.718A1.213,1.213,0,0,1,19.667,87.5Z"
          transform="translate(-3.598 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="2.915"
          cy="2.915"
          r="2.915"
          transform="translate(7.084 7.085)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M16.438,84.051H10.757a3.531,3.531,0,0,1-2.5-1.036l-.175-.174a3.532,3.532,0,0,1-1.035-2.5V74.66a3.53,3.53,0,0,1,1.035-2.5l.175-.175a3.535,3.535,0,0,1,2.5-1.035h5.681a3.538,3.538,0,0,1,2.5,1.035l.174.175a3.535,3.535,0,0,1,1.035,2.5v5.681a3.536,3.536,0,0,1-1.035,2.5l-.174.174A3.535,3.535,0,0,1,16.438,84.051Z"
          transform="translate(-3.598 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="2.547"
          y2="2.547"
          transform="translate(15.738 15.739)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="2.547"
          y2="2.547"
          transform="translate(1.713 1.714)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="2.32"
          y2="2.32"
          transform="translate(1.94 15.739)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="2.547"
          y2="2.547"
          transform="translate(15.738 1.714)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
