export const UserIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <path
        fill="none"
        stroke={props.color}
        d="M23.7,19.1c0.1,0.3,0.1,0.6,0.1,1c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.4-0.3,0.7-0.6,0.9c-0.2,0.3-0.5,0.5-0.9,0.6
		C21.8,22.9,21.4,23,21,23c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.4-0.2-0.8-0.2-1.2
		c0-0.4,0.1-0.8,0.2-1.2"
      />
      <g>
        <path
          fill="none"
          stroke={props.color}
          d="M22.5,22.7c0.3,0.1,0.6,0.2,0.9,0.3c0.7,0.3,1.3,0.6,1.8,1.1c0.5,0.5,0.8,1.1,1.1,1.7
			c0.1,0.3,0.2,0.6,0.2,0.9l-11,0c0.1-0.3,0.1-0.6,0.2-0.9c0.3-0.7,0.6-1.3,1.1-1.7c0.5-0.5,1.1-0.9,1.7-1.1
			c0.2-0.1,0.5-0.2,0.7-0.2"
        />
      </g>
      <line fill="none" stroke={props.color} x1="16" y1="19.1" x2="26" y2="19.1" />
      <polygon fill="none" stroke={props.color} points="23.7,19.1 18.3,19.1 19.4,15.3 22.6,15.3 	" />
    </g>
  </svg>
);
