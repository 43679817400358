import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { useRemoteConfigValue } from '../components/remoteConfig';
import MaintenanceContainer from '../containers/MaintenanceContainer';

export class RemoteConfig {
  static SETTINGS = {
    minimumFetchIntervalMillis: 120000,
  };

  static CONFIG_NAMES = {
    bale_is_in_test: 'bale_is_in_test',
    images_on_observations_is_new: 'images_on_observations_is_new',
    display_api_maintenance: 'display_api_maintenance',
  };

  static CONFIG_DEFAULTS = {
    [RemoteConfig.CONFIG_NAMES.bale_is_in_test]: false,
    [RemoteConfig.CONFIG_NAMES.images_on_observations_is_new]: false,
    [RemoteConfig.CONFIG_NAMES.display_api_maintenance]: false,
  };

  static instance = null;

  constructor() {
    this.listeners = [];
  }

  setConfig(firebaseRemoteConfig) {
    if (firebaseRemoteConfig) {
      this.config = firebaseRemoteConfig;
      this.config.settings = RemoteConfig.SETTINGS;
      this.config.defaultConfig = RemoteConfig.CONFIG_DEFAULTS;
      this.fetchActivate();
    }
  }

  fetchActivate(force) {
    if (force) {
      this.config.settings = {
        minimumFetchIntervalMillis: 10000,
      };
    }
    fetchAndActivate(this.config)
      .then(() => {
        this.error = null;
        this.listeners.forEach((listener) => listener());
        if (force) {
          this.config.settings = RemoteConfig.SETTINGS;
        }
      })
      .catch((e) => {
        if (e && e.code === 'remoteconfig/storage-open') return Promise.resolve();
        this.error = e;
      });
  }

  async configValueIsEnabled(name) {
    if (this.error) return RemoteConfig.CONFIG_DEFAULTS[name];
    return getValue(this.config, name);
  }

  static getInstance() {
    if (RemoteConfig.instance === null) RemoteConfig.instance = new RemoteConfig();
    return RemoteConfig.instance;
  }

  addFetchListener(listener) {
    this.listeners.push(listener);
  }

  removeFetchListener(listener) {
    this.listeners.filter((l) => l !== listener);
  }
}

export function RemoteConfigSwitch({ children }) {
  const displayAPIMaintenance = useRemoteConfigValue(RemoteConfig.CONFIG_NAMES.display_api_maintenance, 'boolean');

  return displayAPIMaintenance ? <MaintenanceContainer /> : children;
}
