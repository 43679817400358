import { Actions } from '../../../actions';

const initialState = {
  isFetching: false,
  list: [],
};

const abstractObservationReducer = ({ request, success, add, remove, update, deleteFiles, addFiles }) =>
  function (state = {}, action) {
    const { type, payload } = action;
    const { assetId, observations, observation } = payload || {};
    const assetObservations = assetId && state[assetId] ? state[assetId] : initialState;
    if (!assetId) return state;
    switch (type) {
      case success:
        return {
          ...state,
          [assetId]: {
            isFetching: false,
            list: observations,
          },
        };

      case request:
        return {
          ...state,
          [assetId]: {
            ...assetObservations,
            isFetching: true,
          },
        };

      case remove:
        return {
          ...state,
          [assetId]: {
            ...assetObservations,
            list: assetObservations.list.filter((o) => o.id !== observation.id),
          },
        };

      case add:
        return {
          ...state,
          [assetId]: {
            ...assetObservations,
            list: [observation].concat(assetObservations.list || []),
          },
        };

      case update:
        return {
          ...state,
          [assetId]: {
            ...assetObservations,
            list: assetObservations.list.map((o) => {
              if (o.id === observation.id) {
                return {
                  ...observation,
                };
              }
              return o;
            }),
          },
        };
      case deleteFiles:
        return {
          ...state,
          [assetId]: {
            ...assetObservations,
            list: assetObservations.list.map((o) => {
              if (o.id === observation.id) {
                return {
                  ...o,
                  binaries: o.binaries.filter((b) => !payload?.deletedFileIds?.includes(b.id)),
                  updated: true,
                };
              }
              return o;
            }),
          },
        };
      case addFiles:
        return {
          ...state,
          [assetId]: {
            ...assetObservations,
            list: assetObservations.list.map((o) => {
              if (o.id === observation.id) {
                if (o.binaries?.pending) {
                  o.binaries = [];
                }
                return {
                  ...o,
                  binaries: [...(o.binaries || []), ...payload.newFiles],
                };
              }
              return o;
            }),
          },
        };

      case Actions.LOGOUT:
        return {};

      default:
        return state;
    }
  };

export default abstractObservationReducer;
