import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const GiftIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 17 22.002"
    >
      <g transform="translate(1 1.002)">
        <path
          d="M76.521,132.573c1.219,1.3,1.85,6.081,1.85,6.081s-4.473-.675-5.689-1.975a3.3,3.3,0,0,1-.286-4.411A2.807,2.807,0,0,1,76.521,132.573Zm7.5,4.106c-1.216,1.3-5.689,1.975-5.689,1.975s.632-4.781,1.85-6.081a2.8,2.8,0,0,1,4.123-.3A3.293,3.293,0,0,1,84.021,136.679Zm-5.691,1.867V151.5m6.083-.044H72.247a1.471,1.471,0,0,1-1.417-1.512v-9.883a1.472,1.472,0,0,1,1.417-1.515H84.413a1.474,1.474,0,0,1,1.417,1.515v9.883A1.473,1.473,0,0,1,84.413,151.456Z"
          transform="translate(-70.83 -131.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
