import Icon from 'react-icon-base';

const IcKarnott = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="#4B453C"
      d="M22.7548103,9.44 L20.8898266,7.345 C20.7124356,7.13222446 20.4517778,7.00645142 20.1748328,7 L3.82497537,7 C3.55198671,7.00015409 3.29197402,7.11652442 3.1099816,7.32 L1.24499786,9.44 C1.088521,9.61464133 1.0013736,9.84051515 1,10.075 L1,14.925 C1.0013736,15.1594848 1.088521,15.3853587 1.24499786,15.56 L3.1099816,17.655 C3.28737252,17.8677755 3.54803032,17.9935486 3.82497537,18 L20.1748328,18 C20.4478215,17.9998459 20.7078341,17.8834756 20.8898266,17.68 L22.7548103,15.585 C22.9171069,15.403963 23.004666,15.1680873 22.9998082,14.925 L22.9998082,10.075 C22.9984346,9.84051515 22.9112872,9.61464133 22.7548103,9.44 Z M13.4648913,14.805 C13.1059094,14.805 12.814897,14.5139851 12.814897,14.155 C12.814897,13.7960149 13.1059094,13.505 13.4648913,13.505 C13.8238733,13.505 14.1148856,13.7960149 14.1148856,14.155 C14.1148856,14.5139851 13.8238733,14.805 13.4648913,14.805 Z M16.0748685,14.805 C15.7158866,14.805 15.4248742,14.5139851 15.4248742,14.155 C15.4248742,13.7960149 15.7158866,13.505 16.0748685,13.505 C16.4338505,13.505 16.7248629,13.7960149 16.7248629,14.155 C16.7248629,14.5139851 16.4338505,14.805 16.0748685,14.805 Z M18.6848458,14.805 C18.4219476,14.805 18.1849361,14.6466326 18.0843293,14.4037442 C17.9837226,14.1608559 18.0393333,13.8812793 18.2252304,13.6953806 C18.4111275,13.5094819 18.6907016,13.4538707 18.9335879,13.5544783 C19.1764741,13.655086 19.3348401,13.8920995 19.3348401,14.155 C19.3348507,14.5120415 19.0468736,14.8022535 18.6898457,14.805 L18.6848458,14.805 Z"
    />
  </Icon>
);

export default IcKarnott;
