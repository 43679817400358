import { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { fontFamily } from '@karnott/theme';

const Container = styled.div<{
  $size: number;
  $color?: string;
  backgroundColor?: string;
  borderColor?: string;
}>`
  padding: 2px 5px;
  font-size: ${({ $size }) => $size + 'px'};
  color: ${({ $color = colors('white') }) => $color};
  font-family: ${fontFamily('overstatement')};
  border-radius: 1000px;
  background-color: ${({ backgroundColor = colors('grey') }) => backgroundColor};
  border: ${({ $size }) => $size / 8}px solid black;
  border-color: ${({ borderColor, backgroundColor = colors('grey') }) => borderColor || backgroundColor};
  max-width: fit-content;
`;

const Label = styled.span`
  font-weight: bold;
`;

const TAG_COLOR_MODE = {
  inactive: {
    base: 'grey',
    font: 'white',
    border: 'grey',
    outlined: 'white',
    outlinedFont: 'grey',
    outlinedBorder: 'grey',
  },
  primary: {
    base: 'orange',
    font: 'white',
    border: 'orange',
    outlined: 'white',
    outlinedFont: 'orange',
    outlinedBorder: 'orange',
  },
  active: {
    base: 'green',
    font: 'white',
    border: 'green',
    outlined: 'white',
    outlinedFont: 'green',
    outlinedBorder: 'green',
  },
  danger: {
    base: 'red',
    font: 'white',
    border: 'red',
    outlined: 'white',
    outlinedFont: 'red',
    outlinedBorder: 'red',
  },
} as const;

type TagColorMode = keyof typeof TAG_COLOR_MODE;

type Props = {
  /** Label of the tag */
  label: string;
  /** Background color of the tag. All 3 custom colors must be set for them to be active */
  backgroundColor?: string;
  /** Border color of the tag */
  borderColor?: string;
  /** Font color of the tag */
  color?: string;
  /** Font size of the tag, in px */
  size?: number;
  /** Set tag as primary */
  primary?: boolean;
  /** Set tag as active */
  active?: boolean;
  /** Set tag as danger */
  danger?: boolean;
  /** Set tag as outlined (inverted colors) */
  outlined?: boolean;
};

/** A static tag to display status information about an entity */
export const Tag = ({
  backgroundColor,
  borderColor,
  color,
  size = 8,
  label,
  primary = false,
  active = false,
  danger = false,
  outlined = false,
}: Props) => {
  const [presetBackgroundColor, presetFontColor, presetBorderColor] = useMemo(() => {
    if (backgroundColor && color && borderColor) {
      return [backgroundColor, color, borderColor];
    }
    const bgShade = 500;
    const fontShade = 500;

    let mode: TagColorMode = 'inactive';
    if (primary) mode = 'primary';
    if (active) mode = 'active';
    if (danger) mode = 'danger';

    if (outlined) {
      return [
        colors(TAG_COLOR_MODE[mode].outlined, bgShade) || undefined,
        colors(TAG_COLOR_MODE[mode].outlinedFont, fontShade) || undefined,
        colors(TAG_COLOR_MODE[mode].outlinedBorder, bgShade) || undefined,
      ];
    } else {
      return [
        colors(TAG_COLOR_MODE[mode].base, bgShade) || undefined,
        colors(TAG_COLOR_MODE[mode].font, fontShade) || undefined,
        colors(TAG_COLOR_MODE[mode].border, bgShade) || undefined,
      ];
    }
  }, [primary, active, danger, backgroundColor, color, borderColor, outlined]);

  return (
    <Container
      backgroundColor={presetBackgroundColor}
      $color={presetFontColor}
      $size={size}
      borderColor={presetBorderColor}
    >
      <Label>{label.toLocaleUpperCase()}</Label>
    </Container>
  );
};
