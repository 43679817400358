import { useCallback } from 'react';
import { DriverPopup } from '@karnott/map';
import { ANALYTICS, sendAnalyticsTooltipClickEvent } from '../../../../utils/AnalyticsConstants';

export function DriverTooltip({ driverProps, labels }) {
  const { id, name, isActive, beaconUUID, clusterName, history, lastDate } = driverProps;

  const actionCB = useCallback(() => {
    sendAnalyticsTooltipClickEvent(window)(ANALYTICS.LABEL.TOOLTIPS.DRIVER);
    history.push(`/drivers/${id}/`);
  }, [history, id]);

  return (
    <DriverPopup
      name={name}
      labels={labels}
      chipAction={actionCB}
      isActive={isActive}
      beaconUUID={beaconUUID}
      clusterName={clusterName}
      lastDate={lastDate}
    />
  );
}
