import { useContext } from 'react';
import { MapContext } from '../../contexts/map';
import { useDrawObservationsOnMap } from './effects';

const Observations = function ({ observations, events }) {
  const { map } = useContext(MapContext);
  useDrawObservationsOnMap(map, observations, events);
  return null;
};

export default Observations;
