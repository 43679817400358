export const AssociationIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <path
        fill="none"
        stroke={props.color}
        d="M22.5,25.1l-0.3,0.3c-1.5,1.5-4,1.5-5.5,0l0,0c-1.5-1.5-1.5-4,0-5.5l0.3-0.3"
      />
      <path
        fill="none"
        stroke={props.color}
        d="M19.5,16.9l0.3-0.3c1.5-1.5,4-1.5,5.5,0l0,0c1.5,1.5,1.5,4,0,5.5l-0.3,0.3"
      />
      <line fill="none" stroke={props.color} x1="22.2" y1="19.8" x2="19.8" y2="22.2" />
    </g>
  </svg>
);
