import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const DistanceIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 22 12.5"
    >
      <g transform="translate(1 1)">
        <line
          x1="10.302"
          transform="translate(4.849 5.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="1.896"
          y1="1.879"
          transform="translate(13.847 3.371)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="1.896"
          y2="1.879"
          transform="translate(13.847 5.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="1.896"
          y2="1.879"
          transform="translate(4.257 5.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y1="1.879"
          x2="1.896"
          transform="translate(4.257 3.371)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y1="10.5"
          transform="translate(20)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line y1="10.5" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  </CircledIcon>
);
