import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const RecoltIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <path
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
			M38.3,26.8h2.6c0.1,0,0.2-0.1,0.2-0.2v-2.6c0-0.1-0.1-0.2-0.2-0.2h-1.1l1.9-2.5l-2.4-4.6l-7.8,0l-2.2-6"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="22.3"
            y1="29.9"
            x2="29.2"
            y2="29.9"
          />
          <g>
            <path
              fill="none"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
				M11.5,22.6l-10,5.5C0.6,28.6,1,29.9,2,29.9h7.2"
            />
          </g>

          <ellipse
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="15.8"
            cy="28"
            rx="6.8"
            ry="7"
          />

          <ellipse
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="34.2"
            cy="29.9"
            rx="5"
            ry="5.2"
          />

          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="17.3"
            y1="10.9"
            x2="17.3"
            y2="20.9"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			39.3,16.6 40.2,13.7 41.7,12.5 40.7,10.5 18,10.5 17.3,10.5 12.9,9 7.3,10.5 11.5,22.6 		"
          />
        </g>

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="41.4"
          y1="25"
          x2="43"
          y2="25"
        />
      </g>
    </svg>
  </CircledIcon>
);
