import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const BaleIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 21.999 22"
    >
      <g transform="translate(1 1)">
        <line
          x2="9.72"
          transform="translate(0.279 20)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M277.4,148.571a10,10,0,1,1,2.928-7.071,9.969,9.969,0,0,1-2.928,7.071"
          transform="translate(-260.33 -131.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M272.646,141.5a2.316,2.316,0,1,1-2.316-2.316A2.316,2.316,0,0,1,272.646,141.5Z"
          transform="translate(-260.33 -131.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M276.463,141.5a6.133,6.133,0,1,1-6.134-6.134A6.133,6.133,0,0,1,276.463,141.5Z"
          transform="translate(-260.33 -131.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
