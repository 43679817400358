import { useCallback, useState } from 'react';

function useInputFocus({
  onFocus,
  onBlur,
}: {
  onFocus?: () => void;
  onBlur?: () => void;
}): [focused: boolean, focus: () => void, blur: () => void] {
  const [focused, setFocused] = useState(false);
  const focusCB = useCallback(() => {
    onFocus && onFocus();
    setFocused(true);
  }, [onFocus]);
  const blurCB = useCallback(() => {
    onBlur && onBlur();
    setFocused(false);
  }, [onBlur]);

  return [focused, focusCB, blurCB];
}

function useInputChangeCallback(callback: (value: string) => void) {
  return useCallback(
    (e: { target?: { value: string } }) => {
      return callback(e && e.target ? e.target.value : '');
    },
    [callback],
  );
}

function useInputValue(initialValue: string): [value: string, onChange: (e: { target?: { value: string } }) => void] {
  const [value, setValue] = useState(initialValue);
  const onInputChangeCB = useInputChangeCallback(setValue);

  return [value, onInputChangeCB];
}

export const Effects = {
  useInputFocus,
  useInputValue,
  useInputChangeCallback,
};
