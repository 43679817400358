import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { Input, Radio } from '@karnott/form';
import {
  AddIcon,
  CalendarIcon,
  ChevronIcon,
  ClusterIcon,
  DeleteIcon,
  InterventionDetailsIcon,
  KarnottIcon,
  MixtureIcon,
  PenIcon,
  PlotsIcon,
  SaveIcon,
  ShowLabelIcon,
  TaskCreateIcon,
  TaskTodoIcon,
  TrashIcon,
} from '@karnott/icons';
import { elevation, fontFamily, msDuration, pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';
import { fetchParcel } from '../../../../actions/parcels';
import { getParcelsWorksitesTracks } from '../../../../api/api';
import { TASK_STATUS } from '../../../../constants/taskConstants';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { UIContext } from '../../../../contexts/ui';
import {
  convertAreaToSquaredMeters,
  formatArea,
  getAreaUnit,
  round,
  sortAlphaBeta,
  sortByPosition,
} from '../../../../utils';
import { inertFeatures } from '../../../../utils/map';
import {
  formatMixtureFunction,
  getUsageProblems,
  getUsageProblemsLabel,
  isProductMixAuthorized,
} from '../../../../utils/phytos';
import { localStorageKeys } from '../../../../utils/storage';
import { getUserName } from '../../../../utils/user';
import { isNumber } from '../../../../utils/validation';
import IsolatedMap from '../../../map/IsolatedMap';
import { useParcelsOverrideStyle, useSelectedParcelsFocusedStyle } from '../../../map/assets/Parcels/effects';
import Controls from '../../../map/controls/Controls';
import { useLayerFilters } from '../../../map/filters/effects';
import ParcelThumbnail from '../../../parcels/ParcelThumbnail';

export const VerticalList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('large')};
`;

export const Label = styled.label`
  border: none;
  background: none;
  font-family: inherit;
  padding: 0;
  margin: 0;
  margin-bottom: ${pixelSpacing('xSmall')};
  color: ${colors('black', 400)};
  font-size: ${pixelSize('regular')};
  display: flex;
  // back icon
  > div {
    rotate: 0.25turn;
  }
`;

export const FormComponent = styled.div`
  position: relative;

  #date-container {
    max-width: unset;
    #date-trigger {
      max-width: 100%;
    }
  }

  ${({ error }) =>
    error
      ? css`
          ::after {
            content: '${error}';
            position: absolute;
            top: 100%;
            translate: 0 -1px;
            color: ${colors('red')};
            font-size: ${pixelSize('small')};
          }
        `
      : null}
`;

export const TwoColumnsInput = styled.div`
  display: grid;
  gap: ${pixelSpacing('small')};
  grid-template-columns: 1fr 3fr;
  align-items: center;
  input {
    text-align: center;
  }
  > span {
    color: ${colors('grey', 600)};
    font-size: ${pixelSize()};
  }
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${pixelSpacing()};
`;

export const InformationText = styled.div`
  background-color: ${({ error, warning }) =>
    error ? colors('red') : warning ? colors('orange') : colors('grey', 600)};
  color: ${colors('white')};
  font-size: ${pixelSize('regular')};
  margin-top: ${({ noMargin }) => (noMargin ? 0 : pixelSpacing('small'))};
  padding: ${pixelSpacing('small')};
  border-radius: 4px;
`;

const Card = styled.div`
  background: ${colors('white')};
  border: 1px solid ${({ error }) => (error ? colors('red') : colors('grey', 300))};
  border-radius: 4px;
  padding: ${pixelSpacing('small')};
  transition: border-color ${msDuration('short')} linear;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('small')};
  min-width: 0;
  > div {
    flex-shrink: 0;
  }
`;

const CardTitle = styled.h2`
  flex-grow: 1;
  font-size: ${pixelSize('regular')};
  margin: 0;
  font-weight: bold;
`;

const CardIconButton = styled.button`
  all: unset;
  cursor: pointer;
`;

const CardBody = styled.div`
  margin: 0 ${pixelSpacing('large')};
`;

const CardText = styled.p`
  margin: 0;
  font-size: ${pixelSize('regular')};
  color: ${colors('grey', 600)};
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const BlackCardText = styled(CardText)`
  color: ${colors('black')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const CardSubtitle = styled(CardText).attrs({ as: 'h3' })`
  font-size: ${pixelSize('small')};
  font-weight: normal;
`;

const CardGrid = styled.div`
  margin: ${pixelSpacing('small')} ${pixelSpacing('large')} ${pixelSpacing('xSmall')} ${pixelSpacing('large')};
  display: grid;
  align-items: center;
  column-gap: ${pixelSpacing('xSmall')};
  row-gap: ${pixelSpacing('xSmall')};
  grid-template-columns: 1fr 0.9fr 4px 60px auto 20px;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    text-align: right;
  }
`;

const CardGridIcon = styled.div`
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CardButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function MixtureUsageRow({ usage, dose, isEditing, onChange, batchNumber, onBatchNumberChange }) {
  const { t } = useContext(I18nContext);

  const [internalDose, setInternalDose] = useState(round(dose));
  const [doseError, setDoseError] = useState(!!usage.product_state && dose > usage.product_usage_dose);

  function onDoseChange(e) {
    const value = e.target.value.replace(',', '.');
    setInternalDose(value);

    // check input validity
    if (value.length === 0 || isNumber(value) || Number(value) === 0) {
      setDoseError(true);
      return;
    }

    setDoseError(false);

    // check usage validity (non-blocking)
    const numberValue = Number(value);
    if (!!usage.product_state && numberValue > usage.product_usage_dose) {
      setDoseError(true);
    }
    setInternalDose(round(numberValue));
    onChange(round(numberValue));
  }

  const problems = getUsageProblems(usage, internalDose, isEditing);

  return (
    <>
      <CardText title={usage.product_name}>{usage.product_name}</CardText>
      {isEditing ? (
        <Input
          placeholder={t('CreateTaskModal.composition_batch_number_placeholder')}
          value={batchNumber == undefined ? '' : batchNumber}
          onValueChange={(e) => {
            onBatchNumberChange(e.target.value);
            onDoseChange({ target: { value: internalDose === undefined ? String(usage.dose) : String(internalDose) } });
          }}
        />
      ) : (
        <CardText right title={usage.batch_number}>
          {usage.batch_number || '-'}
        </CardText>
      )}
      <div />
      {isEditing ? (
        <Input error={doseError} value={internalDose == undefined ? '' : internalDose} onValueChange={onDoseChange} />
      ) : (
        <CardText right title={`${usage.dose} ${usage.dose_unit}`}>
          {round(usage.dose)}
        </CardText>
      )}
      <CardText title={`${usage.dose} ${usage.dose_unit}`}>{usage.dose_unit}</CardText>
      <CardGridIcon title={getUsageProblemsLabel(problems)}>
        {problems.length === 0 ? (
          <SaveIcon circled size={size('small')} color={colors('white')} backgroundColor={colors('green')} />
        ) : (
          <DeleteIcon circled size={size('small')} color={colors('white')} backgroundColor={colors('red')} />
        )}
      </CardGridIcon>
    </>
  );
}

export function MixtureCard({ mixture, doses, batchNumbers, onReset, onChange, onBatchNumbersChange }) {
  const { t } = useContext(I18nContext);

  const [isEditing, setIsEditing] = useState(false);
  const [internalDoses, setInternalDoses] = useState(doses || {});
  const [internalBatchNumbers, setInternalBatchNumbers] = useState(batchNumbers || {});
  const usageErrors = mixture.product_usages.reduce(
    (p, usage) =>
      p ||
      getUsageProblems(
        doses && doses[usage.product_usage_id] ? { ...usage, dose: doses[usage.product_usage_id] } : usage,
      ).length > 0,
    false,
  );
  const productMixError = !isProductMixAuthorized(mixture);

  function onSave() {
    onChange(internalDoses);
    onBatchNumbersChange(internalBatchNumbers);
    setIsEditing(false);
  }

  return (
    <FormComponent>
      <Label>{t('CreateTaskModal.composition_input_label')}</Label>
      <Card error={usageErrors || productMixError}>
        <CardHeader>
          <MixtureIcon size={size('xLarge')} circled backgroundColor={colors('black', 600)} color={colors('white')} />
          <CardTitle>{mixture.name}</CardTitle>
          <CardIconButton
            title={t('CreateTaskModal.composition_input_edit_title')}
            onClick={() => setIsEditing(!isEditing)}
          >
            <PenIcon size={size('large')} color={colors('grey', 600)} />
          </CardIconButton>
          <CardIconButton title={t('CreateTaskModal.composition_input_delete_title')} onClick={onReset}>
            <TrashIcon size={size('large')} color={colors('grey', 600)} />
          </CardIconButton>
        </CardHeader>
        <CardBody>
          <CardSubtitle>{formatMixtureFunction(mixture)}</CardSubtitle>
          <CardGrid>
            <div />
            <CardText bold right>
              {t('CreateTaskModal.composition_batch_number_placeholder')}
            </CardText>
            <div />
            <CardText bold right>
              Dose
            </CardText>
            <div />
            <div />

            {mixture.product_usages
              .toSorted((a, b) => sortAlphaBeta(a.product_name, b.product_name))
              .map((usage) => (
                <MixtureUsageRow
                  key={usage.product_usage_id}
                  usage={{
                    ...usage,
                    dose: doses?.[usage.product_usage_id] || usage.dose,
                    batch_number:
                      batchNumbers?.[usage.product_usage_id] === undefined
                        ? usage.batch_number
                        : batchNumbers?.[usage.product_usage_id],
                  }}
                  dose={internalDoses[usage.product_usage_id] || usage.dose}
                  isEditing={isEditing}
                  onChange={(dose) =>
                    setInternalDoses({ ...internalDoses, [usage.product_usage_id]: dose || undefined })
                  }
                  batchNumber={
                    internalBatchNumbers[usage.product_usage_id] === undefined
                      ? usage.batch_number
                      : internalBatchNumbers[usage.product_usage_id]
                  }
                  onBatchNumberChange={(batchNumber) => {
                    return setInternalBatchNumbers({
                      ...internalBatchNumbers,
                      [usage.product_usage_id]: batchNumber,
                    });
                  }}
                />
              ))}
          </CardGrid>
        </CardBody>
        <CardButtonRow>
          {isEditing ? <Button success title={t('Commons.validate')} onClick={onSave} /> : null}
        </CardButtonRow>
      </Card>
      {isEditing ? (
        <InformationText>{t('CreateTaskModal.mixture_input_message_edition')}</InformationText>
      ) : (
        <>
          {usageErrors ? (
            <InformationText error>{t('CreateTaskModal.mixture_input_message_invalid')}</InformationText>
          ) : null}
          {productMixError ? (
            <InformationText error>{t('CreateTaskModal.mixture_input_message_invalid_product_mix')}</InformationText>
          ) : null}
        </>
      )}
    </FormComponent>
  );
}

const Recap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSize('xSmall')};
`;

const RecapInfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('small')};
  margin-left: ${pixelSpacing('xSmall')};
`;

const RecapText = styled.p`
  font-size: ${pixelSize('regular')};
  margin: 0;
`;

export const StepOneRecap = connect((state, props) => ({
  clientCluster: state.clusters.clusterById[props.task.clientClusterId],
}))(function ({ task, clientCluster }) {
  const { t } = useContext(I18nContext);
  const { units } = useContext(UIContext);

  const parcels = task.parcels?.filter((p) => !p.ignored);

  return (
    <Recap>
      <RecapInfoRow>
        <ClusterIcon size={13} />
        <RecapText>{clientCluster.name}</RecapText>
      </RecapInfoRow>
      {task.fromDate && task.toDate ? (
        <RecapInfoRow>
          <CalendarIcon size={13} />
          <RecapText>
            {task.fromDate.isSame(task.toDate, 'day')
              ? task.fromDate.format?.('D MMMM Y')
              : `${task.fromDate.format?.('D MMMM Y')} - ${task.toDate.format?.('D MMMM Y')}`}
          </RecapText>
        </RecapInfoRow>
      ) : null}
      {parcels?.length > 0 ? (
        <RecapInfoRow>
          <PlotsIcon size={13} />
          <RecapText>
            {parcels.length === 1
              ? t('CreateTaskModal.recap_parcel_count_one')
              : t('CreateTaskModal.recap_parcel_count', { nb: parcels.length })}{' '}
            -{' '}
            {formatArea(
              parcels.reduce((prev, parcel) => prev + parcel.computedArea, 0),
              units.area,
              true,
            )}
          </RecapText>
        </RecapInfoRow>
      ) : null}
    </Recap>
  );
});

export const ModalSeparator = styled.hr`
  height: 1px;
  width: calc(100% + ${pixelSpacing('large')});
  border: none;
  background-color: ${colors('grey', 200)};
  position: relative;
  left: -${pixelSpacing('regular')};
  margin: 0;
  margin-top: -${pixelSpacing('regular')};
`;

const WorksiteGroupCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  gap: ${pixelSpacing('small')};
  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
          :hover {
            border-color: ${colors('green')};
          }
        `
      : null}
`;

const WorksiteGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('small')};
  flex-grow: 1;
`;

const WorksiteGroupCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${pixelSpacing('small')};
`;

const PaddedWorksiteGroupCardRow = styled(WorksiteGroupCardRow)`
  padding-left: ${pixelSpacing('regular')};
  ${({ both }) =>
    both
      ? css`
          padding-right: ${pixelSpacing('regular')};
        `
      : null}
`;

const WorksiteGroupCardHeader = styled.p`
  margin: 0;
  font-size: ${pixelSize('large')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WorksiteGroupActionIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    rotate: -0.25turn;
  }
`;

const WorksiteGroupRecap = connect((state) => ({
  clustersById: state.clusters.clusterById,
}))(function ({ clustersById, worksiteGroup, showWork }) {
  const { t } = useContext(I18nContext);
  const { units } = useContext(UIContext);

  const cluster = clustersById[worksiteGroup.clusterId];
  const { fromDate, toDate, parcels } = worksiteGroup;
  const equipmentLabel = worksiteGroup.equipment?.label || '-';

  const anonymousWork = parcels.reduce((p, c) => p + (c.cluster_id ? 0 : c.computedArea), 0);
  const attributedWork = parcels.reduce((p, c) => p + (c.cluster_id ? c.computedArea : 0), 0);

  return (
    <WorksiteGroupColumn>
      <WorksiteGroupCardRow>
        <WorksiteGroupCardHeader title={equipmentLabel}>{equipmentLabel}</WorksiteGroupCardHeader>
      </WorksiteGroupCardRow>
      <WorksiteGroupCardRow>
        <CardText>
          {fromDate.format('DD/MM/YYYY, HH[h]mm')} -
          {toDate.format(`${fromDate.day() !== toDate.day() ? ' DD/MM/YYYY, ' : ' '}HH[h]mm`)}
        </CardText>
      </WorksiteGroupCardRow>
      {showWork ? (
        <>
          {attributedWork > 0 ? (
            <PaddedWorksiteGroupCardRow>
              <BlackCardText title={cluster?.name}>{cluster?.name}</BlackCardText>
              <BlackCardText>{formatArea(attributedWork, units.area, true)}</BlackCardText>
            </PaddedWorksiteGroupCardRow>
          ) : null}
          {anonymousWork > 0 ? (
            <PaddedWorksiteGroupCardRow>
              <BlackCardText>{t('CreateTaskModal.interventions_anonymous_work')}</BlackCardText>
              <BlackCardText>{formatArea(anonymousWork, units.area, true)}</BlackCardText>
            </PaddedWorksiteGroupCardRow>
          ) : null}
        </>
      ) : null}
    </WorksiteGroupColumn>
  );
});

export function WorksiteGroupCard({ worksiteGroup, onSelect }) {
  return (
    <WorksiteGroupCardContainer onClick={() => onSelect(worksiteGroup)}>
      <WorksiteGroupRecap worksiteGroup={worksiteGroup} showWork />
      <WorksiteGroupActionIcon>
        <ChevronIcon size={24} />
      </WorksiteGroupActionIcon>
    </WorksiteGroupCardContainer>
  );
}

const ParcelRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 90px 90px 25px;
  margin-bottom: ${({ hasError }) => (hasError ? pixelSpacing('small') : 0)};
  align-items: center;
  color: ${colors('grey')};
  font-size: ${pixelSize('regular')};
  gap: ${pixelSpacing('small')};

  .parcel-name {
    transition: color ${msDuration('short')} linear;
  }
  :hover .parcel-name {
    color: ${colors('black')};
  }

  :hover .remove-parcel-button {
    opacity: 1;
  }

  // error message
  span:last-of-type {
    white-space: nowrap;
    left: unset;
    right: 0;
  }
`;

const InputWithUnit = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('small')};
  input {
    text-align: right;
  }
`;

const ParcelLeftColumn = styled.div`
  display: flex;
  gap: ${pixelSpacing('small')};
  align-items: center;
  grid-column: ${({ twoCols }) => (twoCols ? '1 / 3' : '1 / 1')};
  min-width: 0;
  svg {
    flex-shrink: 0;
  }
`;

const ParcelLeftColumnText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const ReadonlyParcelSize = styled.span`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('xSmall')};
  height: ${pixelSpacing('large')};
`;

const ToggleParcelButton = styled.button.attrs({ className: 'remove-parcel-button' })`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  opacity: ${({ ignored }) => (ignored ? 1 : 0)};
  transition: opacity ${msDuration('short')} linear;
  :hover > div {
    background-color: ${({ ignored }) => (ignored ? colors('green') : colors('red'))};
    ${({ ignored }) =>
      ignored
        ? css`
            line {
              stroke: ${colors('white')};
            }
          `
        : null}
  }
`;

function ParcelWorkRefinement({ parcel, onComputedAreaChange, onHover, toggleParcel, ignored }) {
  const { t } = useContext(I18nContext);
  const { units } = useContext(UIContext);

  const [value, setValue] = useState(formatArea(parcel.computedArea, units.area));
  const [error, setError] = useState(null);

  const onValueChange = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      setValue(value);
      const isNumberError = isNumber(value);
      if (value === '' || Number(value) === 0) {
        setError(
          `${t('CreateTaskModal.interventions_input_parcel_error_required')} ${formatArea(
            parcel.originalComputedArea || parcel.computedArea,
            units.area,
            true,
          )}`,
        );
      } else if (isNumberError) {
        setError(isNumberError);
      } else {
        setError(null);
      }
      onComputedAreaChange(value ? convertAreaToSquaredMeters(Number(value), units.area) : 0);
    },
    [onComputedAreaChange, parcel.computedArea, parcel.originalComputedArea, t, units.area],
  );

  return (
    <ParcelRow hasError={!!error} onMouseEnter={() => onHover(parcel.id)} onMouseLeave={() => onHover(null)}>
      <ParcelLeftColumn title={`${parcel.name} (${formatArea(parcel.area, units.area, true)})`}>
        <ParcelThumbnail parcel={parcel} size={32} />
        <ParcelLeftColumnText>
          <span className="parcel-name">{parcel.name}</span> ({formatArea(parcel.area, units.area, true)})
        </ParcelLeftColumnText>
      </ParcelLeftColumn>
      <ReadonlyParcelSize>
        {parcel.worksiteGroupId && parcel.originalComputedArea ? (
          <>
            <KarnottIcon color={colors('grey')} size={18} />
            {formatArea(parcel.originalComputedArea, units.area, true)}
          </>
        ) : null}
      </ReadonlyParcelSize>
      {onComputedAreaChange ? (
        <InputWithUnit>
          <Input
            value={value}
            onValueChange={onValueChange}
            placeholder={t('CreateTaskModal.parcels_input_area_label')}
            error={error}
          />
          <span>{getAreaUnit(units.area)}</span>
        </InputWithUnit>
      ) : ignored ? null : (
        <ReadonlyParcelSize>
          {value} {getAreaUnit(units.area)}
        </ReadonlyParcelSize>
      )}
      {toggleParcel ? (
        <ToggleParcelButton
          ignored={ignored}
          onClick={() => {
            toggleParcel();
            onHover(null);
          }}
        >
          {ignored ? (
            <AddIcon size={15} color={colors('green')} />
          ) : (
            <DeleteIcon circled size={15} color={colors('white')} backgroundColor={colors('black')} />
          )}
        </ToggleParcelButton>
      ) : null}
    </ParcelRow>
  );
}

const WorksiteGroupParcelsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('regular')};
`;

const WorksiteGroupWorkDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('small')};
`;

const WorksiteGroupParcelGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('xSmall')};
`;

const WorksiteGroupParcelList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${pixelSpacing()};
`;

const SelectedParcelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('small')};
`;

function useParcelAreaOverride({ parcels, onChange }) {
  const parcelAreaOverride = useRef({});
  const getParcelsWithOverride = useCallback(
    () =>
      (parcels || []).map((parcel) => ({
        ...parcel,
        computedArea:
          parcel.id in parcelAreaOverride.current ? parcelAreaOverride.current[parcel.id] : parcel.computedArea,
      })),
    [parcels],
  );

  const onComputedAreaChange = useCallback(
    (parcel, area) => {
      parcelAreaOverride.current = { ...parcelAreaOverride.current, [parcel.id]: area };
      onChange(getParcelsWithOverride());
    },
    [onChange, getParcelsWithOverride],
  );

  return { onComputedAreaChange };
}

function WorksiteGroupParcelRow({ parcel, onHover }) {
  const { units } = useContext(UIContext);

  return (
    <ParcelRow onMouseEnter={() => onHover(parcel.id)} onMouseLeave={() => onHover(null)}>
      <ParcelLeftColumn title={`${parcel.name} (${formatArea(parcel.area, units.area, true)})`}>
        <ParcelThumbnail parcel={parcel} size={26} />
        <ParcelLeftColumnText>
          <span className="parcel-name">{parcel.name}</span> ({formatArea(parcel.area, units.area, true)})
        </ParcelLeftColumnText>
      </ParcelLeftColumn>
      <ReadonlyParcelSize style={{ gridColumn: '3 / 5', justifySelf: 'flex-end' }}>
        {formatArea(parcel.computedArea, units.area, true)}
      </ReadonlyParcelSize>
    </ParcelRow>
  );
}

export const WorksiteGroupDetailCard = connect((state) => ({
  clustersById: state.clusters.clusterById,
}))(({ worksiteGroup, onHover, clustersById }) => {
  const { t } = useContext(I18nContext);
  const { units } = useContext(UIContext);

  const cluster = clustersById[worksiteGroup.clusterId];

  const parcels = worksiteGroup.parcels;
  const anonymousParcels = parcels.filter((p) => !p.cluster_id);
  const attributedParcels = parcels.filter((p) => !!p.cluster_id);

  const anonymousWork = anonymousParcels.reduce((p, c) => p + c.computedArea, 0);
  const attributedWork = attributedParcels.reduce((p, c) => p + c.computedArea, 0);

  return (
    <WorksiteGroupCardContainer>
      <WorksiteGroupParcelsContainer>
        <WorksiteGroupRecap worksiteGroup={worksiteGroup} />
        <WorksiteGroupWorkDetail>
          {attributedWork > 0 ? (
            <WorksiteGroupParcelGroup>
              <PaddedWorksiteGroupCardRow both>
                <BlackCardText bold title={cluster?.name}>
                  {cluster?.name}
                </BlackCardText>
                <BlackCardText bold>{formatArea(attributedWork, units.area, true)}</BlackCardText>
              </PaddedWorksiteGroupCardRow>
              <WorksiteGroupParcelList>
                {attributedParcels.map((parcel) => (
                  <WorksiteGroupParcelRow key={parcel.id} parcel={parcel} onHover={onHover} />
                ))}
              </WorksiteGroupParcelList>
            </WorksiteGroupParcelGroup>
          ) : null}
          {anonymousWork > 0 ? (
            <WorksiteGroupParcelGroup>
              <PaddedWorksiteGroupCardRow both>
                <BlackCardText bold>{t('CreateTaskModal.interventions_anonymous_work')}</BlackCardText>
                <BlackCardText bold>{formatArea(anonymousWork, units.area, true)}</BlackCardText>
              </PaddedWorksiteGroupCardRow>
              <WorksiteGroupParcelList>
                {anonymousParcels.map((parcel) => (
                  <WorksiteGroupParcelRow key={parcel.id} parcel={parcel} onHover={onHover} />
                ))}
              </WorksiteGroupParcelList>
            </WorksiteGroupParcelGroup>
          ) : null}
        </WorksiteGroupWorkDetail>
      </WorksiteGroupParcelsContainer>
    </WorksiteGroupCardContainer>
  );
});

export const SlidingPane = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  transition:
    translate ${msDuration('long')} ease-in-out,
    left ${msDuration('long')} ease-in-out;
  padding: ${pixelSpacing('medium')} ${pixelSpacing('small')};
  height: var(--list-height);
  overflow: hidden;

  .sliding-pane-label {
    padding-bottom: ${pixelSpacing('xSmall')};
  }
`;

export const SlidingPanes = styled.div.attrs(() => ({ 'data-qa': 'parcel-list-container' }))`
  background-color: ${colors('grey', 200)};
  border: 1px solid ${({ error }) => (error ? colors('red', 400) : 'transparent')};
  border-radius: 7px;
  --list-height: 300px;
  height: var(--list-height);
  overflow: hidden;

  .scroll-container {
    padding-bottom: 70px;
    height: 100%;
    overflow-y: auto;
  }

  --gutter: ${pixelSpacing('regular')};
  position: relative;
  > div:nth-child(1) {
    left: ${({ showPane }) => (showPane === 1 ? 0 : '-100%')};
    translate: ${({ showPane }) => (showPane === 1 ? 0 : 'calc(-1 * var(--gutter))')} 0;
  }
  > div:nth-child(2) {
    left: ${({ showPane }) => (showPane === 2 ? 0 : '100%')};
    translate: ${({ showPane }) => (showPane === 2 ? 0 : 'var(--gutter)')} 0;
  }
`;

const ParcelsRefinementCard = styled(Card)`
  border-color: ${({ error }) => (error ? colors('red', 400) : colors('grey', 300))};
`;

const ParcelHeaderRow = styled(ParcelRow)`
  margin-bottom: ${pixelSpacing('small')};
  font-weight: bold;
  color: ${colors('black')};
`;

const ParcelFooterRow = styled(ParcelRow)`
  color: ${colors('black')};
  padding: ${pixelSpacing('xSmall')} 0;
`;

const AreaHeader = styled.span`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('xSmall')};
  padding: 0;
  border: none;
  font-family: ${fontFamily()};
  font-size: ${pixelSize('small')};
  font-weight: normal;
`;

const TotalArea = styled.p`
  margin: 0;
  font-weight: bold;
  grid-column: 3;
  justify-self: flex-end;
  display: flex;
  gap: ${spacing('regular') - 1}px;
`;

function ParcelsFromWorksiteGroupList({ parcels = [], unignoreParcels, onParcelHover }) {
  const { t } = useContext(I18nContext);

  const sortedParcels = useMemo(() => parcels.sort(sortByPosition), [parcels]);

  const addParcel = (id) => {
    unignoreParcels([id]);
  };

  const addAllParcels = () => {
    unignoreParcels(parcels.map((p) => p.id));
  };

  return parcels.length ? (
    <ParcelsRefinementCard>
      <ParcelHeaderRow>
        <ParcelLeftColumn bold twoCols>
          {t('CreateTaskModal.parcels_input_karnott_label')}
        </ParcelLeftColumn>
        <AreaHeader as="button" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={addAllParcels}>
          <AddIcon size={12} />
          <span>{t('CreateTaskModal.parcels_input_karnott_add_all_label')}</span>
        </AreaHeader>
      </ParcelHeaderRow>
      <SelectedParcelList>
        {sortedParcels.map((parcel) => (
          <ParcelWorkRefinement
            key={parcel.id}
            parcel={parcel}
            ignored
            onHover={onParcelHover}
            toggleParcel={() => addParcel(parcel.id)}
          />
        ))}
      </SelectedParcelList>
    </ParcelsRefinementCard>
  ) : null;
}

function ParcelsRefinementList({ parcels = [], removeParcel, onAreaChange, onParcelHover }) {
  const { t } = useContext(I18nContext);
  const { units } = useContext(UIContext);

  const sortedParcels = useMemo(() => parcels.sort(sortByPosition), [parcels]);
  const { onComputedAreaChange } = useParcelAreaOverride({ parcels, onChange: onAreaChange });
  const totalComputedArea = useMemo(() => sortedParcels.reduce((p, c) => p + c.computedArea, 0), [sortedParcels]);

  return parcels.length ? (
    <ParcelsRefinementCard>
      <ParcelHeaderRow>
        <ParcelLeftColumn bold>{t('CreateTaskModal.parcels_input_parcels_label')}</ParcelLeftColumn>
        <AreaHeader>
          {parcels.find((p) => p.worksiteGroupId) ? t('CreateTaskModal.parcels_input_karnott_area_label') : ''}
        </AreaHeader>
        <AreaHeader>{t('CreateTaskModal.parcels_input_area_label')}</AreaHeader>
      </ParcelHeaderRow>
      <SelectedParcelList>
        {sortedParcels.map((parcel) => (
          <ParcelWorkRefinement
            key={parcel.id}
            parcel={parcel}
            onComputedAreaChange={(area) => onComputedAreaChange(parcel, area)}
            onHover={onParcelHover}
            toggleParcel={() => removeParcel(parcel.id)}
          />
        ))}
        <ParcelFooterRow>
          <ParcelLeftColumn bold>{t('Commons.total')}</ParcelLeftColumn>
          <TotalArea>
            <span>{formatArea(totalComputedArea, units.area)}</span>
            <span>{getAreaUnit(units.area)}</span>
          </TotalArea>
        </ParcelFooterRow>
      </SelectedParcelList>
    </ParcelsRefinementCard>
  ) : null;
}

export function ParcelLists({ parcels, onChange, onParcelHover }) {
  const ignoredParcels = parcels.filter((p) => p.ignored);
  const unignoredParcels = parcels.filter((p) => !p.ignored);

  const unignoreParcels = (parcelIds) => {
    const unignoredParcels = parcels.filter((p) => parcelIds.find((i) => i === p.id));
    const sameParcels = parcels.filter((p) => !parcelIds.find((i) => i === p.id));
    onChange([...sameParcels, ...unignoredParcels.map((p) => ({ ...p, ignored: false }))]);
  };

  const removeParcel = (parcelId) => {
    const removedParcel = parcels.filter((p) => parcelId === p.id).at(0);
    const sameParcels = parcels.filter((p) => parcelId !== p.id);
    if (removedParcel.worksiteGroupId) {
      // ignoring parcel from worksiteGroup
      onChange([...sameParcels, { ...removedParcel, ignored: true }]);
    } else {
      // deleting parcel from selection
      onChange(sameParcels);
    }
  };

  const onAreaChange = (parcelsAfterAreaChange) => {
    const sameParcels = parcels.filter((p) => !parcelsAfterAreaChange.find((a) => p.id === a.id));
    onChange([...sameParcels, ...parcelsAfterAreaChange]);
  };

  return (
    <>
      <ParcelsFromWorksiteGroupList
        parcels={ignoredParcels}
        unignoreParcels={unignoreParcels}
        onParcelHover={onParcelHover}
      />
      <ParcelsRefinementList
        parcels={unignoredParcels}
        removeParcel={removeParcel}
        onAreaChange={onAreaChange}
        onParcelHover={onParcelHover}
      />
    </>
  );
}

const mapParcelMapToProps = (state) => ({
  parcelsById: state.parcels.parcelById,
});

const mapParcelMapDispatchToProps = (dispatch) => ({
  fetchParcel: (id) => dispatch(fetchParcel(id)),
});

export const ParcelMap = connect(
  mapParcelMapToProps,
  mapParcelMapDispatchToProps,
)(function ({
  clusterId,
  fetchParcel,
  parcelsById,
  parcels,
  selectedParcels,
  hoveredParcelId,
  toggleParcel,
  worksiteGroupIds,
}) {
  const { t } = useContext(I18nContext);
  const [tracks, setTracks] = useState();

  // join parcels with selected parcels, to display on map
  const parcelsOnMap = useMemo(
    () =>
      clusterId && parcels
        ? parcels
            .concat(selectedParcels?.filter((s) => !parcels.find((p) => p.id === s.id)) || [])
            .map((p) => parcelsById[p.id])
            .filter(Boolean)
        : [],
    [clusterId, parcels, parcelsById, selectedParcels],
  );

  const idsOfParcelOnMap = useMemo(
    () =>
      (clusterId && parcels
        ? parcels.concat(selectedParcels?.filter((s) => !parcels.find((p) => p.id === s.id)) || [])
        : []
      )
        .map((p) => p.id)
        .sort()
        .join(','),
    [clusterId, parcels, selectedParcels],
  );

  const hasArrayOfWorksiteGroups = Array.isArray(worksiteGroupIds) && worksiteGroupIds.length > 0;
  const hasOneWorksiteGroup = !Array.isArray(worksiteGroupIds) && !!worksiteGroupIds;

  useEffect(() => {
    if (idsOfParcelOnMap && (hasOneWorksiteGroup || hasArrayOfWorksiteGroups)) {
      // fetch worksiteGroup tracks
      const params = new URLSearchParams();
      if (hasArrayOfWorksiteGroups) {
        worksiteGroupIds.forEach((worksiteGroupId) => {
          params.append('device_session_id', worksiteGroupId);
        });
      } else if (hasOneWorksiteGroup) {
        params.append('device_session_id', worksiteGroupIds);
      }

      idsOfParcelOnMap.split(',').forEach((parcelId) => {
        params.append('parcel_id', parcelId);
      });

      getParcelsWorksitesTracks(params.toString()).then(setTracks);
    }
  }, [hasArrayOfWorksiteGroups, hasOneWorksiteGroup, idsOfParcelOnMap, worksiteGroupIds]);

  // fetch missing parcels
  const fetchedParcels = useRef({});
  useEffect(() => {
    const parcelsToFetch = (
      selectedParcels?.filter((s) => !fetchedParcels.current[s.id] && !parcels.find((p) => p.id === s.id)) || []
    )
      .concat(parcels)
      .filter((p) => !parcelsById[p.id]);

    parcelsToFetch.forEach((p) => {
      fetchParcel(p.id);
      fetchedParcels.current[p.id] = true;
    });
  }, [fetchParcel, parcels, parcelsById, selectedParcels]);

  const hoveredParcelStyleOverride = useParcelsOverrideStyle({
    focusedIDs: [hoveredParcelId?.toString()].filter(Boolean),
  });
  const selectedParcelsStyleOverride = useSelectedParcelsFocusedStyle(
    selectedParcels?.map((p) => p.id?.toString()).filter(Boolean) || [],
  );

  const [layers, toggleLayer] = useLayerFilters({
    initVal: {
      parcelsName: !localStorage.getItem(localStorageKeys.parcelsName)
        ? true
        : localStorage.getItem(localStorageKeys.parcelsName) === 'true',
    },
  });

  const { toggleParcelNames } = useContext(UIContext);

  const toggleLayerCB = useCallback(() => {
    toggleLayer('parcelsName');
    toggleParcelNames();
  }, [toggleLayer, toggleParcelNames]);

  const mapControls = useMemo(
    () => [
      <Controls
        key="controls"
        hideFullScreen
        hideToggleMarkerCluster
        moreControls={
          <Button
            Icon={ShowLabelIcon}
            onClick={toggleLayerCB}
            primary
            message={t('Controls.parcel_names')}
            outlinedNeutral
            tooltipOrientation="left"
            noWrap
            outlined={layers.parcelsName}
            badge={layers.parcelsName}
          />
        }
      />,
    ],
    [layers.parcelsName, t, toggleLayerCB],
  );

  return (
    <IsolatedMap
      id="task-map"
      tracks={hasArrayOfWorksiteGroups || hasOneWorksiteGroup ? tracks : undefined}
      tracksStyleOverrides={inertFeatures()}
      parcels={parcelsOnMap}
      overlays={mapControls}
      parcelsStyleOverrides={selectedParcelsStyleOverride.concat(hoveredParcelStyleOverride)}
      parcelsEvents={{ click: toggleParcel }}
      fitMask={hasArrayOfWorksiteGroups || hasOneWorksiteGroup ? 'tracks' : 'parcel'}
    />
  );
});

export const ContainerWithGaps = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('medium')};
`;

function WorksiteGroupRecapCard({ worksiteGroup, onDelete }) {
  const { t } = useContext(I18nContext);

  const { equipment, fromDate, toDate } = worksiteGroup;

  return (
    <Card style={{ backgroundColor: colors('grey', 200) }}>
      <CardHeader>
        <InterventionDetailsIcon
          size={size('xLarge')}
          circled
          backgroundColor={colors('black', 600)}
          color={colors('white')}
        />
        <CardTitle>
          {t('CreateTaskModal.interventions_button_selected_label', {
            equipment: equipment?.label,
            day: fromDate.format('DD/MM/YY'),
          })}
        </CardTitle>
        <CardIconButton title={t('CreateTaskModal.interventions_button_delete_title')} onClick={onDelete}>
          <TrashIcon size={size('large')} color={colors('grey', 600)} />
        </CardIconButton>
      </CardHeader>
      <CardBody>
        <CardSubtitle>
          {t('Commons.time_range', { from_time: fromDate.format('HH[h]mm'), to_time: toDate.format('HH[h]mm') })}
        </CardSubtitle>
      </CardBody>
    </Card>
  );
}

export const CenteredButtonWrapper = styled.div`
  position: relative;
  align-self: center;
`;

export function OpenWorksiteGroupSelectionButton({ worksiteGroups, loading, onClick, onDelete, disabled }) {
  const { t } = useContext(I18nContext);

  return (
    <>
      {(worksiteGroups || [])
        .sort((a, b) => a.fromDate.format('X') - b.fromDate.format('X'))
        .map((worksiteGroup) => (
          <WorksiteGroupRecapCard
            key={worksiteGroup.id}
            worksiteGroup={worksiteGroup}
            onDelete={() => onDelete(worksiteGroup)}
          />
        ))}
      <CenteredButtonWrapper>
        <Button
          disabled={disabled}
          primary={!disabled}
          title={
            loading
              ? t('CreateTaskModal.interventions_button_loading_label')
              : disabled
                ? worksiteGroups?.length
                  ? t('CreateTaskModal.interventions_button_no_more_label')
                  : t('CreateTaskModal.interventions_button_empty_label')
                : worksiteGroups?.length
                  ? t('CreateTaskModal.interventions_button_add_label')
                  : t('CreateTaskModal.interventions_button_label')
          }
          onClick={onClick}
        />
      </CenteredButtonWrapper>
    </>
  );
}

export const ButtonRow = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${colors('grey', 200)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pixelSpacing('small')};
`;

const openMapAnimation = keyframes`
  from {
    height: 0;
    width: 0;
    left: calc((100vw - 550px) / 2);
    top: 50vh;
  }
`;

export const MapContainer = styled.div`
  --map-padding: 30px;
  animation: ${openMapAnimation} ${msDuration('long')} ease-in-out;
  display: flex;
  flex: 1;
  position: fixed;
  height: calc(100vh - calc(var(--map-padding) * 2));
  width: calc(100vw - 550px - calc(var(--map-padding) * 2));
  left: var(--map-padding);
  top: var(--map-padding);
`;

export const LinkButton = styled(Label).attrs({ as: 'button' })`
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 0;
  padding: ${spacing('small') + 3}px;
  padding-left: 0;
`;

export const Collapsible = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('regular')};
  color: ${colors('black')};
`;

const CollapsibleOptionContainer = styled.div`
  background: ${colors('white')};
  border-radius: 5px;
  overflow: hidden;
  padding: ${pixelSpacing('small')} ${pixelSpacing('medium')};
  display: flex;
  flex-direction: column;
`;

const CollapsibleTitle = styled.button`
  all: unset;
  font-size: ${pixelSize('large')};
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const CollapsibleIcon = styled.div`
  transition: rotate ${msDuration('long')} cubic-bezier(0.6, 0, 0.4, 1);
  rotate: 0;
  &[data-open='true'] {
    rotate: 180deg;
  }
`;

const CollapsibleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('xSmall')};
  padding: 0 ${pixelSpacing('medium')};
  overflow: hidden;
  transition: height ${msDuration('long')} cubic-bezier(0.6, 0, 0.4, 1);
  height: 0;
  &[data-open='true'] {
    height: auto;
  }
  > * {
    margin: ${pixelSpacing('medium')} 0;
  }
`;

export function CollapsibleOption({ title, children, open, onToggle }) {
  const collapsibleRef = useRef(null);

  useEffect(() => {
    if (collapsibleRef.current) {
      collapsibleRef.current.style.height = open ? collapsibleRef.current.scrollHeight + 'px' : 0;
    }
  }, [open]);

  return (
    <CollapsibleOptionContainer>
      <CollapsibleTitle onClick={onToggle}>
        <span>{title}</span>
        <CollapsibleIcon data-open={open}>
          <ChevronIcon color={colors('grey', 600)} />
        </CollapsibleIcon>
      </CollapsibleTitle>
      <CollapsibleContent ref={collapsibleRef} data-open={open}>
        {children}
      </CollapsibleContent>
    </CollapsibleOptionContainer>
  );
}

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('small')};
  .question-title {
    color: ${colors('black', 400)};
    font-size: ${pixelSize()};
    margin: 0;
    + div {
      padding: 0 ${pixelSpacing('small')};
    }
  }
  span::first-letter {
    text-transform: uppercase;
  }
`;

export const RelatedTasksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('medium')};
`;

const RelatedTaskCardClickableWrapper = styled.div`
  padding: ${pixelSpacing('medium')} ${pixelSpacing('small')};
  background-color: ${colors('white')};
  border-radius: 4px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  color: ${colors('black')};
  display: flex;
  gap: ${pixelSpacing('medium')};
  align-items: flex-start;
  transition: ${msDuration('short')} border-color ease-in-out;
  border: 1px solid ${({ checked }) => (checked ? colors('green') : 'transparent')};
  transition: box-shadow ${msDuration('regular')} ease-in-out;
  ${({ checked }) =>
    checked
      ? css`
          ${elevation({ elevated: true })}
        `
      : null}

  ${({ onClick }) =>
    onClick
      ? css`
          :hover {
            ${elevation({ elevated: true })}
          }
        `
      : null}
`;

const RelatedTaskCardRadioWrapper = styled.div`
  margin-top: 7px;
`;

const RelatedTaskCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelSpacing('xSmall')};
  flex: 1;
  min-width: 0;
`;

const RelatedTaskCardTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('small')};
`;

const RelatedTaskCardTitle = styled.h3`
  font-weight: normal;
  margin: 0;
  font-size: ${pixelSize('large')};
`;

const RelatedTaskCardItemRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('small')};
  justify-content: space-between;
  padding-left: 39px;
`;

const RelatedTaskCardItemWrapper = styled.div`
  display: flex;
  gap: ${pixelSpacing('medium')};
  min-width: 0;
`;

const RelatedTaskCardLabel = styled.p`
  margin: 0;
  font-size: 13px;
  color: ${colors('grey', 400)};
  min-width: 70px;
`;

const RelatedTaskCardValue = styled.p`
  margin: 0;
  font-size: 13px;
  color: ${colors('black')};
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

function RelatedTaskCardItem({ label, value }) {
  return (
    <RelatedTaskCardItemWrapper title={value}>
      <RelatedTaskCardLabel>{label}</RelatedTaskCardLabel>
      <RelatedTaskCardValue>{value || '-'}</RelatedTaskCardValue>
    </RelatedTaskCardItemWrapper>
  );
}

export function RelatedTaskCard({ task, checked, onClick }) {
  const { t } = useContext(I18nContext);
  const { units } = useContext(UIContext);

  if (!task) return null;

  const TaskIcon = task.status === TASK_STATUS.TO_DO ? TaskTodoIcon : TaskCreateIcon;

  return (
    <RelatedTaskCardClickableWrapper onClick={onClick} checked={checked}>
      {onClick ? (
        <RelatedTaskCardRadioWrapper>
          <Radio checked={checked} onClick={() => {}} />
        </RelatedTaskCardRadioWrapper>
      ) : null}
      <RelatedTaskCardWrapper>
        <RelatedTaskCardTitleRow>
          <TaskIcon circled size={30} color={colors('white')} backgroundColor={colors('black')} />
          <RelatedTaskCardTitle>{task.mixture?.name || '-'}</RelatedTaskCardTitle>
        </RelatedTaskCardTitleRow>
        {task.status === TASK_STATUS.TO_DO ? (
          <>
            <RelatedTaskCardItemRow>
              <RelatedTaskCardItem
                label={t('TaskFromWorksiteModal.task_card_area_label')}
                value={formatArea(task.area, units.area, true)}
              />
              <RelatedTaskCardItem
                label={t('TaskFromWorksiteModal.task_card_parcels_label')}
                value={task.parcels?.length}
              />
            </RelatedTaskCardItemRow>
            <RelatedTaskCardItemRow>
              <RelatedTaskCardItem label={t('TaskFromWorksiteModal.task_card_client_label')} value={task.client_name} />
            </RelatedTaskCardItemRow>
            <RelatedTaskCardItemRow>
              <RelatedTaskCardItem
                label={t('TaskFromWorksiteModal.task_card_equipment_label')}
                value={(task.equipments || []).map((e) => e.label).join(', ')}
              />
            </RelatedTaskCardItemRow>
            <RelatedTaskCardItemRow>
              <RelatedTaskCardItem
                label={t('TaskFromWorksiteModal.task_card_operator_label')}
                value={(task.assigned_users || []).map(getUserName).join(', ')}
              />
            </RelatedTaskCardItemRow>
            <RelatedTaskCardItemRow>
              <RelatedTaskCardItem label={t('TaskFromWorksiteModal.task_card_description_label')} value={task.name} />
            </RelatedTaskCardItemRow>
          </>
        ) : null}
      </RelatedTaskCardWrapper>
    </RelatedTaskCardClickableWrapper>
  );
}
