import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const PenIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 19.121 19.121"
    >
      <g transform="translate(1 1.121)">
        <path
          d="M711.475,79.207l9.916-9.916a1,1,0,0,1,1.408,0L725.8,72.3a1,1,0,0,1,0,1.409l-9.915,9.916"
          transform="translate(-709.097 -68.999)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M711.475,79.207,709.1,86l6.793-2.377-1.675-1.675L712.468,80.2Z"
          transform="translate(-709.097 -68.999)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
