import moment from 'moment-timezone';
import { Locale } from '.';

export const loadMomentLocale = (locale: Locale) => {
  switch (locale) {
    case 'fr':
      // @ts-expect-error TS cannot find the locale modules
      import('moment/dist/locale/fr').then(() => {
        moment.locale('fr');
      });
      break;
    case 'de':
      // @ts-expect-error TS cannot find the locale modules
      import('moment/dist/locale/de').then(() => {
        moment.locale('de');
      });
      break;
    case 'nl':
      // @ts-expect-error TS cannot find the locale modules
      import('moment/dist/locale/nl').then(() => {
        moment.locale('nl');
      });
      break;
    case 'en':
      moment.locale('en');
      break;
    default: {
      const unhandledLang: never = locale;
      console.warn(`unhandled locale ${unhandledLang}`);
    }
  }
};
