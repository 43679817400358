import { useCallback, useState } from 'react';

function useButtonHover(): [hovered: boolean, onEnter: () => void, onLeave: () => void] {
  const [hovered, setHovered] = useState(false);

  const onEnter = useCallback(() => {
    setHovered(true);
  }, []);
  const onLeave = useCallback(() => {
    setHovered(false);
  }, []);

  return [hovered, onEnter, onLeave];
}

export const Effects = {
  useButtonHover,
};
