import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ZoomOutIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '35%' : '50%'}
      height={circled ? '35%' : '50%'}
      viewBox="0 0 11.5 1.5"
    >
      <g transform="translate(0.75 0.75)">
        <line x1="10" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  </CircledIcon>
);
