import merge from 'lodash-es/merge';
import { Actions } from '../actions';

const initialState = {
  isParcelSessionByIdsFetching: false,
  isEquipmentSessionByIdsFetching: false,
  parcelSessionById: {},
  parcelSessionWithTracksById: {},
  parcelSessionsIdsByParcelId: {},
  equipmentSessionById: {},
  equipmentSessionsIdsByEquipmentId: {},
  errorSharedSession: null,
  sharedSession: {},
  loadingSharedSession: false,
  driverSessionByDriverID: {},
  parcelsTracks: null,
  equipmentsTracks: null,
  driversTracks: null,
  driversTracksFetching: false,
  parcelWorksites: null,
  forcedOrIgnoredParcelsByDeviceSessionId: {},
};

const sessions = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case Actions.REQUEST_PARCEL_SESSIONS_BY_IDS:
      return {
        ...state,
        isParcelSessionByIdsFetching: true,
      };
    case Actions.RECEIVE_PARCEL_SESSIONS_BY_IDS:
      return {
        ...state,
        isParcelSessionByIdsFetching: false,
        parcelsTracks: payload,
      };
    case Actions.REQUEST_EQUIPMENT_SESSIONS_BY_IDS:
      return {
        ...state,
        isEquipmentSessionByIdsFetching: true,
      };
    case Actions.RECEIVE_EQUIPMENT_SESSIONS_BY_IDS:
      return {
        ...state,
        isEquipmentSessionByIdsFetching: false,
        equipmentsTracks: payload,
      };
    case Actions.PARCEL_SESSIONS_SUCCESS:
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          ...payload.data.entities.equipmentSession,
        },
        parcelSessionById: {
          ...state.parcelSessionById,
          ...payload.data.entities.parcelSession,
        },
        parcelSessionsIdsByParcelId: {
          ...state.parcelSessionsIdsByParcelId,
          [payload.id]: payload.data.result,
        },
      };
    case Actions.PARCEL_SESSION_POINTS_SUCCESS:
      return {
        ...state,
        parcelSessionById: {
          ...state.parcelSessionById,
          [payload.id]: {
            ...state.parcelSessionById[payload.id],
            points: payload.points,
          },
        },
      };
    case Actions.RECEIVE_PARCEL_SESSION_SUCCESS:
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          ...payload.data.entities.equipmentSession,
        },
        parcelSessionWithTracksById: {
          ...state.parcelSessionWithTracksById,
          ...payload.data.entities.parcelSession,
        },
        parcelSessionsIdsByParcelId: {
          ...state.parcelSessionsIdsByParcelId,
          [payload.id]: payload.data.result,
        },
      };
    case Actions.EQUIPMENT_SESSION_PARCEL_SESSIONS_SUCCESS:
      return {
        ...state,
        parcelSessionById: {
          ...state.parcelSessionById,
          ...payload.data.entities.parcelSession,
        },
      };
    case Actions.EQUIPMENT_SESSIONS_SUCCESS:
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          ...payload.data.entities.equipmentSession,
        },
        parcelSessionById: {
          ...state.parcelSessionById,
          ...payload.data.entities.parcelSession,
        },
        equipmentSessionsIdsByEquipmentId: {
          ...state.equipmentSessionsIdsByEquipmentId,
          [payload.id]: payload.data.result,
        },
      };
    case Actions.EQUIPMENT_SESSION_SUCCESS: {
      const parcelSessions = merge(state.parcelSessionById, payload.entities.parcelSession);
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          ...payload.entities.equipmentSession,
        },
        parcelSessionById: parcelSessions,
      };
    }
    case Actions.EQUIPMENT_SESSION_POINTS_SUCCESS: {
      const { id, points } = payload;
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          [id]: {
            ...state.equipmentSessionById[id],
            points,
          },
        },
      };
    }
    case Actions.NEW_CLUSTER_ID_FOR_SESSION: {
      const psAfterUpdate = merge(state.parcelSessionById, payload.entities.parcelSession);
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          [payload.result]: payload.entities.equipmentSession[payload.result],
        },
        parcelSessionById: psAfterUpdate,
      };
    }
    case Actions.REQUEST_SHARED_SESSION:
      return {
        ...state,
        loadingSharedSession: true,
      };
    case Actions.RECEIVE_SHARED_SESSION:
      return {
        ...state,
        sharedSession: payload,
        loadingSharedSession: false,
      };
    case Actions.UPDATED_SHARED_SESSION:
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          [payload.id]: {
            ...state.equipmentSessionById[payload.id],
            shareCounter: payload.share_counter,
            shareExpiration: payload.share_expiration,
            shareFull: payload.share_full,
            shareId: payload.share_id,
          },
        },
      };
    case Actions.FAIL_SHARED_SESSION: {
      let reason = null;
      if (error?.errors?.length) {
        reason = error.errors[0].reason;
      }
      return {
        ...state,
        loadingSharedSession: false,
        errorSharedSession: reason,
      };
    }
    case Actions.REPORTS_DRIVERS_REPORT_SUCCESS: {
      const driverSessionByDriverID = {};
      (payload || []).forEach((driverReport) => {
        if (!driverSessionByDriverID[driverReport.user_id]) {
          driverSessionByDriverID[driverReport.user_id] = [driverReport];
        } else {
          driverSessionByDriverID[driverReport.user_id] =
            driverSessionByDriverID[driverReport.user_id].concat(driverReport);
        }
      });
      return {
        ...state,
        driverSessionByDriverID: driverSessionByDriverID,
      };
    }
    case Actions.REPORTS_DRIVERS_TRACKS_REQUEST:
      return {
        ...state,
        driversTracksFetching: true,
      };
    case Actions.REPORTS_DRIVERS_TRACKS_SUCCESS:
      return {
        ...state,
        driversTracksFetching: false,
        driversTracks: payload,
      };
    case Actions.PARCEL_WORKSITES_SUCCESS:
      return {
        ...state,
        parcelWorksites: payload,
      };
    case Actions.GET_FORCED_OR_IGNORED_PARCELS_OF_DEVICE_SESSION_SUCCESS:
      return {
        ...state,
        forcedOrIgnoredParcelsByDeviceSessionId: {
          ...state.forcedOrIgnoredParcelsByDeviceSessionId,
          [payload.deviceSessionId]: payload.forcedOrIgnoredParcels,
        },
      };
    case Actions.UPDATE_EQUIPMENT_SESSION_STATUS:
      return {
        ...state,
        equipmentSessionById: {
          ...state.equipmentSessionById,
          [payload.sessionID]: {
            ...state.equipmentSessionById[payload.sessionID],
            processingStatus: payload.status,
          },
        },
      };
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default sessions;
