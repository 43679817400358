import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { EQUIPMENT_CONSTANTS } from '../../../../constants/equipmentConstants';
import { openLeafletPopup, openLeafletTooltip } from '../../../../utils/map';
import { equipmentTooltipData } from './effects';
import { EquipmentSessionPointTooltip, EquipmentTooltip } from './tooltip';

export function openEquipmentTooltip(equipmentProps, layer, labels) {
  const tooltip = <EquipmentTooltip {...{ equipmentProps, labels }} />;
  const popupOptions = {
    direction: 'top',
    offset: [-3, -38],
    closeButton: false,
    interactive: true,
    maxWidth: 500,
  };
  openLeafletPopup(layer, tooltip, popupOptions);
}

export function openEquipmentSessionPointTooltip(point, layer) {
  openLeafletTooltip(layer, <EquipmentSessionPointTooltip {...{ point }} />, {
    direction: 'top',
    offset: [0, 0],
    zIndex: 1000,
  });
}

export function useEquipmentEventsRef(labels, setOverTrackID) {
  const history = useHistory();

  return useRef({
    mouseover: (equipment, l, { iconMarker, backgroundMarker }) => {
      if (equipment?.activeSession) {
        setOverTrackID(equipment.activeSession.deviceSessionId);
      }
      const eqProps = equipmentTooltipData(equipment, history);
      openEquipmentTooltip(eqProps, iconMarker, labels);
      iconMarker.setZIndexOffset(10000);
      backgroundMarker.setZIndexOffset(10000);
    },
    mouseout: (e, l, { iconMarker, backgroundMarker }) => {
      if (e?.activeSession) {
        setOverTrackID(null);
      }
      iconMarker.setZIndexOffset(e.status === EQUIPMENT_CONSTANTS.EQUIPMENT_STATUS.INUSE ? 9000 : 0);
      backgroundMarker.setZIndexOffset(e.status === EQUIPMENT_CONSTANTS.EQUIPMENT_STATUS.INUSE ? 9000 : 0);
    },
    click: (e) => history?.push(`/equipments/${e.id}/`),
  });
}
