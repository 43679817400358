// Select part of global state with props context
export const getParams = (state, { match }) => match?.params;
export const getIdFromParams = (state, { match }) => (match?.params?.id ? match.params.id : null);
export const getBeacons = (state) => state.beacons;
export const getClusters = (state) => state.clusters;
export const getCrops = (state) => state.crops;
export const getDevices = (state) => state.devices;
export const getEquipments = (state) => state.equipments;
export const getFilters = (state) => state.filters;
export const getParcels = (state) => state.parcels;
export const getUser = (state) => state.user;
export const getUsers = (state) => state.user.usersByID;
export const getPartnerData = (state) => state.partner;
export const getWorksites = (state) => state.worksites;
export const getObservations = (state) => state.observations;
export const getTasks = (state) => state.tasks;
export const getTags = (state) => state.tags;

// ui
export const getActiveClusters = (state) => state.ui.selectedClusters;
export const getSearchFilter = (state) => state.ui.searchFilter;
export const getTitle = (state) => state.ui.title;
