import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ZoomInIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      width={circled ? '35%' : '50%'}
      height={circled ? '35%' : '50%'}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2)">
        <g clipPath="url(#clip1_1_2)">
          <path d="M43 23H3" stroke={color} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M23 43V3" stroke={color} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width="46" height="46" fill="white" />
        </clipPath>
        <clipPath>
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </CircledIcon>
);
