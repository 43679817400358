export const EditIcon = (props) => (
  <svg viewBox="0 0 42 42" {...props} fill={props.color} height={`${props.size || 42}`} width={`${props.size || 42}`}>
    <g>
      <path
        fill="none"
        stroke={props.color}
        d="M17.2,22.1l6.2-6.2c0.2-0.2,0.6-0.2,0.9,0l1.9,1.9c0.2,0.2,0.2,0.6,0,0.9l-6.2,6.2"
      />
      <polygon fill="none" stroke={props.color} points="17.2,22.1 15.7,26.3 19.9,24.8 18.9,23.8 17.8,22.7 	" />
    </g>
  </svg>
);
