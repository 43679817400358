import { Actions } from '../actions';

const initialState = {
  smag: {
    farms: [],
    fields: [],
  },
  mesparcelles: {
    farms: [],
    fields: [],
  },
};

const smag = (state = initialState, action) => {
  const { type, payload, partnerName } = action;

  const partnerState = state[partnerName];
  switch (type) {
    case Actions.PARTNER_FARMS_SYNC_SUCCESS: {
      const { farms } = partnerState;
      (payload || []).forEach((farm) => {
        farms[farm.id] = farm;
      });
      return {
        ...state,
        [partnerName]: {
          ...partnerState,
          farms: { ...farms },
        },
      };
    }
    case Actions.GET_SYNCED_PARTNER_FARMS_SUCCESS: {
      const allFarms = {};
      (payload || []).forEach((farm) => {
        allFarms[farm.id] = farm;
      });
      return {
        ...state,
        [partnerName]: {
          ...partnerState,
          farms: { ...allFarms },
        },
      };
    }
    case Actions.GET_PARTNER_CROP_ZONES_SUCCESS: {
      const fieldsGet = {};
      (payload || []).forEach((field) => {
        fieldsGet[field.id] = field;
      });
      return {
        ...state,
        [partnerName]: {
          ...partnerState,
          fields: { ...fieldsGet },
        },
      };
    }
    case Actions.PARTNER_CROP_ZONES_SYNC_SUCCESS: {
      const fieldsSync = {};
      (payload || []).forEach((field) => {
        fieldsSync[field.id] = field;
      });
      return {
        ...state,
        [partnerName]: {
          ...partnerState,
          fields: { ...fieldsSync },
        },
      };
    }
    case Actions.UPDATE_PARTNER_FARM_SUCCESS:
      return {
        ...state,
        [partnerName]: {
          ...partnerState,
          farms: { ...state.farms, [payload.id]: payload },
        },
      };
    case Actions.UPDATE_PARTNER_CROP_ZONE_SUCCESS:
      return {
        ...state,
        [partnerName]: {
          ...partnerState,
          fields: { ...state.fields, [payload.id]: payload },
        },
      };
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default smag;
