import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { fontFamily, zIndex as karnottSdkZIndex, pixelSpacing } from '@karnott/theme';
import { Link, List } from '.';
import { color, family, size, spacing } from './theme';

export const HeaderWrapper = styled.header`
  position: fixed;
  font-family: ${family('ui')};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  background-color: #fff;
  z-index: ${({ popUpMenuOpen }) => (popUpMenuOpen ? karnottSdkZIndex('sky', 1) : karnottSdkZIndex('menu', 1))};
  justify-content: space-between;
  box-shadow: rgb(223 230 237) 0px -1px 0px 0px inset;
`;

export const MainNav = styled(List)`
  flex-direction: row;
  display: flex;
  align-items: center;
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    transition: top 0.3s ease-in;
    display: flex;
    position: absolute;
    top: -604px;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
`;

export const ToggleContainer = styled.div`
  display: none;
  visibility: hidden;
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    height: 55px;
    visibility: visible;
    color: ${colors('grey', 'light')};
    font-size: ${size('xl')};
    display: flex;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
  }
`;

export const MainNavItem = styled.li`
  vertical-align: top;
  position: relative;
`;

export const MainNavLink = styled(Link)`
  text-transform: uppercase;
  display: block;
  padding: ${spacing}px;
  line-height: 1.75;
  color: ${colors('grey', 'light')};
  font-size: 0.841em;
  transition: color 150ms ease-out;

  &.active,
  &.active:hover {
    color: ${colors('green')};
    font-weight: bold;
    box-shadow: inset 0 4px 0 ${colors('green')};
  }

  &:hover {
    color: ${colors('green')};
  }

  &.MainNavLink--home {
    border-left: 1px solid ${color('smoke', 'light')};
    border-right: 1px solid ${color('smoke', 'light')};
    font-size: ${size('xl')};
    padding-top: ${spacing / 2}px;
    padding-bottom: ${spacing / 2}px;
  }
`;

export const RightHeaderSeparator = styled.div`
  width: ${pixelSpacing()};
  height: 1px;
`;

export const RightHeaderItem = styled.button`
  border: ${({ outlined }) => (outlined ? `1px solid ${colors('bleen')}` : 0)};
  border-radius: ${pixelSpacing('large')};
  background-color: ${({ outlined }) => (outlined ? colors('white') : colors('bleen'))};
  color: ${({ outlined }) => (outlined ? colors('bleen') : colors('white'))};
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  font-size: ${size('xs')};
  font-family: ${fontFamily()};
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  max-height: 22px;
  display: flex;
  gap: ${pixelSpacing('small')};
  align-items: center;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: ${colors('bleen', 'dark')};
    color: ${colors('white')};
    ${({ outlined }) =>
      outlined &&
      css`
        svg * {
          stroke: ${colors('white')};
        }
      `}
  }
`;

export const Logo = styled(Link)`
  margin: 0;
  padding: 0 ${pixelSpacing('large')};
  img {
    height: 55px;
    width: 120px;
  }
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    display: none;
    visibility: hidden;
  }
`;

export const MainNavSmallScreenItem = styled(MainNavItem)`
  display: none;
  visibility: hidden;
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    display: block;
    visibility: visible;
  }
`;

export const SmallScreenLogo = styled(Link)`
  display: none;
  visibility: hidden;
  img {
    height: 55px;
  }
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    display: block;
    visibility: visible;
  }
`;

export const SmallScreenTitle = styled.div`
  display: none;
  visibility: hidden;
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    display: flex;
    visibility: visible;
    color: ${colors('grey')};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
  }
`;

export const UserMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pixelSpacing('small')};
`;

export const UserPopoverLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors('grey')};
  font-size: 0.8em;
  line-height: 1.45em;
  &:hover {
    color: ${colors('orange')};
  }
  svg {
    font-size: 1.45em;
    margin-right: ${spacing / 2}px;
    vertical-align: top !important;
  }
`;

export const UserPopoverButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: ${fontFamily()};
  padding: 0;
  border: 0;
  color: ${colors('grey')};
  font-size: 0.8em;
  line-height: 1.45em;
  &:hover {
    color: ${colors('orange')};
  }
  svg {
    font-size: 1.45em;
    margin-right: ${spacing / 2}px;
    vertical-align: top !important;
  }
`;

export const RightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${pixelSpacing()};
  gap: ${pixelSpacing('small')};
`;

export const DemoAccount = styled.span`
  margin-right: ${spacing * 2}px;
  display: flex;
  align-items: center;
`;

export const Quotation = styled.a`
  border-radius: 4px;
  box-shadow: 0 0 0 0 rgba(118, 194, 44, 0.7);
  cursor: pointer;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation-delay: 0.2s;
  text-decoration: none;
  @keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(118, 194, 44, 0);
    }
  }
  z-index: 2147483642;
  padding: 6px 10px;
  background-color: #76c22c;
  color: white;
`;
