import { CLUSTER_TYPE } from '../constants/clusterConstants';

export const determineAddClusterButtonWording = (parentsClusters, t) => {
  let buttonTitle = t('Clusters.add_cluster');
  if (parentsClusters?.length) {
    let cumaNumber = 0;
    let etaNumber = 0;

    parentsClusters.forEach((parentCluster) => {
      switch (parentCluster.cluster_type) {
        case 'CUMA':
          cumaNumber++;
          break;
        case 'ETA':
          etaNumber++;
          break;
        default:
          break;
      }
    });

    if (cumaNumber && !etaNumber) {
      buttonTitle = t('Clusters.add_cluster_for_cuma');
    } else if (etaNumber && !cumaNumber) {
      buttonTitle = t('Clusters.add_cluster_for_eta');
    }
  }
  return buttonTitle;
};

export function isPluginActivated(cluster, pluginName) {
  if (!cluster) return false;
  const plugins = cluster.plugins || [];
  return plugins.findIndex((p) => p.option === pluginName && p.activated) !== -1;
}

/**
 * Get information on a the parents of a cluster: whether the cluster has parents and is a farm, and whether the cluster
 * is a child of a CUMA or an ETA
 *
 * @param   {Cluster}                                                                    cluster
 * @param   {Record<string, Cluster>}                                                    clustersById
 *
 * @returns {{ isFarmAndChild: boolean; isChildOfCuma: boolean; isChildOfEta: boolean }}
 */
export function getChildClusterType(cluster, clustersById) {
  const isFarmAndChild = cluster?.cluster_type === CLUSTER_TYPE.FARM && cluster?.parent_cluster_ids?.length > 0;
  const isChildOfCuma =
    isFarmAndChild && !!cluster.parent_cluster_ids.find((id) => clustersById[id]?.cluster_type === CLUSTER_TYPE.CUMA);
  const isChildOfEta =
    isFarmAndChild && !!cluster.parent_cluster_ids.find((id) => clustersById[id]?.cluster_type === CLUSTER_TYPE.ETA);

  return { isFarmAndChild, isChildOfCuma, isChildOfEta };
}
