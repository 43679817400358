import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@karnott/buttons';
import { MarkerIcon } from '@karnott/icons';
import { I18nContext } from '../../../contexts/I18nProvider';
import { getCustomMarkerPositionStyle } from '../../../utils/map';
import { MapContext } from '../contexts/map/';
import { useDrawCirclesOnMap } from '../effects';
import { useCenterMapOnLocation, useGeolocation } from './effects';

function GeolocationControl() {
  const { t } = useContext(I18nContext);
  const { map } = useContext(MapContext);
  const [getLocation, , , location] = useGeolocation();
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    if (location) {
      setCurrentLocation(location);
    }
  }, [location]);

  const userLocation = useMemo(() => getCustomMarkerPositionStyle(currentLocation), [currentLocation]);

  const currentLocationEvents = useMemo(() => {
    return currentLocation
      ? {
          click: () => {
            setCurrentLocation(null);
          },
        }
      : {};
  }, [currentLocation]);

  const userLocationDataExtractor = useCallback((i) => i, []);
  useDrawCirclesOnMap(map, userLocation, userLocationDataExtractor, currentLocationEvents);
  useCenterMapOnLocation(map, location);

  return (
    <Button
      Icon={MarkerIcon}
      onClick={getLocation}
      message={t('Controls.location')}
      outlinedNeutral
      primary
      noWrap
      tooltipOrientation="left"
    />
  );
}

export default GeolocationControl;
