import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const RemorkIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="39.1"
          y1="19.4"
          x2="5.2"
          y2="19.4"
        />
        <g>
          <ellipse
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="12.4"
            cy="33.2"
            rx="3.8"
            ry="3.9"
          />
        </g>
        <g>
          <ellipse
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="24.2"
            cy="33.2"
            rx="3.8"
            ry="3.9"
          />
        </g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M38.5,12H12.7c-0.3-0.8-0.8-1.9-1.7-1.9H6.1c-0.5,0-1,0.5-1,1v1.7c0,0,0,0,0,0.1c0,0,0,0,0,0.1v12.4c0,0.5,0.5,1,1,1h32.4
		c0.5,0,1-0.5,1-1V13C39.5,12.5,39,12,38.5,12z"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		9.3,30.6 8,30.6 5.9,26.7 	"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="21.3"
          y1="30.6"
          x2="15.4"
          y2="30.6"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="36.5"
          y1="30.6"
          x2="27.4"
          y2="30.6"
        />

        <polyline
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		39.1,26.2 43,30.6 36.5,30.6 	"
        />
      </g>
    </svg>
  </CircledIcon>
);
