import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const MarkerIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '90%'}
      height={circled ? '50%' : '90%'}
      viewBox="0 0 15 19.5"
    >
      <g transform="translate(0.75 0.75)">
        <path
          d="M212.347,74.53c0,3.883-6.75,10.97-6.75,10.97s-6.75-7.087-6.75-10.97a6.756,6.756,0,1,1,13.5,0Z"
          transform="translate(-198.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M208.291,74.768a2.694,2.694,0,1,1-2.693-2.7A2.7,2.7,0,0,1,208.291,74.768Z"
          transform="translate(-198.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
