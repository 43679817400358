import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const MapIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 27 18.652"
    >
      <g transform="translate(1 1)">
        <path
          d="M260.537,72.479l-1.357.9L257.1,85.75l8.333-4.125,8.333,4.125,8.334-4.125-5.792-11.569-4.625,3.319-.376-.249L268.683,71.4,265.43,69.25l-2.3,1.515Z"
          transform="translate(-257.097 -69.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M265.43,69.471V81.625"
          transform="translate(-257.097 -69.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="2.083"
          y2="12.375"
          transform="translate(14.583 4.125)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
