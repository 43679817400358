const colors = {
  orange: {
    light: '#faaf2c',
    default: '#f7931e',
    dark: '#f67d00',
    snowed: '#f4c286',
  },
  bleen: {
    dark: '#1c6f6a',
    default: '#238B85',
    light: '#4ea29d',
  },
  green: {
    light: '#9bde72',
    default: '#76c22c',
    dark: '#319626',
    snowed: '#DAF0CB',
  },
  sepia: {
    light: '#a5916e',
    default: '#8a755d',
    dark: '#75675c',
  },
  cyan: {
    light: '#2EE8EA',
    default: '#1DD7D9',
  },
  yellow: {
    light: '#e2d54e',
    default: '#dbcb22',
    dark: '#afa21b',
  },
  grey: {
    darker: '#34312c',
    dark: '#3b342b',
    default: '#4b453b',
    light: '#918e8a',
  },
  brown: {
    default: '#4c453b',
  },
  smoke: {
    darker: '#131313',
    dark: '#cbc6bf',
    default: '#dedbd7',
    light: '#e6e4e1',
  },
  snow: {
    dark: '#ebe9e6',
    default: '#f2f0ed',
    light: '#faf9f7',
  },
  red: {
    dark: '#e54141',
    default: '#ff4949',
    light: '#ff5b5b',
  },
  white: {
    default: '#ffffff',
  },
};

const colorsUi = {
  primary: '#f7931e',
  success: '#13ce66',
  error: '#ff4949',
  warning: '#ffc82c',
  disabled: {
    border: colors.smoke.dark,
    text: colors.smoke.default,
    background: colors.snow.default,
  },
};

export const hexToRgb = (hex, opacity = 1) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})`
    : null;
};

export const color = (name, shade = 'default') => colors[name] && colors[name][shade];

export const ui = (name, part) => (name ? (part ? colorsUi[name][part] : colorsUi[name]) : null);
