export const ParcelIcon = (props) => (
  <svg width={`${props.size || 39}`} height={`${props.size || 39}`} viewBox="0 0 39 39">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
      stroke={props.color}
      d="M28.2 12.2L14 16.3l-3.8-3 14.2-4.1zM28.5 19l-14.2 4-3.8-3 14.2-4zM28.8 25.7l-14.2 4.1-3.8-3L25 22.7z"
    />
  </svg>
);
