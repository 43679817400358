import { color } from '../../ui/theme';

export const ParcelsIcon = (props) => (
  <svg viewBox="0 0 25 40" height={`${props.size || 30}`} width={`${props.size || 30}`}>
    {props.circled ? <circle cx="21" cy="21" r="21" fill={props.background || color('orange')} /> : null}
    <g transform="translate(-8 0)">
      <polyline
        fill="none"
        stroke={props.color || color('grey')}
        points="15.6,21 13.6,22.4 21,27.4 28.4,22.4 26.4,21 	"
      />
      <polygon
        fill="none"
        stroke={props.color || color('grey')}
        points="21,14.6 13.6,19.6 21,24.7 28.4,19.6 21,14.6 	"
      />
    </g>
  </svg>
);
