import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const SnowRemovalIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <polygon
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		22,15.9 28.1,11.4 27.3,18.9 34.2,22 27.3,25.1 28.1,32.6 22,28.1 15.9,32.6 16.7,25.1 9.8,22 16.7,18.9 15.9,11.4 	"
        />
        <g>
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="22"
            y1="5.3"
            x2="22"
            y2="38.7"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			27.1,6.5 22,10.3 16.9,6.5 		"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			16.9,37.5 22,33.7 27.1,37.5 		"
          />
        </g>
        <g>
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="36.7"
            y1="14"
            x2="7.3"
            y2="30"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			38,19 32.3,16.4 33.1,10.1 		"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			6,25 11.7,27.6 10.9,33.9 		"
          />
        </g>
        <g>
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="7.3"
            y1="14"
            x2="36.7"
            y2="30"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			6,19 11.7,16.4 10.9,10.1 		"
          />

          <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			38,25 32.3,27.6 33.1,33.9 		"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
