import { ReactNode, createContext } from 'react';
import { I18n } from '../i18n';

type Context = typeof I18n;

export const I18nContext = createContext<Context>({ t: () => '', locale: 'fr' });

const I18nProvider = ({ children }: { children: ReactNode }) => {
  return (
    <I18nContext.Provider value={{ t: I18n.t || (() => ''), locale: I18n.locale }}>{children}</I18nContext.Provider>
  );
};

export default I18nProvider;
