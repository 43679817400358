import { colors } from '@karnott/colors';

export const MenuIcon = ({ color = colors('black'), size = 24 }) => (
  <svg viewBox="0 0 24 24" width={size} height={size}>
    <g>
      <g>
        <circle fill={color} stroke={color} cx="12" cy="6" r="1" />
        <circle fill={color} stroke={color} cx="12" cy="12" r="1" />
        <circle fill={color} stroke={color} cx="12" cy="18" r="1" />
      </g>
    </g>
  </svg>
);
