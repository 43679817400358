import { Actions } from '../actions';
import { getWorksiteKey } from '../utils/worksites';

// here keys are combination of device_session_id and cluster_id
const initialState = {
  worksitesByKey: {},
  worksitesDeviceSessionKeys: [],
};

const worksites = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Actions.WORKSITES_SUCCESS:
      if (payload?.length) {
        const worksitesSuccessDeviceSessionKeys = [...state.worksitesDeviceSessionKeys];
        const worksitesSuccessByKey = { ...state.worksitesByKey };
        payload.forEach((worksite) => {
          const key = getWorksiteKey(worksite);
          if (!worksitesSuccessDeviceSessionKeys.includes(key)) {
            worksitesSuccessDeviceSessionKeys.push(key);
          }
          worksitesSuccessByKey[key] = worksite;
        });
        return {
          ...state,
          worksitesDeviceSessionKeys: worksitesSuccessDeviceSessionKeys,
          worksitesByKey: worksitesSuccessByKey,
        };
      } else {
        return state;
      }
    case Actions.LOGIN_ERROR:
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default worksites;
