import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const SaveIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19 19">
      <g transform="translate(1 1)">
        <line
          x1="4.931"
          y2="4.931"
          transform="translate(7.477 6.034)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="2.885"
          y1="2.885"
          transform="translate(4.592 8.081)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        {!circled && (
          <circle
            cx="8.5"
            cy="8.5"
            r="8.5"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        )}
      </g>
    </svg>
  </CircledIcon>
);
