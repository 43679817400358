import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export function useRegionBinder(map) {
  const [isTrackingRegion, setIsTrackingRegion] = useState(false);
  const [isBinded, setIsBinded] = useState(false);
  const [region, setRegion] = useState(null);

  const bind = useCallback(() => {
    setIsBinded(true);
  }, []);
  const unbind = useCallback(() => {
    setIsBinded(false);
  }, []);

  const trackRegion = useCallback(() => {
    setIsTrackingRegion(true);
  }, []);
  const untrackRegion = useCallback(() => {
    setIsTrackingRegion(false);
  }, []);

  const listener = useCallback(() => {
    const mapRegion = map.getBounds();
    if (
      mapRegion._northEast.lat &&
      mapRegion._northEast.lat === mapRegion._southWest.lat &&
      mapRegion._northEast.lng &&
      mapRegion._northEast.lng === mapRegion._southWest.lng
    ) {
      return;
    }
    setRegion({ ...mapRegion });
  }, [map]);

  useEffect(() => {
    if (!map) return () => {};
    if (isTrackingRegion) {
      map.on('moveend', listener);
      return () => {
        map.off('moveend', listener);
      };
    }
    return () => {};
  }, [isTrackingRegion, listener, map, setRegion]);

  return {
    isTrackingRegion,
    isBinded,
    bind,
    unbind,
    trackRegion,
    untrackRegion,
    region,
  };
}

export function useCallSwitch(value, ifOn, ifOff) {
  useEffect(() => {
    if (value) {
      ifOn && ifOn();
      return ifOff;
    }
  }, [value, ifOn, ifOff]);
}

export function useFitWithRegionBinding(fit, region, fitMap) {
  function signRegion(r) {
    return r ? `${r._southWest.lat}-${r._southWest.lng}-${r._northEast.lat}-${r._northEast.lng}` : '';
  }
  const fitted = useRef(null);
  const points = useMemo(() => {
    if (!fit || !region) return null;
    return [
      [region._southWest.lat, region._southWest.lng],
      [region._northEast.lat, region._northEast.lng],
    ];
  }, [fit, region]);

  useEffect(() => {
    if (fit && points) {
      const signature = signRegion(region);
      if (fitted.current !== signature) {
        fitted.current = signature;
        fitMap && fitMap(false, points);
      }
    }
    return () => {};
  }, [fit, fitMap, points, region]);
}
