import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';

export function useRefWidthHeight<T extends HTMLElement>(
  opened: boolean,
  content: ReactNode,
): [containerRef: RefObject<T>, width: number, height: number] {
  const containerRef = useRef<T>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const domRect = containerRef.current.getBoundingClientRect();
      if (opened && height !== domRect.height) {
        setHeight(domRect.height);
      }
      if (opened && width !== domRect.width) {
        setWidth(domRect.width);
      }
    }
  }, [height, width, opened, content]);

  return [containerRef, width, height];
}
