import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Context } from './context';

const Provider = function ({ children, clusterById }) {
  const getClusterById = useCallback((id) => (clusterById && clusterById[id]) || {}, [clusterById]);
  return (
    <Context.Provider
      value={{
        getClusterById,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const ReduxConnectedProvider = connect((state) => ({
  clusterById: state.clusters.clusterById,
}))(Provider);
export { ReduxConnectedProvider as Provider };
