export const BeaconIcon = ({ color, size }) => (
  <svg viewBox="0 0 42 42" fill={color} width={`${size || 24}`} height={`${size || 24}`}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M24.5,26.8h-7c-0.2,0-0.4-0.1-0.5-0.2L15.4,25c-0.1-0.1-0.2-0.3-0.2-0.5v-7c0-0.2,0.1-0.4,0.2-0.5l1.6-1.6
	c0.1-0.1,0.3-0.2,0.5-0.2h7c0.2,0,0.4,0.1,0.5,0.2l1.6,1.6c0.1,0.1,0.2,0.3,0.2,0.5v7c0,0.2-0.1,0.4-0.2,0.5L25,26.6
	C24.9,26.7,24.7,26.8,24.5,26.8z"
    />
    <circle fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" cx="21" cy="21" r="1.7" />
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22.6,24.8h-3.3c-0.5,0-1.1-0.2-1.4-0.6l-0.1-0.1c-0.4-0.4-0.6-0.9-0.6-1.4v-3.3c0-0.5,0.2-1.1,0.6-1.4l0.1-0.1
	c0.4-0.4,0.9-0.6,1.4-0.6h3.3c0.5,0,1.1,0.2,1.4,0.6l0.1,0.1c0.4,0.4,0.6,0.9,0.6,1.4v3.3c0,0.5-0.2,1.1-0.6,1.4l-0.1,0.1
	C23.7,24.6,23.2,24.8,22.6,24.8z"
    />
    <line
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      x1="24.3"
      y1="24.3"
      x2="25.8"
      y2="25.8"
    />
    <line
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      x1="16.2"
      y1="16.2"
      x2="17.7"
      y2="17.7"
    />
    <line
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      x1="17.7"
      y1="24.3"
      x2="16.4"
      y2="25.6"
    />
    <line
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      x1="25.8"
      y1="16.2"
      x2="24.3"
      y2="17.7"
    />
  </svg>
);
