import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const TractorIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <ellipse
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="32.8"
            cy="30.1"
            rx="5.2"
            ry="5"
          />
        </g>

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="25.5"
          y1="17.7"
          x2="19.5"
          y2="20.9"
        />

        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M25.5,17.7l-1.9-7.4c-0.2-0.9-1.1-1.6-2.1-1.6H11c-1,0-1.8,0.8-1.8,1.8v9.5"
        />

        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M38,30.1V19.8c0-1.1-0.8-2.1-2.1-2.1l0,0H25.5"
        />

        <circle
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="14"
          cy="27.1"
          r="8"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="22.1"
          y1="28.7"
          x2="27.5"
          y2="28.7"
        />
      </g>
    </svg>
  </CircledIcon>
);
