import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

type Props = {
  color?: string;
  size?: number;
  backgroundColor?: string;
  circled?: boolean;
  batteryLevel?: number;
  batteryColor?: string;
  charging?: boolean;
  chargingColor?: string;
};

export const BatteryIcon = ({
  color = '#000',
  size = 30,
  backgroundColor = colors('grey'),
  circled = false,
  batteryLevel = 0,
  batteryColor = colors('green'),
  charging = false,
  chargingColor = color,
}: Props) => {
  return (
    <CircledIcon {...{ backgroundColor, circled, size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={circled ? '50%' : '100%'}
        height={circled ? '50%' : '100%'}
        viewBox="0 0 14.418 9.018"
      >
        <defs>
          <clipPath>
            <rect width="14.418" height="9.018" transform="translate(0 0)" fill="none" />
          </clipPath>
        </defs>
        <g transform="translate(0 0)" clipPath="url(#clipPath)">
          <rect x="1" y="1" width={(12.5 * batteryLevel) / 100} height="7.1" fill={batteryColor} />
          <path
            d="M2.115.5H12.3a1.616,1.616,0,0,1,1.615,1.615V6.9A1.616,1.616,0,0,1,12.3,8.518H2.115A1.616,1.616,0,0,1,.5,6.9V2.115A1.653,1.653,0,0,1,2.115.5Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y2="7.684"
            transform="translate(11.002 0.777)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          {charging ? (
            <path
              d="M7.169,7.192V5.28l3.1.273L6.135,2.821V4.733l-3.1-.273Z"
              transform="translate(-0.823 -0.498)"
              fill={chargingColor}
            />
          ) : null}
        </g>
      </svg>
    </CircledIcon>
  );
};
