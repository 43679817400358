import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const DriverIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '75%'}
      height={circled ? '50%' : '75%'}
      viewBox="0 0 21.5 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M466.585,75.5a10.808,10.808,0,0,1-.353,2.456,4.783,4.783,0,0,1-1.017,1.632,4.553,4.553,0,0,1-1.574,1.061,5.684,5.684,0,0,1-4.071,0,4.528,4.528,0,0,1-1.577-1.061,4.767,4.767,0,0,1-1.017-1.632,10.675,10.675,0,0,1-.355-2.481"
          transform="translate(-451.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M464.211,80.414a10.121,10.121,0,0,1,1.657.478,8.94,8.94,0,0,1,3.114,1.983,8.644,8.644,0,0,1,1.927,3.069,8.217,8.217,0,0,1,.438,1.556h-19.5a7.913,7.913,0,0,1,.43-1.556,8.727,8.727,0,0,1,1.956-3.069,8.835,8.835,0,0,1,3.09-1.982,9.94,9.94,0,0,1,1.3-.4"
          transform="translate(-451.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M467.34,73.268a1.194,1.194,0,0,1,.562.906c0,1.208-2.807,2.186-6.27,2.186s-6.269-.978-6.269-2.186a1.115,1.115,0,0,1,.459-.823"
          transform="translate(-451.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M461.6,67.5c-2.321,0-4.678,1.451-6.138,2.549-1.4,1.052-1.253,2.321.316,3.176l.035.019a.2.2,0,0,0,.091.022H467.29a.2.2,0,0,0,.093-.023l.057-.031c1.554-.855,1.689-2.115.3-3.163C466.287,68.951,463.936,67.5,461.6,67.5Z"
          transform="translate(-451.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M461.6,67.5c-2.321,0-4.679,1.452-6.139,2.55-1.4,1.051-1.252,2.32.316,3.174l.035.019a.2.2,0,0,0,.093.023h11.39a.2.2,0,0,0,.093-.023l.035-.019c1.568-.854,1.718-2.122.321-3.176C466.285,68.95,463.935,67.5,461.6,67.5Z"
          transform="translate(-451.847 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
