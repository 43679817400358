import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const MiscellaneousIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="9.7"
            cy="9.7"
            r="2.7"
          />

          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="34.3"
            cy="9.7"
            r="2.7"
          />

          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="22"
            cy="9.7"
            r="2.7"
          />
        </g>
        <g>
          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="9.7"
            cy="22"
            r="2.7"
          />

          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="34.3"
            cy="22"
            r="2.7"
          />

          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="22"
            cy="22"
            r="2.7"
          />
        </g>
        <g>
          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="9.7"
            cy="34.3"
            r="2.7"
          />

          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="34.3"
            cy="34.3"
            r="2.7"
          />

          <circle
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="22"
            cy="34.3"
            r="2.7"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
