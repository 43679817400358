import Icon from 'react-icon-base';

const IcMenu = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <defs>
        <style>
          {'.cls-1{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;stroke-width:2px}'}
        </style>
      </defs>
      <path id="_Trac&#xE9;_" data-name="&lt;Trac&#xE9;&gt;" className="cls-1" d="M5 7h14" />
      <path id="_Trac&#xE9;_2" data-name="&lt;Trac&#xE9;&gt;" className="cls-1" d="M5 12h14" />
      <path id="_Trac&#xE9;_3" data-name="&lt;Trac&#xE9;&gt;" className="cls-1" d="M5 17h14" />
    </g>
  </Icon>
);

export default IcMenu;
