import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFullScreen, toggleFullScreenDashboard } from '../../../../actions/ui';
import { MapContext } from '../map';
import Context from './Context';

const Provider = function ({ fitMap, children, toggleMarkerClusters }) {
  const { map } = useContext(MapContext);
  const dispatch = useDispatch();
  const isFullScreen = useSelector((state) => state.ui.fullScreenMap);
  const isDashboardFullScreen = useSelector((state) => state.ui.fullScreenDashboardMap);

  const toggleFullScreenCb = useCallback(() => {
    dispatch(toggleFullScreen());
    setTimeout(() => map.invalidateSize(), 50);
  }, [dispatch, map]);

  const toggleFullScreenDashboardCb = useCallback(() => {
    dispatch(toggleFullScreenDashboard());
    setTimeout(() => map.invalidateSize(), 50);
  }, [dispatch, map]);

  return (
    <Context.Provider
      value={{
        fitMap,
        toggleMarkerClusters,
        isFullScreen,
        isDashboardFullScreen,
        toggleFullScreen: toggleFullScreenCb,
        toggleFullScreenDashboard: toggleFullScreenDashboardCb,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
