import flattenDeep from 'lodash-es/flattenDeep';
import { push, replace } from 'redux-first-history';
import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { clusterParcel, parcel } from '../api/schema';
import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';
import { normalize } from '../utils';

const requestParcels = () => ({
  type: Actions.PARCELS_REQUEST,
});

const receiveParcels = (payload) => ({
  type: Actions.PARCELS_SUCCESS,
  payload,
});

const requestParcel = () => ({
  type: Actions.PARCEL_REQUEST,
});

export const receiveParcel = (payload) => ({
  type: Actions.PARCEL_SUCCESS,
  payload,
});

export const fetchParcel = (id) => (dispatch) => {
  dispatch(requestParcel());

  return Api.getParcel(id).then(
    (result) => dispatch(receiveParcel(normalize([result], [parcel]))),
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const fetchParcels = () => (dispatch) => {
  dispatch(requestParcels());

  return Api.getParcels().then(
    (result) => dispatch(receiveParcels(normalize(result || {}, [parcel]))),
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const fetchAnonymousParcelsByIds = (parcelIds) => (dispatch) => {
  dispatch(requestParcels());

  return Api.getAnonymousParcelsByIds(parcelIds).then(
    (result) => dispatch(receiveParcels(normalize(result || {}, [parcel]))),
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const focusParcel = (payload) => ({
  type: Actions.PARCEL_FOCUS,
  payload,
});

export const clearParcelCreationErrors = () => ({
  type: Actions.PARCEL_CREATION_CLEAR_ERRORS,
});

const parcelConsumptionsSuccess = (payload) => ({
  type: Actions.PARCEL_CONSUMPTIONS_SUCCESS,
  payload,
});

export const fetchParcelConsumptions =
  ({ id, start, end, format, signal }) =>
  (dispatch) =>
    Api.getConsolidatedParcelSessions({ id, start, end, format, signal }).then(
      (response) => {
        if (format === 'csv' || format === 'xlsx') {
          return response;
        }
        return dispatch(parcelConsumptionsSuccess(response));
      },
      (e) => {
        if (e.name !== 'AbortError') {
          return errorHasOccurred(e, dispatch);
        }
      },
    );

const requestCreateParcel = () => ({
  type: Actions.PARCEL_CREATION_REQUEST,
});

const receiveCreateParcel = (payload) => ({
  type: Actions.PARCEL_CREATION_SUCCESS,
  payload,
});

const receiveErrorsCreateParcel = (payload) => ({
  type: Actions.PARCEL_CREATION_ERRORS,
  payload,
});

const requestEditParcel = (id) => ({
  type: Actions.PARCEL_EDIT_REQUEST,
  payload: id,
});

const receiveEditParcel = (payload) => ({
  type: Actions.PARCEL_EDIT_SUCCESS,
  payload,
});

export const editParcel = (id, payload) => (dispatch) => {
  dispatch(requestEditParcel(id));

  return Api.editParcel(id, payload).then(
    (result) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 3000,
          message: I18n.t('ParcelForm.parcel_edit_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      const payload = normalize(result, parcel);
      return Api.getParcel(payload.result).then((resultV2) => {
        dispatch(receiveEditParcel(normalize(resultV2, parcel)));
      });
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const patchParcel = (id, payload) => (dispatch) =>
  Api.patchParcel(id, payload).then(
    (result) => {
      dispatch(receiveEditParcel(normalize(result, parcel)));
      return result;
    },
    (e) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.ERROR,
          duration: 5000,
          message: catchParcelErrorCode(e, I18n.t('ParcelForm.parcel_create_error')),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      return Promise.reject(e);
    },
  );

const requestDeleteParcel = (id) => ({
  type: Actions.PARCEL_DELETE_REQUEST,
  payload: id,
});

const receiveDeleteParcel = (id) => ({
  type: Actions.PARCEL_DELETE_SUCCESS,
  payload: id,
});

export const deleteParcel = (id, redirect) => (dispatch) => {
  dispatch(requestDeleteParcel(id));

  return Api.deleteParcel(id).then(
    () => {
      if (redirect) {
        dispatch(replace('/parcels'));
      }
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 3000,
          message: I18n.t('ParcelForm.parcel_delete_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      return dispatch(receiveDeleteParcel(id));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const deleteMultiParcelsSuccess = (payload) => ({
  type: Actions.MULTI_PARCEL_DELETE_SUCCESS,
  payload,
});
export const deleteMultiParcels = (ids) => (dispatch) => {
  return Api.deleteMultiParcels(ids).then(
    () => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 3000,
          message:
            ids.length > 1
              ? I18n.t('DeleteParcelForm.parcels_delete_success')
              : I18n.t('ParcelForm.parcel_delete_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      return dispatch(deleteMultiParcelsSuccess({ parcelIds: ids }));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const requestImportParcels = () => ({
  type: Actions.REQUEST_IMPORT_PARCELS,
});

const receiveImportedParcels = (importedParcels) => ({
  type: Actions.RECEIVE_IMPORTED_PARCELS,
  payload: importedParcels,
});

export const importParcels = (cluster_id, file, format, projectionName, projectionID, fromDate, type) => (dispatch) => {
  dispatch(requestImportParcels());
  return Api.importParcels(cluster_id, file, format, projectionName, projectionID, fromDate, type).then(
    (importedParcels) => {
      // need to json.parse geometry area of parcels
      importedParcels.success.forEach((importedParcelSuccess) => {
        importedParcelSuccess.geometry_area = JSON.parse(importedParcelSuccess.geometry_area);
      });
      importedParcels.errors = importedParcels.errors || [];
      importedParcels.errors.forEach((importedParcelError) => {
        if (importedParcelError.parcel.geometry_area) {
          importedParcelError.parcel.geometry_area = JSON.parse(importedParcelError.parcel.geometry_area);
        }
      });
      dispatch(receiveImportedParcels(importedParcels));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const reinitImportedParcels = () => ({
  type: Actions.REINIT_IMPORTED_PARCELS,
});

const requestCreateGfrParcels = () => ({
  type: Actions.GFR_PARCELS_CREATION_REQUEST,
});

const receiveCreateGfrParcels = (normalizedResult, gfrParcelIds) => ({
  type: Actions.GFR_PARCELS_CREATION_SUCCESS,
  payload: {
    normalizedResult,
    gfrParcelIds,
  },
});

export const createGfrParcels = (payload) => (dispatch) => {
  const { clusterId, gfrParcelIds, gfrParcelNames, fromDate } = payload;
  dispatch(requestCreateGfrParcels());

  return Api.postGfrParcels(gfrParcelIds, gfrParcelNames, clusterId, fromDate).then(
    (result) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 5000,
          message: I18n.t('ParcelForm.gfr_parcels_create_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      dispatch(receiveCreateGfrParcels(normalize(result, [parcel]), gfrParcelIds));
      if (payload.withRedirect) {
        if (result[0]) {
          dispatch(push(`/parcels/${result[0].id}/`));
        } else {
          dispatch(push('/parcels/'));
        }
      }
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const requestParcelHistory = (id) => ({
  type: Actions.PARCEL_HISTORY_REQUEST,
  payload: id,
});

const receiveParcelHistory = (payload) => ({
  type: Actions.PARCEL_HISTORY_SUCCESS,
  payload,
});

export const fetchParcelHistory = (id) => (dispatch) => {
  dispatch(requestParcelHistory(id));

  return Api.getParcelHistory(id).then(
    (response) => {
      // because virtual history has no id
      const responseWithVirtualHistoryFormatted = flattenDeep(response).map((h, i) => {
        if (!h.id || h.id === '') {
          h.id = `virtual-${parseInt(new Date().getTime())}-${i}`;
          h.isVirtual = true;
        }
        return h;
      });
      return dispatch(
        receiveParcelHistory({
          parcelId: id,
          data: normalize(responseWithVirtualHistoryFormatted, [clusterParcel]),
        }),
      );
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const requestCreateParcelRent = (id) => ({
  type: Actions.PARCEL_RENT_CREATION_REQUEST,
  payload: id,
});

export const createMultiParcelRent = (ids, payload) => (dispatch) => {
  return Api.createMultiParcelRent(ids, payload).then(
    () => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 5000,
          message: payload.cluster_id
            ? ids.length > 1
              ? I18n.t('ParcelHistory.parcels_rent_create_success')
              : I18n.t('ParcelHistory.parcel_rent_create_success')
            : ids.length > 1
              ? I18n.t('ParcelHistory.parcels_rent_archive_success')
              : I18n.t('ParcelHistory.parcel_rent_archive_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );

      // need to parcels list because the field tenant can be updated
      return dispatch(fetchParcels());
    },
    (e) => {
      if (e?.reason === 'intersectedParcelsFound') {
        return Promise.reject(e);
      } else {
        return errorHasOccurred(e, dispatch);
      }
    },
  );
};
export const createParcelRent = (id, payload) => (dispatch) => {
  dispatch(requestCreateParcelRent(id));

  return Api.createParcelRent(id, payload).then(
    () => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 5000,
          message: payload.cluster_id
            ? I18n.t('ParcelHistory.parcel_rent_create_success')
            : I18n.t('ParcelHistory.parcel_rent_archive_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );

      // need to update history (because of merge) & update parcel because of current_tenant_id
      return Promise.all([dispatch(fetchParcelHistory(id)), dispatch(fetchParcel(id))]);
    },
    (e) => {
      if (e?.reason === 'intersectedParcelsFound') {
        return Promise.reject(e);
      } else {
        return errorHasOccurred(e, dispatch);
      }
    },
  );
};

const requestUpdateClusterParcel = (id) => ({
  type: Actions.UPDATE_CLUSTER_PARCEL_REQUEST,
  payload: id,
});

export const updateClusterParcel = (id, payload) => (dispatch) => {
  dispatch(requestUpdateClusterParcel(id));

  return Api.updateClusterParcel(id, payload).then(
    (response) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 5000,
          message: I18n.t('ParcelHistory.parcel_rent_update_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      // need to update history (because of merge) & update parcel because of current_tenant_id
      const { parcel_id } = response;
      return Promise.all([dispatch(fetchParcelHistory(parcel_id)), dispatch(fetchParcel(parcel_id))]);
    },
    (e) => {
      if (e?.reason === 'intersectedParcelsFound') {
        return Promise.reject(e);
      } else {
        return errorHasOccurred(e, dispatch);
      }
    },
  );
};

export const deleteClusterParcel = (id, parcel_id) => (dispatch) =>
  Api.deleteClusterParcel(id).then(
    () => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 5000,
          message: I18n.t('ParcelHistory.parcel_rent_delete_success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      return dispatch(fetchParcelHistory(parcel_id));
    },
    (e) => {
      if (e?.reason === 'intersectedParcelsFound') {
        return Promise.reject(e);
      } else {
        return errorHasOccurred(e, dispatch);
      }
    },
  );

export const closeCurrentRentParcel =
  (parcelId, closingDate = null, isDesarchive = false) =>
  (dispatch) => {
    return Api.closeCurrentRentParcel(parcelId, closingDate).then(
      () => {
        dispatch(
          snackAdded({
            type: SNACK_CONSTANTS.TYPE.SUCCESS,
            duration: 5000,
            message: isDesarchive
              ? I18n.t('ParcelHistory.active_parcel_success')
              : I18n.t('ParcelHistory.close_current_parcel_rent_success'),
            action: SNACK_CONSTANTS.ACTION.OK,
          }),
        );
        // need to update history (because of merge) & update parcel because of current_tenant_id
        return Promise.all([dispatch(fetchParcelHistory(parcelId)), dispatch(fetchParcel(parcelId))]);
      },
      (e) => {
        if (e?.reason === 'intersectedParcelsFound') {
          return Promise.reject(e);
        } else {
          return errorHasOccurred(e, dispatch);
        }
      },
    );
  };

export const closeManyCurrentRentParcel =
  (parcelIds, closingDate = null, isDesarchive = false) =>
  (dispatch) => {
    return Api.closeManyCurrentRentParcel(parcelIds, closingDate).then(
      () => {
        dispatch(
          snackAdded({
            type: SNACK_CONSTANTS.TYPE.SUCCESS,
            duration: 5000,
            message: isDesarchive
              ? I18n.t('ParcelHistory.active_many_parcel_success')
              : I18n.t('ParcelHistory.close_many_current_parcel_rent_success'),
            action: SNACK_CONSTANTS.ACTION.OK,
          }),
        );
        // need to update history (because of merge) & update parcel because of current_tenant_id
        return Promise.all(parcelIds.map((id) => [dispatch(fetchParcelHistory(id)), dispatch(fetchParcel(id))]));
      },
      (e) => {
        if (e?.reason === 'intersectedParcelsFound') {
          return Promise.reject(e);
        } else {
          return errorHasOccurred(e, dispatch);
        }
      },
    );
  };

export const createParcel = (payload) => (dispatch) => {
  dispatch(requestCreateParcel());

  return Api.createParcel(payload).then(
    (result) => {
      const { errors, success, id } = result;
      if (success?.length || id) {
        if (success?.length) {
          dispatch(receiveCreateParcel(normalize(success, [parcel])));
        }
        if (id) {
          dispatch(receiveCreateParcel(normalize(result, parcel)));
        }
        dispatch(
          snackAdded({
            type: SNACK_CONSTANTS.TYPE.SUCCESS,
            duration: 5000,
            message: I18n.t('ParcelForm.parcel_create_success'),
            action: SNACK_CONSTANTS.ACTION.OK,
          }),
        );
      }

      if (errors?.length) {
        dispatch(receiveErrorsCreateParcel(errors));
        console.warn('failed to add some fields', errors);
        dispatch(
          snackAdded({
            type: SNACK_CONSTANTS.TYPE.ERROR,
            duration: 5000,
            message: catchParcelErrorCode(errors[0], I18n.t('ParcelForm.parcel_create_error')),
            action: SNACK_CONSTANTS.ACTION.OK,
          }),
        );
        return Promise.reject(errors);
      }
      return Promise.resolve(success);
    },
    (error) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.ERROR,
          duration: 5000,
          message: catchParcelErrorCode(error, I18n.t('ParcelForm.parcel_create_error')),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
      return Promise.reject(error);
    },
  );
};

const catchParcelErrorCode = (error, defaultMessage = I18n.t('ParcelForm.parcel_create_error')) => {
  let message = defaultMessage;
  if (error.error_code && error.error_code === 1105) {
    message = I18n.t('ErrorsImportModal.area_too_large_error');
  }
  if (error.error_code && error.error_code === 1106) {
    message = I18n.t('ErrorsImportModal.not_authorized');
  }
  if (error.error_code && error.error_code === 1103) {
    message = I18n.t('ErrorsImportModal.intersection');
  }
  return message;
};

export const colorizeParcelsBy = (id) => ({
  type: Actions.COLORIZE_PARCELS_BY,
  payload: id,
});

export const colorizeTracksBy = (id) => ({
  type: Actions.COLORIZE_TRACKS_BY,
  payload: id,
});

const receiveParcelWorksites = (payload) => ({
  type: Actions.PARCEL_WORKSITES_SUCCESS,
  payload,
});

export const fetchParcelWorksites =
  ({ id, start, end, format, params, signal }) =>
  (dispatch) =>
    Api.getParcelWorksites({ id, start, end, format, params, signal }).then(
      (response) => {
        if (format === 'csv' || format === 'xlsx') {
          return response;
        }
        return dispatch(receiveParcelWorksites(response));
      },
      (error) => {
        if (error.name !== 'AbortError') {
          return errorHasOccurred(error, dispatch);
        }
      },
    );

const receiveParcelWorksitesTracks = (payload) => ({
  type: Actions.PARCEL_WORKSITES_TRACKS_SUCCESS,
  payload,
});

const receiveParcelActiveWorksitesTracks = (payload) => ({
  type: Actions.PARCEL_ACTIVE_WORKSITES_TRACKS_SUCCESS,
  payload,
});

export const fetchParcelWorksitesTracks =
  ({ id, start, end, params, signal }) =>
  (dispatch) =>
    Api.getParcelWorksitesTracks({ id, start, end, params, signal }).then(
      (response) => dispatch(receiveParcelWorksitesTracks(response)),
      (error) => {
        if (error.name !== 'AbortError') {
          return errorHasOccurred(error, dispatch);
        }
      },
    );

export const fetchParcelActiveWorksitesTracks =
  ({ id, start, end, signal }) =>
  (dispatch) =>
    Api.getParcelWorksitesTracks({ id, start, end, signal, params: '&status=active' }).then(
      (response) => dispatch(receiveParcelActiveWorksitesTracks(response)),
      (error) => {
        if (error.name !== 'AbortError') {
          return errorHasOccurred(error, dispatch);
        }
      },
    );

const receiveParcelsWorksites = (payload) => ({
  type: Actions.PARCELS_WORKSITES_SUCCESS,
  payload,
});

export const fetchParcelsWorksites = (params, signal) => (dispatch) =>
  Api.getParcelsWorksites(params, signal).then(
    (response) => {
      dispatch(receiveParcelsWorksites(response));
      return response;
    },
    (error) => {
      if (error.name !== 'AbortError') {
        return errorHasOccurred(error, dispatch);
      }
    },
  );

const parcelsWorksitesTracksPending = () => ({
  type: Actions.PARCELS_WORKSITES_TRACKS_PENDING,
});

const parcelsWorksitesTracksSuccess = (payload) => ({
  type: Actions.PARCELS_WORKSITES_TRACKS_SUCCESS,
  payload,
});

const parcelsWorksitesTracksFail = () => ({
  type: Actions.PARCELS_WORKSITES_TRACKS_FAIL,
});

export const fetchParcelsWorksitesTracks = (params, signal) => (dispatch) => {
  dispatch(parcelsWorksitesTracksPending());
  return Api.getParcelsWorksitesTracks(params, signal).then(
    (response) => dispatch(parcelsWorksitesTracksSuccess(response)),
    (error) => {
      if (error.name !== 'AbortError') {
        return dispatch(parcelsWorksitesTracksFail());
      }
    },
  );
};

// Tags

const linkedExistingTagToParcel = (parcelId, tag) => ({
  type: Actions.PARCEL_LINK_EXISTING_TAG,
  payload: { parcelId, tag },
});

const unlinkedTagFromParcel = (parcelId, tagId) => ({
  type: Actions.PARCEL_UNLINK_TAG,
  payload: { parcelId, tagId },
});

export const linkTagToParcel = (parcelId, tag) => (dispatch) => {
  return Api.linkTagToParcel(parcelId, tag.id)
    .then(() => dispatch(linkedExistingTagToParcel(parcelId, tag)))
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const unlinkTagFromParcel = (parcelId, tagId) => (dispatch) => {
  return Api.unlinkTagFromParcel(parcelId, tagId)
    .then(() => dispatch(unlinkedTagFromParcel(parcelId, tagId)))
    .catch((e) => errorHasOccurred(e, dispatch));
};
