import { useCallback, useMemo } from 'react';
import { colors } from '@karnott/colors';
import { ParcelPopup } from '@karnott/map';
import { formatArea } from '../../../../utils';
import { ANALYTICS, sendAnalyticsTooltipClickEvent } from '../../../../utils/AnalyticsConstants';

export function ParcelName({ name }) {
  return (
    <div
      style={{
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '4px',
        overflow: 'hidden',
        border: `1.5px solid ${colors('orange')}`,
        width: 'auto !important',
        height: '20px !important',
        whiteSpace: 'nowrap',
        fontFamily: 'Ubuntu',
        marginLeft: '-50%',
        marginRight: '50%',
        padding: '0px 5px',
      }}
    >
      {name}
    </div>
  );
}

export function ParcelTooltip({ parcelProps, labels, units, headerOnly = false }) {
  const { crop_name, crop_color, current_tenant_cluster, category, area, status, name, action, isViti, hasCropPlugin } =
    parcelProps;

  const formattedArea = useMemo(() => formatArea(area, units.area, true), [area, units]);
  const actionCB = useCallback(() => {
    sendAnalyticsTooltipClickEvent(window)(ANALYTICS.LABEL.TOOLTIPS.PARCEL);
    action && action();
  }, [action]);

  return (
    <ParcelPopup
      crop={crop_name}
      iconColor={crop_color}
      tenant={current_tenant_cluster}
      category={category}
      area={formattedArea}
      status={status}
      name={name}
      action={actionCB}
      hasCropPlugin={hasCropPlugin}
      labels={labels}
      isViti={isViti}
      headerOnly={headerOnly}
    />
  );
}
