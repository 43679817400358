import Icon from 'react-icon-base';

const IcKarnottEmit = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <circle cx="2.095" cy="16.895" r="1.09" fill="#F7931E" />
      <path
        fill="#F7931E"
        d="M1,10.205 L1,11.62 C2.69157133,11.6186665 4.31409752,12.290735 5.50928094,13.4877947 C6.70446437,14.6848544 7.37398695,16.3084328 7.37,18 L8.785,18 C8.78898536,15.9331513 7.97038216,13.9496629 6.50983508,12.4872398 C5.049288,11.0248166 3.06685209,10.2036678 1,10.205 L1,10.205 Z M1,13.035 L1,14.45 C1.94060156,14.4499962 2.8425332,14.8243319 3.50670018,15.490375 C4.17086717,16.1564182 4.54265707,17.0594022 4.54,18 L5.955,18 C5.95898944,16.6837145 5.43854789,15.4200456 4.50872793,14.4883491 C3.57890796,13.5566526 2.3162909,13.0336644 1,13.035 L1,13.035 Z"
      />
      <path
        fill="#4B453C"
        d="M22.755,9.44 L20.89,7.345 C20.7126075,7.13222446 20.4519475,7.00645142 20.175,7 L3.825,7 C3.55200896,7.00015409 3.29199401,7.11652442 3.11,7.32 L2.025,8.555 C6.84684295,9.07830636 10.5002338,13.1498434 10.5,18 L20.175,18 C20.447991,17.9998459 20.708006,17.8834756 20.89,17.68 L22.755,15.585 C22.917298,15.403963 23.0048579,15.1680873 23,14.925 L23,10.075 C22.9986264,9.84051515 22.9114782,9.61464133 22.755,9.44 Z M13.465,14.805 C13.1060149,14.805 12.815,14.5139851 12.815,14.155 C12.815,13.7960149 13.1060149,13.505 13.465,13.505 C13.8239851,13.505 14.115,13.7960149 14.115,14.155 C14.115,14.5139851 13.8239851,14.805 13.465,14.805 Z M16.075,14.805 C15.7160149,14.805 15.425,14.5139851 15.425,14.155 C15.425,13.7960149 15.7160149,13.505 16.075,13.505 C16.4339851,13.505 16.725,13.7960149 16.725,14.155 C16.725,14.5139851 16.4339851,14.805 16.075,14.805 Z M18.685,14.805 C18.4220995,14.805 18.185086,14.6466326 18.0844783,14.4037442 C17.9838707,14.1608559 18.0394819,13.8812793 18.2253806,13.6953806 C18.4112793,13.5094819 18.6908559,13.4538707 18.9337442,13.5544783 C19.1766326,13.655086 19.335,13.8920995 19.335,14.155 C19.3350106,14.5120415 19.0470309,14.8022535 18.69,14.805 L18.685,14.805 Z"
      />
    </g>
  </Icon>
);

export default IcKarnottEmit;
