import { AddCircleIcon } from './addCircleIcon';
import { AddressIcon } from './addressIcon';
import { ArrowBackIcon } from './arrowBackIcon';
import { AssociationIcon } from './associationIcon';
import { BeaconIcon } from './beaconIcon';
import { CheckIcon } from './checkIcon';
import { ChevronDownIcon } from './chevronDownIcon';
import { CloseIcon } from './closeIcon';
import { CrownIcon } from './crownIcon';
import { DeleteIcon } from './deleteIcon';
import { DriverIcon } from './driverIcon';
import { EditIcon } from './editIcon';
import { ExportIcon } from './exportIcon';
import { FarmIcon } from './farmIcon';
import { KarnottIcon } from './karnottIcon';
import { KarnottWithNameIcon } from './karnottWithNameIcon';
import { KarnottWithNameIconColor } from './karnottWithNameIconColor';
import { LocationIcon } from './locationIcon';
import { LogoutIcon } from './logoutIcon';
import { MailIcon } from './mailIcon';
import { MinusIcon } from './minusIcon';
import { ParcelIcon } from './parcelIcon';
import { ParcelRentIcon } from './parcelRentIcon';
import { ParcelsIcon } from './parcelsIcon';
import { PenIcon } from './penIcon';
import { PersonIcon } from './personIcon';
import { PlusIcon } from './plusIcon';
import { RulerIcon } from './rulerIcon';
import { SearchIcon } from './searchIcon';
import { SirenIcon } from './sirenIcon';
import { SurfaceIcon } from './surfaceIcon';
import { TelIcon } from './telIcon';
import { UserIcon } from './userIcon';

export const Icons = {
  AddCircleIcon,
  AddressIcon,
  ArrowBackIcon,
  AssociationIcon,
  BeaconIcon,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  CrownIcon,
  DeleteIcon,
  DriverIcon,
  EditIcon,
  ExportIcon,
  FarmIcon,
  KarnottIcon,
  KarnottWithNameIcon,
  KarnottWithNameIconColor,
  LocationIcon,
  LogoutIcon,
  MailIcon,
  MinusIcon,
  PenIcon,
  ParcelIcon,
  ParcelRentIcon,
  ParcelsIcon,
  PersonIcon,
  PlusIcon,
  RulerIcon,
  SearchIcon,
  SirenIcon,
  SurfaceIcon,
  TelIcon,
  UserIcon,
};
