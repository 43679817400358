import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const AdminIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 22 19"
    >
      <g transform="translate(1 1)">
        <path
          d="M905.733,78.709l3.942-5.642,3.941,5.642"
          transform="translate(-899.597 -69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M901.636,85.076l.076-12.016,3.941,5.643"
          transform="translate(-899.597 -69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M913.617,78.709l3.942-5.642V85.076"
          transform="translate(-899.597 -69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M917.558,85.164a36.221,36.221,0,0,1-8.23.836,37.772,37.772,0,0,1-7.692-.712"
          transform="translate(-899.597 -69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <ellipse
          cx="2.039"
          cy="2.033"
          rx="2.039"
          ry="2.033"
          transform="translate(8.039)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <ellipse
          cx="2.039"
          cy="2.033"
          rx="2.039"
          ry="2.033"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <ellipse
          cx="2.039"
          cy="2.033"
          rx="2.039"
          ry="2.033"
          transform="translate(15.922)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
