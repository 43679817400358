import mapValues from 'lodash-es/mapValues';
import omit from 'lodash-es/omit';
import transform from 'lodash-es/transform';
import uniq from 'lodash-es/uniq';
import moment from 'moment-timezone';
import { Actions } from '../actions';

const initialState = {
  isFetching: false,
  equipmentModelsIds: [],
  equipmentModelById: {},
  equipmentById: {},
  equipmentsIds: [],
};

const equipments = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.DEVICE_ATTACH_EQUIPMENT_SUCCESS: {
      const { equipmentHasDevice } = payload.entities;
      const equipmentHasDeviceArray = Object.values(equipmentHasDevice);
      // update currentDeviceID
      const theEquipmentById = transform(
        state.equipmentById,
        (result, value, key) => {
          let currentDeviceInstanceId = value.currentDeviceId;
          let equipmentStatus = value.status;
          const now = moment().add(5, 'second');
          for (const i in equipmentHasDeviceArray) {
            const ehd = equipmentHasDeviceArray[i];
            const isCurrent =
              moment(ehd.fromDate).isSameOrBefore(now) &&
              (ehd.toDate === undefined || ehd.toDate === null || moment(ehd.toDate).isAfter(now));
            if (isCurrent) {
              if (ehd.equipmentInstanceId === value.id) {
                currentDeviceInstanceId = ehd.deviceInstanceId;
                break;
              }

              if (ehd.deviceInstanceId === value.currentDeviceId) {
                currentDeviceInstanceId = 0;
                equipmentStatus = 'AVAILABLE';
                break;
              }
            }
            if (ehd.deviceInstanceId === value.currentDeviceId) {
              equipmentStatus = 'AVAILABLE';
              currentDeviceInstanceId = 0;
            }
          }
          value.currentDeviceId = currentDeviceInstanceId;
          value.status = equipmentStatus;
          return (result[key] = value);
        },
        {},
      );
      return {
        ...state,
        equipmentById: theEquipmentById,
      };
    }
    case Actions.EQUIPMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.EQUIPMENTS_SUCCESS:
      return {
        ...state,
        equipmentById: mapValues(payload?.entities?.equipmentInstance || {}, (e) => ({
          ...e,
          focused: false,
        })),
        equipmentsIds: payload.result,
        isFetching: false,
      };
    case Actions.EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          ...payload.entities.equipmentInstance,
        },
        equipmentsIds: uniq(state.equipmentsIds.concat(payload.result)),
      };
    case Actions.EQUIPMENT_MODELS_SUCCESS:
      return {
        ...state,
        equipmentModelsIds: Object.keys(payload.entities.equipmentModel).map(Number),
        equipmentModelById: mapValues(payload.entities.equipmentModel, (model) => ({
          ...model,
          equipmentType: model.equipmentType,
        })),
      };
    case Actions.EQUIPMENT_CREATION_SUCCESS:
      return {
        ...state,
        equipmentsIds: state.equipmentsIds.concat(payload.id),
        equipmentById: {
          ...state.equipmentById,
          [payload.id]: {
            ...payload,
            focused: false,
          },
        },
      };
    case Actions.EQUIPMENT_DELETE_SUCCESS:
      return {
        ...state,
        equipmentsIds: state.equipmentsIds.filter((id) => id !== action.payload),
        equipmentById: omit(state.equipmentById, action.payload),
      };
    case Actions.PARCEL_SESSIONS_SUCCESS:
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          ...(payload.data.entities.equipmentInstance || {}),
        },
        equipmentModelById: {
          ...state.equipmentModelById,
          ...(payload.data.entities.equipmentModal || {}),
        },
      };
    case Actions.EQUIPMENT_SESSION_SUCCESS:
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          ...(payload.entities.equipmentInstance || {}),
        },
      };
    case Actions.BEACON_SUCCESSFULLY_LINKED: {
      const { beaconID, equipmentID } = payload;
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          [equipmentID]: {
            ...state.equipmentById[equipmentID],
            currentBeaconUuid: beaconID,
            currentDeviceId: null,
          },
        },
      };
    }
    case Actions.REMOVE_CURRENT_DEVICE_TO_EQUIPMENT: {
      const equipmentId = payload;
      const equipment = state.equipmentById[equipmentId];
      equipment.currentDeviceId = null;
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          [equipmentId]: equipment,
        },
      };
    }
    case Actions.EQUIPMENT_LINK_EXISTING_TAG: {
      const equipment = state.equipmentById[payload.equipmentId];
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          [payload.equipmentId]: { ...equipment, tags: [...(equipment.tags || []), payload.tag] },
        },
      };
    }
    case Actions.EQUIPMENT_UNLINK_TAG: {
      const equipment = state.equipmentById[payload.equipmentId];
      return {
        ...state,
        equipmentById: {
          ...state.equipmentById,
          [payload.equipmentId]: {
            ...equipment,
            tags: (equipment.tags || []).filter((tag) => tag.id !== payload.tagId),
          },
        },
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default equipments;
