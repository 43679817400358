import Icon from 'react-icon-base';

const IcKarnottAvailable = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path
        fill="#4B453C"
        d="M22.7712,9.64315789 L21.0112,7.59421053 C20.8358205,7.3738741 20.5681293,7.25106386 20.2896,7.26315789 L14.2,7.26315789 C14.2,11.216354 11.0480846,14.4210526 7.16,14.4210526 C5.29536319,14.420174 3.50723048,13.6672003 2.188,12.3273684 C2.0824,12.22 1.9856,12.1036842 1.88,11.9873684 L1.88,15.0026316 C1.87386578,15.2336098 1.95585454,15.4580454 2.1088,15.6289474 L3.8688,17.6778947 C4.03996978,17.881473 4.29183381,17.9963815 4.5552,17.9910526 L20.2896,17.9910526 C20.5529662,17.9963815 20.8048302,17.881473 20.976,17.6778947 L22.736,15.6289474 C22.8889455,15.4580454 22.9709342,15.2336098 22.9648,15.0026316 L22.9648,10.2694737 C22.9806518,10.0433017 22.9114559,9.8194459 22.7712,9.64315789 Z M18.8816,14.3673684 L15.08,14.3673684 C14.7154921,14.3673684 14.42,14.0669279 14.42,13.6963158 C14.42,13.3257037 14.7154921,13.0252632 15.08,13.0252632 L18.864,13.0252632 C19.2285079,13.0252632 19.524,13.3257037 19.524,13.6963158 C19.524,14.0669279 19.2285079,14.3673684 18.864,14.3673684 L18.8816,14.3673684 Z"
      />
      <path
        fill="#76C22C"
        d="M10.9,3.46947368 C9.58372711,2.03321936 7.60136898,1.44200586 5.73060608,1.92776665 C3.85984318,2.41352744 2.39904581,3.89878793 1.92128579,5.80087939 C1.44352576,7.70297086 2.02500163,9.71852637 3.4376,11.0568421 C4.75387289,12.4930964 6.73623102,13.0843099 8.60699392,12.5985491 C10.4777568,12.1127884 11.9385542,10.6275279 12.4163142,8.7254364 C12.8940742,6.82334493 12.3125984,4.80778942 10.9,3.46947368 L10.9,3.46947368 Z M9.4128,8.36368421 L4.916,8.36368421 C4.78382246,8.36118465 4.66550068,8.27974568 4.61411431,8.15590118 C4.56272795,8.03205667 4.58792423,7.88905708 4.6784,7.79105263 L6.9224,5.39315789 C6.98550641,5.32587518 7.07295883,5.28780252 7.1644,5.28780252 C7.25584117,5.28780252 7.34329359,5.32587518 7.4064,5.39315789 L9.6504,7.79105263 C9.74087577,7.88905708 9.76607205,8.03205667 9.71468569,8.15590118 C9.66329932,8.27974568 9.54497754,8.36118465 9.4128,8.36368421 L9.4128,8.36368421 Z"
      />
    </g>
  </Icon>
);

export default IcKarnottAvailable;
