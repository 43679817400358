import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const SearchIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 18.698 18.811"
    >
      <g transform="translate(0.75 0.75)">
        <path
          d="M655.6,69.559a7.031,7.031,0,1,1-9.943,0A7.03,7.03,0,0,1,655.6,69.559Z"
          transform="translate(-643.596 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="4.885"
          y2="4.998"
          transform="translate(12.003 12.003)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
