import { colors } from '@karnott/colors';
import { formatArea, formatDistance, formatDuration } from '../utils';
import { EQUIPMENT_CONSTANTS } from './equipmentConstants';

export const REPORTS_CONSTANTS = {
  SESSIONS: {
    device_session_status: {
      active: 'active',
      computed: 'computed',
      debug: 'debug',
      ended: 'ended',
    },
  },
  DIMENSIONS: {
    equipments_report: [
      'totalArea',
      'totalDistance',
      'totalInParcelsDistance',
      'totalOutOfParcelsDistance',
      'totalDuration',
      'totalMotorDuration',
      'totalMovementDuration',
      'totalInParcelsMotorDuration',
      'totalOutOfParcelsMotorDuration',
      'totalUnit',
    ],
    parcels_report: [
      'totalArea',
      'totalDistance',
      'totalDuration',
      'totalMotorDuration',
      'totalMovementDuration',
      'numberOfSession',
      'totalUnit',
    ],
    METHOD_TO_FORMAT: {
      totalArea: formatArea,
      totalDistance: formatDistance,
      totalDuration: formatDuration,
      totalInParcelsDistance: formatDistance,
      totalInParcelsMotorDuration: formatDuration,
      totalMotorDuration: formatDuration,
      totalMovementDuration: formatDuration,
      totalOutOfParcelsDistance: formatDistance,
      totalOutOfParcelsMotorDuration: formatDuration,
      numberOfSession: (nb: number) => nb,
      totalUnit: (tu: number) => tu,
    },
    UNIT_TO_USE: {
      totalArea: 'area',
      totalDistance: 'distance',
      totalDuration: 'time',
      totalInParcelsDistance: 'distance',
      totalInParcelsMotorDuration: 'time',
      totalMotorDuration: 'time',
      totalMovementDuration: 'time',
      totalOutOfParcelsDistance: 'distance',
      totalOutOfParcelsMotorDuration: 'time',
    },
    CORRESPONDING_BILLING_METHOD: {
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.AREA]: 'totalArea',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME]: 'totalMotorDuration',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_IN_FIELD]: 'totalInParcelsMotorDuration',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_OUT_FIELD]: 'totalOutOfParcelsMotorDuration',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_MOVEMENT]: 'totalMovementDuration',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.DURATION]: 'totalDuration',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE]: 'totalDistance',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_IN_FIELD]: 'totalInParcelsDistance',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_OUT_FIELD]: 'totalOutOfParcelsDistance',
      [EQUIPMENT_CONSTANTS.BILLING_METHODS.UNIT]: 'totalUnit',
    },
  },
  DIAGRAM_COLORS: [
    colors('bleen'),
    colors('green'),
    colors('orange'),
    colors('bleen', 'light'),
    colors('green', 'light'),
    colors('orange', 'light'),
    colors('bleen', 'dark'),
    colors('green', 'dark'),
    colors('orange', 'dark'),
    colors('sepia'),
    colors('cyan'),
    colors('red'),
    colors('yellow'),
    colors('sepia', 'light'),
    colors('cyan', 'light'),
    colors('red', 'light'),
    colors('yellow', 'light'),
    colors('sepia', 'dark'),
    colors('cyan', 'dark'),
    colors('red', 'dark'),
    colors('yellow', 'dark'),
  ],
  DIMENSION_TABS: {
    TIME: 'Commons.motor_duration',
    DISTANCE: 'Commons.traveled_distance',
    AREA: 'Commons.worked_area',
  },
} as const;
