import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ClusterIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 19 19"
    >
      <g transform="translate(1 1)">
        <path
          d="M854.1,74.024V86h-17V74.024L845.6,69Z"
          transform="translate(-837.097 -69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
