import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ShowLabelIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => {
  return (
    <CircledIcon {...{ backgroundColor, circled, size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={circled ? '50%' : '100%'}
        height={circled ? '50%' : '100%'}
        viewBox="0 0 19.5 19.5"
      >
        <defs>
          <clipPath>
            <rect width="19.5" height="19.5" transform="translate(0 0)" fill="none" />
          </clipPath>
        </defs>
        <g transform="translate(0 0)" clipPath="url(#clipPath)">
          <circle
            cx="1.633"
            cy="1.633"
            r="1.633"
            transform="translate(3.744 3.744)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M1.09,8.813l9.6,9.6a1.162,1.162,0,0,0,1.644,0l6.079-6.079a1.161,1.161,0,0,0,0-1.643l-9.6-9.6A1.165,1.165,0,0,0,7.849.758l-5.411.668A1.162,1.162,0,0,0,1.427,2.437L.758,7.849A1.162,1.162,0,0,0,1.09,8.813Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </CircledIcon>
  );
};
