import * as SentrySDK from '@sentry/browser';

export default class Sentry {
  static isCollectingData = import.meta.env.PROD;

  static instance = null;

  static CATEGORIES = {
    BUTTON_CLICK: 'BUTTON_CLICK',
    LINK_CLICK: 'LINK_CLICK',
    TRANSLATION: 'TRANSLATION',
    TOOLTIP_CLICK: 'TOOLTIP_CLICK',
  };

  static EVENT_LEVELS = {
    Fatal: 'fatal',
    Error: 'error',
    Warning: 'warning',
    Log: 'log',
    Info: 'info',
    Debug: 'debug',
    Critical: 'critical',
  };

  init() {
    if (Sentry.isCollectingData) {
      SentrySDK.init({
        dsn: 'https://0447629153494b98b1c08fbff2714308@o404951.ingest.sentry.io/5269789',
        ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'Load failed', // Thrown by Apple devices and concerns tracking api
          'Object captured as promise rejection with keys: errors', // Ghost error filling the quota, but never seen anywhere other than Sentry
        ],
        integrations: [SentrySDK.httpClientIntegration()],
        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,
      });
      SentrySDK.getCurrentScope().setTag('version', import.meta.env['VITE_VERSION']);
    }
  }

  reportError(error) {
    if (error && Sentry.isCollectingData) {
      SentrySDK.captureException(error);
    }
  }

  logEvent(event) {
    if (event && Sentry.isCollectingData) {
      SentrySDK.addBreadcrumb(event);
    }
  }

  setUser(user) {
    if (user && Sentry.isCollectingData) {
      SentrySDK.setUser(user);
    }
  }

  static getInstance() {
    if (!Sentry.instance) {
      Sentry.instance = new Sentry();
    }
    return Sentry.instance;
  }
}
