import { useEffect } from 'react';
import Lottie from 'lottie-web';
import styled from 'styled-components';

const Loader = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  margin: auto;
  opacity: ${({ opacity }) => opacity};
  background-color: ${({ transparent }) => (transparent ? 'transparent' : 'white')};
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : '')}
`;

const TheLoader = function ({ id = 'loader', opacity = 1, zIndex, transparent = false }) {
  useEffect(() => {
    const animData = {
      container: document.getElementById(id),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'data.json',
    };
    const anim = Lottie.loadAnimation(animData);
    return () => {
      anim.destroy();
    };
  }, [id]);

  return <Loader {...{ id, opacity, zIndex, transparent }} />;
};

export default TheLoader;
