import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const VendangeIcon = ({
  color = '#000',
  size = 44,
  backgroundColor = colors('grey'),
  circled = false,
  healthIssue = false,
}) => (
  <CircledIcon {...{ backgroundColor, circled, size, healthIssue }}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="
						M11.3,32.2c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C15.1,33.9,13.4,32.2,11.3,32.2z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="
						M30.3,32.2c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9C34.1,33.9,32.4,32.2,30.3,32.2z"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M7.4,35.9h-3c-0.6,0-1.2-0.4-1.2-1v-5.6c0-0.5,0.5-1,1.2-1h37.3c0.6,0,1.2,0.4,1.2,1v5.6c0,0.5-0.5,1-1.2,1h-7.2"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="26.2"
          y1="35.9"
          x2="15.3"
          y2="35.9"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M20.7,28.2H4c-0.4,0-0.8-0.4-0.8-0.8l-2.3-8.5c0-0.4,0.4-0.8,0.8-0.8h19.9c0.4,0,1,0.4,1,0.8l-1,8.5C21.7,27.9,21.2,28.2,20.7,28.2
		z"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="27.8"
          y1="3.7"
          x2="27.8"
          y2="19.3"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="22.6"
          y1="18.6"
          x2="22.6"
          y2="9.1"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M22.5,9.1l-2,2c-0.2,0.2-0.4,0.2-0.6,0l-2.2-2.2c-0.3-0.3-0.3-0.9,0-1.2l6.5-6.5c0.3-0.3,0.9-0.3,1.2,0l2.2,2.2
		c0.1,0.1,0.1,0.1,0.1,0.2"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M27.8,19.3h4.6c0.4,0,0.8,0.4,0.8,0.8v3.5v0.1h4.7c0.4,0,0.8,0.4,0.8,0.8v3.7"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="42.9"
          y1="29.7"
          x2="42.9"
          y2="13.2"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="36.3"
          y1="23.2"
          x2="36.3"
          y2="14.5"
        />

        <line
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="33.7"
          y1="14.4"
          x2="39"
          y2="14.4"
        />
      </g>
    </svg>
  </CircledIcon>
);
