import removeAccents from 'remove-accents';
import {
  AreaIcon,
  BaleIcon,
  BroyIcon,
  ChenillardIcon,
  ChronoIcon,
  CoupeIcon,
  DistanceIcon,
  EnjambeurIcon,
  FieldRollerIcon,
  HayMakingIcon,
  IntercepIcon,
  MiscellaneousIcon,
  PieceIcon,
  PlowIcon,
  PulveIcon,
  RecoltIcon,
  RemorkIcon,
  SeederIcon,
  SnowRemovalIcon,
  SoilIcon,
  SpreaderIcon,
  TimeIcon,
  TractorIcon,
  TraitementIcon,
  VendangeIcon,
} from '@karnott/icons';
import { BILLING_METHOD_TO_PROPERTY_NAME, EQUIPMENT_CONSTANTS } from '../constants/equipmentConstants';
import { formatArea, formatDistance, formatDuration, formatUnit, fromKmPerHourToMperS, getTotal } from './index';

export const equipmentContains = (equipment, filter) =>
  removeAccents(equipment.label || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const getEquipmentIcon = (type) =>
  ({
    charrue: PlowIcon,
    andaineuse: HayMakingIcon,
    faucheuse: HayMakingIcon,
    presse: HayMakingIcon,
    faneuse: HayMakingIcon,
    tractor: TractorIcon,
    misc: MiscellaneousIcon,
    broy: BroyIcon,
    remork: RemorkIcon,
    epand: SpreaderIcon,
    plomb: FieldRollerIcon,
    recolt: RecoltIcon,
    recolt_tool: RecoltIcon,
    pulve: PulveIcon,
    semoir: SeederIcon,
    snow_removal: SnowRemovalIcon,
    sol: SoilIcon,
    sol_combined: SoilIcon,
    stone_picker: MiscellaneousIcon,
    lisier: SpreaderIcon,
    semoir_couverts: SeederIcon,
    haymaking: HayMakingIcon,
    chenillard: ChenillardIcon,
    coupe: CoupeIcon,
    enjambeur: EnjambeurIcon,
    intercep: IntercepIcon,
    traitement: TraitementIcon,
    vendange: VendangeIcon,
    weeding: PulveIcon,
  })[type];

export const equipmentTypeLabel = (type) => {
  switch (type) {
    case 'tractor':
      return 'EquipmentType.tractor';
    case 'recolt':
      return 'EquipmentType.recolt';
    case 'recolt_tool':
      return 'EquipmentType.recolt_tool';
    case 'remork':
      return 'EquipmentType.remork';
    case 'semoir':
    case 'semoir_couverts':
      return 'EquipmentType.semoir';
    case 'pulve':
      return 'EquipmentType.pulve';
    case 'plomb':
      return 'EquipmentType.plomb';
    case 'epand':
      return 'EquipmentType.epand';
    case 'lisier':
      return 'EquipmentType.lisier';
    case 'broy':
      return 'EquipmentType.broy';
    case 'sol_combined':
      return 'EquipmentType.sol_combined';
    case 'sol':
      return 'EquipmentType.sol';
    case 'charrue':
      return 'EquipmentType.charrue';
    case 'haymaking':
      return 'EquipmentType.haymaking';
    case 'andaineuse':
      return 'EquipmentType.andaineuse';
    case 'faucheuse':
      return 'EquipmentType.faucheuse';
    case 'presse':
      return 'EquipmentType.presse';
    case 'faneuse':
      return 'EquipmentType.faneuse';
    case 'snow_removal':
      return 'EquipmentType.snow_removal';
    case 'chenillard':
      return 'EquipmentType.chenillard';
    case 'coupe':
      return 'EquipmentType.coupe';
    case 'enjambeur':
      return 'EquipmentType.enjambeur';
    case 'intercep':
      return 'EquipmentType.intercep';
    case 'traitement':
      return 'EquipmentType.traitement';
    case 'vendange':
      return 'EquipmentType.vendange';
    case 'weeding':
      return 'EquipmentType.weeding';
    case 'misc':
    default:
      return 'EquipmentType.misc';
  }
};

export const getBillingMethodLabel = (method, type, gpioDefaultUnitMethod) => {
  switch (method) {
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME:
      return 'EquipmentsReport.totalMotorDuration';
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_IN_FIELD:
      return 'EquipmentsReport.totalInParcelsMotorDuration';
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_OUT_FIELD:
      return 'EquipmentsReport.totalOutOfParcelsMotorDuration';
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_MOVEMENT:
      return 'EquipmentsReport.totalMovementDuration';
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DURATION:
      return 'EquipmentsReport.totalDuration';

    case EQUIPMENT_CONSTANTS.BILLING_METHODS.AREA:
      return 'EquipmentsReport.totalArea';

    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE:
      return 'Commons.traveled_distance';
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_IN_FIELD:
      return 'EquipmentsReport.totalInParcelsDistance';
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_OUT_FIELD:
      return 'EquipmentsReport.totalOutOfParcelsDistance';

    case EQUIPMENT_CONSTANTS.BILLING_METHODS.UNIT:
      switch (type) {
        case EQUIPMENT_CONSTANTS.TYPES.epand:
          return 'Session.unit_epand';
        case EQUIPMENT_CONSTANTS.TYPES.lisier:
          return 'Session.unit_lisier';
        case EQUIPMENT_CONSTANTS.TYPES.presse:
          return 'Session.unit_presse';
        default:
          if (gpioDefaultUnitMethod === EQUIPMENT_CONSTANTS.GPIO_METHODS.TIME) {
            return 'Session.unit_time';
          }
          return 'Session.unit_unit';
      }
    default:
      return '';
  }
};

export const getUnitWording = (t, equipmentModel, gpioDefaultUnitMethod) => {
  let equipmentLabel = 'Session.unit';
  if (
    equipmentModel === EQUIPMENT_CONSTANTS.TYPES.epand ||
    equipmentModel === EQUIPMENT_CONSTANTS.TYPES.lisier ||
    equipmentModel === EQUIPMENT_CONSTANTS.TYPES.presse
  ) {
    equipmentLabel = equipmentLabel + `_${equipmentModel}`;
  } else if (gpioDefaultUnitMethod) {
    equipmentLabel = equipmentLabel + `_${gpioDefaultUnitMethod}`;
  } else {
    equipmentLabel = equipmentLabel + `_unit`;
  }
  return t(equipmentLabel);
};

export const getUnitIcon = (equipmentModel, gpioDefaultUnitMethod) => {
  let unitsIcon = PieceIcon;
  if (equipmentModel === EQUIPMENT_CONSTANTS.TYPES.epand || equipmentModel === EQUIPMENT_CONSTANTS.TYPES.lisier) {
    unitsIcon = SpreaderIcon;
  } else if (equipmentModel === EQUIPMENT_CONSTANTS.TYPES.presse) {
    unitsIcon = BaleIcon;
  } else if (gpioDefaultUnitMethod === EQUIPMENT_CONSTANTS.GPIO_METHODS.TIME) {
    unitsIcon = ChronoIcon;
  }
  return unitsIcon;
};

export const getBillingMethodIcon = (method, equipmentModel, gpioDefaultUnitMethod) => {
  switch (method) {
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_IN_FIELD:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_OUT_FIELD:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_MOVEMENT:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DURATION:
      return TimeIcon;
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.AREA:
      return AreaIcon;
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_IN_FIELD:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_OUT_FIELD:
      return DistanceIcon;
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.UNIT:
      // If the equipment is an epand or a lisier, we display the icon of the equipment
      if (equipmentModel === EQUIPMENT_CONSTANTS.TYPES.epand || equipmentModel === EQUIPMENT_CONSTANTS.TYPES.lisier) {
        return SpreaderIcon;
      }
      // If the equipment is a presse, we display the icon of the equipment
      if (equipmentModel === EQUIPMENT_CONSTANTS.TYPES.presse) {
        return BaleIcon;
      }
      if (gpioDefaultUnitMethod === EQUIPMENT_CONSTANTS.GPIO_METHODS.TIME) {
        return ChronoIcon;
      }
      return PieceIcon;
    default:
      return DistanceIcon;
  }
};

export const getMaxSpeed = ({ equipmentModel, maxSpeedThreshold }) => {
  let defaultMaxSpeed = 40;
  if (equipmentModel !== undefined && equipmentModel.type === EQUIPMENT_CONSTANTS.TYPES.snow_removal) {
    defaultMaxSpeed = 115;
  }
  if (maxSpeedThreshold > fromKmPerHourToMperS(defaultMaxSpeed)) {
    defaultMaxSpeed = 115;
  }
  return defaultMaxSpeed;
};

export const buildActiveTracksFromEquipments = (equipments) => {
  const activeEquipments = (equipments || []).filter((e) => !!e.activeSession);
  if (!activeEquipments?.length) return null;
  const tracks = {
    type: 'FeatureCollection',
  };
  let features = [];
  activeEquipments.forEach((equipment) => {
    let activeSession = {};
    activeSession.geometry = equipment.activeSession.track;
    activeSession.properties = {
      device_session_id: equipment.activeSession.deviceSessionId,
      fromDate: equipment.activeSession.fromDate,
      equipment_model_id: equipment.equipmentModelId,
      equipment_model_type: equipment?.equipmentModel?.type,
      equipment_name: equipment.label,
      equipment_id: equipment.id,
    };
    activeSession.type = 'Feature';
    features.push(activeSession);
  });
  tracks.features = features;
  return tracks;
};

export const formatTotalBillingMethod = (equipment = {}, equipmentSessions = []) => {
  return {
    type: equipment.billingMethod,
    value: getTotal(equipmentSessions, BILLING_METHOD_TO_PROPERTY_NAME[equipment.billingMethod]),
  };
};

export const getBillingMethodFormatter = (billingMethod, gpioDefaultUnitMethod) => {
  switch (billingMethod) {
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.UNIT:
      return gpioDefaultUnitMethod === EQUIPMENT_CONSTANTS.GPIO_METHODS.TIME ? formatDuration : formatUnit;
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.AREA:
      return formatArea;
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_IN_FIELD:
    case EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_OUT_FIELD:
      return formatDistance;
    default:
      return formatDuration;
  }
};
