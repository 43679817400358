import { useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { CalendarIcon } from '@karnott/icons';
import { Table } from '@karnott/table';
import { elevation, pixelSpacing } from '@karnott/theme';
import { getSharedSession } from '../actions/sessions';
import TheLoader from '../components/Loader';
import WithSnacks from '../components/WithSnacks';
import Map from '../components/map/Map';
import { useParcelsOverrideStyle } from '../components/map/assets/Parcels/effects';
import Controls from '../components/map/controls/Controls';
import { useParcelSelection } from '../components/map/drawParcel/effects';
import { StatsSimpleNumericValue, StatsTitle } from '../components/reports/ui';
import { USER_CONSTANTS } from '../constants';
import { EQUIPMENT_CONSTANTS } from '../constants/equipmentConstants';
import { I18nContext } from '../contexts/I18nProvider';
import logo from '../images/karnott_logo.svg';
import { Cell, Container, DetailsMapContainer, Grid } from '../ui';
import { Logo } from '../ui/Header';
import { color, family, spacing } from '../ui/theme';
import { formatArea, formatDistance, formatDuration, formatUnit } from '../utils';
import { equipmentTypeLabel, getEquipmentIcon, getUnitWording } from '../utils/equipments';

function Header({ Icon, equipmentModel, date }) {
  const { t } = useContext(I18nContext);

  return (
    <ShareHeader>
      <Logo to="/">
        <img alt="karnott" src={logo} />
      </Logo>
      <IconWithLabel>
        {Icon ? <Icon size={28} color={colors('black')} /> : null}
        <span>{t(equipmentTypeLabel(equipmentModel))}</span>
      </IconWithLabel>
      <IconWithLabel>
        <CalendarIcon size={20} color={colors('black')} />
        <span>{moment(date).format('LL')}</span>
      </IconWithLabel>
    </ShareHeader>
  );
}

function ShareContainer({ errorSharedSession, fullScreenMap, loadingSharedSession, session, getSharedSession }) {
  const { t } = useContext(I18nContext);
  const units = useMemo(
    () => ({
      area: USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI,
      distance: USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI,
      time: USER_CONSTANTS.UNITS.TIME.DECIMAL2,
    }),
    [],
  );
  const [area, concernedParcel, distance, equipmentModel, date, duration, parcelSessions, track, unit] = useMemo(
    () => [
      formatArea(session.area, units.area, true),
      session.concernedParcel,
      formatDistance(session.distance, units.distance, true),
      session.equipmentModel,
      session.date,
      formatDuration(session.motorDuration, units.time, true, true),
      session.parcelSessions,
      session.track,
      session.equipmentGpioDefaultUnitMethod === EQUIPMENT_CONSTANTS.GPIO_METHODS.TIME
        ? formatDuration(session.unit, units.time, true)
        : formatUnit(session.unit),
    ],
    [session, units],
  );

  const id = useMemo(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get('id');
  }, []);

  useEffect(() => {
    if (id) {
      getSharedSession(id);
    }
  }, [id, getSharedSession]);

  const [, focusedIDs, toggle, clear] = useParcelSelection();

  const onRowEnter = (parcelId) => {
    clear();
    toggle({ id: parcelId });
  };

  const mapOverlays = useMemo(() => [<Controls key="0" />], []);
  const tracks = useMemo(() => track, [track]);
  const parcelsStyleOverrides = useParcelsOverrideStyle({ focusedIDs });
  const Icon = useMemo(() => getEquipmentIcon(equipmentModel), [equipmentModel]);

  if (Object.keys(session).length) {
    if (parcelSessions) {
      return (
        <ShareComponent>
          {Object.keys(session).length ? (
            <StyledShareContainer>
              <Header Icon={Icon} equipmentModel={equipmentModel} date={date} />
              <Grid>
                <Cell width={1 / 2}>
                  <DetailsMapContainer {...{ fullScreenMap }} fullHeight>
                    <Map
                      fitMask="parcel,tracks"
                      tracks={tracks}
                      parcels={concernedParcel}
                      overlays={mapOverlays}
                      parcelsStyleOverrides={parcelsStyleOverrides}
                    />
                  </DetailsMapContainer>
                </Cell>
                <Cell width={1 / 2}>
                  <Container bare full>
                    <ShareStatsContentBloc>
                      <div>
                        <StatsTitle>{t('Commons.motor_duration')}</StatsTitle>
                        <StatsSimpleNumericValue>{duration}</StatsSimpleNumericValue>
                      </div>
                      <div>
                        <StatsTitle>{t('Commons.traveled_distance')}</StatsTitle>
                        <StatsSimpleNumericValue>{distance}</StatsSimpleNumericValue>
                      </div>
                      <div>
                        <StatsTitle>{t('Commons.worked_area')}</StatsTitle>
                        <StatsSimpleNumericValue>{area}</StatsSimpleNumericValue>
                      </div>
                      {unit ? (
                        <div>
                          <StatsTitle>
                            {getUnitWording(t, equipmentModel, session.equipmentGpioDefaultUnitMethod)}
                          </StatsTitle>
                          <StatsSimpleNumericValue>{unit}</StatsSimpleNumericValue>
                        </div>
                      ) : null}
                    </ShareStatsContentBloc>
                    <TableWrapper>
                      {parcelSessions?.length ? (
                        <Table
                          sortAll
                          columns={[
                            {
                              key: 'distance',
                              header: t('Session.distance'),
                              displayFn: ({ distance, row }) => (
                                <div onMouseEnter={() => onRowEnter(row.id)} onMouseLeave={() => clear()}>
                                  {formatDistance(distance, units.distance, true)}
                                </div>
                              ),
                            },
                            {
                              key: 'motor_duration',
                              header: t('Share.motor_duration'),
                              displayFn: ({ motor_duration, row }) => (
                                <div onMouseEnter={() => onRowEnter(row.id)} onMouseLeave={() => clear()}>
                                  {formatDuration(motor_duration, units.time, true, true)}
                                </div>
                              ),
                            },
                            {
                              key: 'area',
                              header: t('Session.area'),
                              displayFn: ({ area, row }) => (
                                <div onMouseEnter={() => onRowEnter(row.id)} onMouseLeave={() => clear()}>
                                  {formatArea(area, units.area, true)}
                                </div>
                              ),
                            },
                          ]}
                          rows={parcelSessions
                            .map((s) => ({ ...s, id: s.concerned_parcel_id }))
                            .sort((a, b) => a.distance - b.distance)}
                        />
                      ) : null}
                    </TableWrapper>
                  </Container>
                </Cell>
              </Grid>
            </StyledShareContainer>
          ) : null}
        </ShareComponent>
      );
    }
    return (
      <ShareComponent>
        <Header Icon={Icon} equipmentModel={equipmentModel} date={date} />
        <BasicModeMapContainer>
          <Map
            fitMask="equipment,parcel,tracks"
            tracks={tracks}
            parcels={concernedParcel}
            overlays={mapOverlays}
            parcelsStyleOverrides={parcelsStyleOverrides}
          />
        </BasicModeMapContainer>
      </ShareComponent>
    );
  }
  return (
    <ShareComponent>
      {loadingSharedSession ? <TheLoader /> : null}
      {errorSharedSession ? (
        <ErrorWrapper>
          <img alt="karnott" src={logo} />
          <div>{t('Share.error')}</div>
        </ErrorWrapper>
      ) : null}
    </ShareComponent>
  );
}

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  img {
    max-width: 33%;
  }
`;

const ShareComponent = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${color('snow', 'light')};
  font-family: ${family('body')};
`;

const StyledShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const IconWithLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing}px;
`;

const BasicModeMapContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: calc(100vh - 55px);
  top: 55px;
  bottom: 0;
`;

const ShareHeader = styled.div`
  width: 100vw;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
`;

const TableWrapper = styled.div`
  max-height: calc(100vh - 171px);
  overflow: auto;
  padding: 0 ${pixelSpacing()};
  width: 100%;
  > * {
    width: 100%;
  }
`;

const ShareStatsContentBloc = styled.div`
  display: flex;
  padding: ${pixelSpacing()};
  > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${colors('white')};
    border-radius: 4px;
    padding: ${pixelSpacing()};
    ${elevation({ elevated: true })}
  }
  > *:not(:last-child) {
    margin-right: ${pixelSpacing('small')};
  }
`;

const mapStateToProps = (state) => ({
  fullScreenMap: state.ui.fullScreenMap,
  session: state.sessions.sharedSession,
  loadingSharedSession: state.sessions.loadingSharedSession,
  errorSharedSession: state.sessions.errorSharedSession,
});

const mapDispatchToProps = (dispatch) => ({
  getSharedSession: (id) => dispatch(getSharedSession(id)),
});

const ConnectedShareContainer = connect(mapStateToProps, mapDispatchToProps)(WithSnacks(ShareContainer));
export default ConnectedShareContainer;
