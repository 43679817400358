import { useContext } from 'react';
import styled from 'styled-components';
import { I18nContext } from '../contexts/I18nProvider';
import backgroundMaintenance from '../images/background_maintenance.jpg';
import fullLogo from '../images/karnott_full_logo.svg';
import { color, family } from '../ui/theme';

const MaintenanceWrapper = styled.div`
  background-image: url(${backgroundMaintenance});
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  font-family: ${family('body')};
  @media (max-width: 768px) {
    background-size: auto 160%;
    background-position: 62% 88%;
  }
`;

const MaintenanceContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: white;
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-size: 1.2rem;
  color: ${color('brown')};
  width: 360px;
  height: 299px;
  margin-left: 25%;
  @media (max-width: 768px) {
    font-size: 1rem;
    width: 280px;
    margin-left: 10px;
  }
`;

const MaintenanceContainer = function () {
  const { t } = useContext(I18nContext);

  return (
    <MaintenanceWrapper>
      <MaintenanceContent>
        <img src={fullLogo} alt="Karnott" />
        {t('Maintenance.maintenance_content')}
      </MaintenanceContent>
    </MaintenanceWrapper>
  );
};

export default MaintenanceContainer;
